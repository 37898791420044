export const anim = {
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enterList(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `listAnim 0.2s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterLongList(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `listLongAnim 0.2s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterBtnBottom(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `btnBottomAnim 0.5s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterShowDaily(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `enterShowDaily 0.3s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },

    setWowAnim() {
      _v.$nextTick(() => {
        const wow = new this.$wow({
          live: false,
        });
        wow.init();
      });
    },
  },
  mounted() {},
};
