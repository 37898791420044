import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mTierBenefits",
  props: {
    isCurrent: {
      default: false
    }
  },
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...gameVuex.mapState(["propertyList", "gameProgress", "levelIntro"]),
    levelInfo() {
      return this.$route.path == '/center' || this.$route.path != '/center' && this.isCurrent ? this.gameProgress.currentLevel : this.gameProgress.nextLevel == -1 ? 'Max' : this.gameProgress.nextLevel;
    }
  },
  data() {
    return {};
  }
};