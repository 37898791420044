var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mSlotMachine"
  }, _vm._l(_vm.slotMachineConfig, function (config, index) {
    return _c('m-gift', {
      key: index,
      attrs: {
        "trigger": _vm.trigger,
        "config": config,
        "isSelect": _vm.isSelect
      },
      on: {
        "finished": _vm.isFinished
      }
    });
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };