import vailcode from "@utils/errcode";

export default {
  namespaced: true,
  state: {
    rId:null
  },
  mutations: {
    setRId: (state, payload)=> {
      state.rId = payload
    },
  },
  actions: {
    doCheck:async ({ state, commit },params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/facescan/check",params);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              resolve();
            }
            resolve(data);
          },
          (err) => {
            resolve();
          },
          false
        );
      });
    },
    getRequestId:async ({ state, commit }, appid) => {
      const rs = await _v.$http.post("/facescan/id",{appid});
      vailcode(
        rs,
        (rs) => {
          commit("setRId", rs.data.requestId);
        }, ()=> {
          
        }
      );
    },
  },
  getters: {},
};
