var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "mBtnMathine",
    class: _vm.isNoStyle && 'none',
    on: {
      "click": _vm.showPop
    }
  }, [_c('span', [_vm._v("SLOTS")]), _c('i')])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };