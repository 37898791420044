import Vue from "vue";
import vailcode from "@utils/errcode";
let generatePaymentInfo = (start, end, step = 1) => {
  return Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => `p_${start + i * step}`).reduce((acc, key) => {
    acc[key] = {
      paymentId: null,
      isLoading: false,
      timer: null,
    };
    return acc;
  }, {});
};

export default {
  namespaced: true,
  state: {
    taskInfo: {
      taskTimer: null,
      popReward: {
        reward: 0,
        show: false,
        isAnim: true,
        icon: "COIN",
      },
      paymentInfo: generatePaymentInfo(0, 30),
      spinsPriceListLength: 0,
      popCoinAnim: {
        show: false,
        icon: "SPINS",
        reward: 5,
      },
    },
  },
  mutations: {},
  actions: {
    //每日签到 任务页面数据获取
    getDailySummary: async ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await Vue.prototype.$http.post("/daily/summary", {});
        vailcode(res, () => {
          const data = res.data;
          if (!data) {
            resolve();
            return;
          }
          resolve(data.check_in);
        });
      });
    },
    //获取任务列表
    getTaskList: async ({ state, commit, dispatch }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/task/list", params);
        console.log("resresresres", res);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              resolve();
              return;
            }
            data.map((item) => {
              _v.$set(item, "isLoading", false);
              _v.$set(item, "adv", params?.adv);
              _v.$set(item, "isCancelLoading", false);
              _v.$set(item, "type", item?.provider ? item.provider : item.type);
              console.log("typeof item.target", typeof item.target);
              let target = item.target;
              try {
                if (typeof item.target === "string" && item.target != "") {
                  target = JSON.parse(item.target);
                }
              } catch (e) {}
              _v.$set(item, "target", target);
            });
            resolve(data);
            console.log("datadatadata", data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //验证任务
    doTask: async ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        console.log("params", params);
        const res = await Vue.prototype.$http.post("/task/complete", params);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              resolve();
              return;
            }
            resolve(data);
          },
          (err) => {
            resolve(err);
          }
        );
      });
    },
    //支付前预处理任务
    getTaskPrepare: async ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await Vue.prototype.$http.post("/task/prepare", params);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              resolve();
              return;
            }
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //星星支付
    openInvoiceStarPay: async (_, params) => {
      return new Promise(async (resolve) => {
        const res = await _v.WebApp.openInvoice(params.url);
        async function invoiceClosedFnc(e) {
          _v.WebApp.offEvent("invoiceClosed", invoiceClosedFnc);
          console.log("ressssssss", res, e?.status);
          if (e?.status && e.status == "paid") {
            resolve(1);
          } else if (e?.status && e.status === "cancelled") {
            resolve();
          }
        }
        _v.WebApp.onEvent("invoiceClosed", invoiceClosedFnc);
      });
    },
    //获取每日签到列表
    getSignDailyTask: async (_, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await Vue.prototype.$http.post("/daily/steps", params);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              resolve();
              return;
            }
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //每日签到 签到
    signDailyIn: async (_, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await Vue.prototype.$http.post("/daily/complete", params);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              resolve();
              return;
            }
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
  },
  getters: {
    isAnyPaymentSpinsLoading: (state) => {
      for (const key in state.taskInfo.paymentInfo) {
        if (state.taskInfo.paymentInfo[key].isLoading) {
          return true;
        }
      }
      return false;
    },
  },
};
