import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import Axios from "./http/index";
import router from "./router";
import "./assets/css/reset.scss";
import "./assets/css/common.scss";
import "./assets/css/anim.scss";
import "./assets/css/popPrompt.scss";
import "./assets/icons"; //svg图标
import "./assets/js/rem";
import "./assets/fonts/font.css";
import Toasted from "vue-toasted";
import directive from "./directive";
import formatter from "./utils/formatter";
import loadConfig from "@tonWalletConfig/config";
import tonWallet from "./tonWallet";
import WebApp from "@twa-dev/sdk";
import { anim } from "./mixins/anim.js";
import { WOW } from "wowjs";
import "animate.css";
import { EventBus } from "@assets/js/eventBus";
import * as svgBox from "@assets/js/svg";
import VPopover from "vue-js-popover";
Vue.use(VPopover, { tooltip: true });
import pop from "./assets/js/pop";
import bigNumber from "bignumber.js";
import countTo from "vue-count-to";
import "animate.css";
const confetti = require("@assets/js/confetti.js");

// import vconsole from "vconsole";
// new vconsole()

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

import "./assets/css/vtip.css";
import Vtip from "vtip";
Vue.use(Vtip.directive);
Vue.prototype.$tip = Vtip.tip;

window._v = Vue.prototype;
_v.confetti = confetti;
_v.tonWallet = tonWallet;
_v.screenWidth = window.innerWidth;
_v.screenHeight = window.innerHeight;
WebApp.ready();
WebApp.expand();
WebApp.isVerticalSwipesEnabled = false;
WebApp.isClosingConfirmationEnabled = true;
WebApp.setHeaderColor("#000");
WebApp.setBackgroundColor("#000");
WebApp.lockOrientation()

Axios.install(Vue);
_v.EventBus = EventBus;
_v.hapticFeedback = () => {
  try {
    _v.WebApp.HapticFeedback.notificationOccurred("success");
  } catch (err) {
    console.log(err);
  }
};

const options = {
  duration: 3000,
  position: "top-center",
  fullWidth: false,
};
Vue.use(Toasted, options);
_v.WebApp = WebApp;
console.log("svgBox", svgBox);
for (let key in svgBox) {
  _v[key] = svgBox[key];
}
for (let key in pop) {
  _v[key] = pop[key];
}

for (let key in loadConfig) {
  _v[key] = loadConfig[key];
}
for (let func in formatter) {
  _v[func] = formatter[func];
}
for (let func in formatter) {
  _v[func] = formatter[func];
}

Vue.use(directive);
for (let func in formatter) {
  Vue.filter(func, formatter[func]);
}
_v.formatter = formatter;

Vue.config.productionTip = false;

_v.registerBodyClick = (isRegister, callback) => {
  isRegister ? document.body.addEventListener("click", callback) : document.body.removeEventListener("click", callback);
};

_v.bigNumber = function (num) {
  if (!num) {
    return bigNumber(0);
  }
  const str = num.toString();
  // 检查是否是科学计数法表示
  if (/e/i.test(str)) {
    return bigNumber(Number(num.toFixed(18).replace(/0+$/, ""))); // 保留18位精度
  }
  return bigNumber(str);
};

Vue.mixin(anim);

_v.$wow = WOW;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
