var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mHead"
  }, [_c('div', {
    staticClass: "mHead-main"
  }, [_c('div', {
    staticClass: "mHead-avatar"
  }, [_c('div', {
    staticClass: "mHead-avatar-img",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/center');
      }
    }
  }, [_c('div', {
    staticClass: "head",
    style: {
      background: `url(${require(`@images/avatar/head/${_vm.gameProgress.currentLevel >= _vm.botImgLimit ? _vm.botImgLimit : _vm.gameProgress.currentLevel}.png`)}) center/auto 100% no-repeat`
    }
  })]), _c('div', {
    staticClass: "mHead-avatar-level"
  }, [_vm._v("Lv." + _vm._s(_vm.gameProgress.currentLevel))])]), _c('div', {
    staticClass: "mHead-btns"
  }, [_c('div', {
    staticClass: "mHead-btn"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-hic.png'),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.fmt_pre(_vm.userInfo.coin, 2, true, true)))])]), _c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.bottom.click",
      value: `Withdrawals will be available soon`,
      expression: "`Withdrawals will be available soon`",
      modifiers: {
        "bottom": true,
        "click": true
      }
    }],
    staticClass: "mHead-btn"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-usdt.png'),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v(" " + _vm._s(_vm.fmt_pre(_vm.balanceInfo.usdt.balance, 3, true, true)) + " ")])]), _c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.bottom.click",
      value: `Withdrawals will be available soon`,
      expression: "`Withdrawals will be available soon`",
      modifiers: {
        "bottom": true,
        "click": true
      }
    }],
    staticClass: "mHead-btn"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-star-fill.png'),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.fmt_pre(_vm.balanceInfo.stars.balance, 2, true, true)))])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };