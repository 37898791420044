export default {
  name: "popPrompt",
  props: {
    show: Boolean,
    content: {
      typeof: String,
      default: ""
    },
    confirmText: {
      //右侧按钮文字
      typeof: [String, Number],
      default: "Confirm"
    },
    cancelText: {
      //左侧按钮文本
      //
      typeof: [String, Number],
      default: "Cancel"
    },
    title: {
      //标题
      typeof: [String, Number],
      default: ""
    },
    isConfirmLoading: {
      //右侧按钮loading状态
      typeof: Boolean,
      default: false
    },
    isBtnConfirm: {
      //是否显示右侧按钮
      typeof: Boolean,
      default: true
    },
    isBtnCancel: {
      //是否左侧按钮
      typeof: Boolean,
      default: true
    },
    isClose: {
      //是否关闭
      typeof: Boolean,
      default: true
    },
    isShadowClick: {
      //遮罩层是否关闭
      typeof: Boolean,
      default: false
    },
    className: {
      //弹窗自定义class 用于修改弹窗样式
      typeof: String,
      default: ""
    },
    loadingText: {
      //右侧按钮上loading名称
      typeof: String,
      default: "Loading"
    },
    isSmallCancelBtn: {
      //左侧按钮是否变短
      typeof: Boolean,
      default: false
    },
    isSmallConfirmBtn: {
      //右侧按钮是否变短
      typeof: Boolean,
      default: false
    },
    isCancelLoading: {
      //左侧按钮上loading状态
      typeof: Boolean,
      default: false
    },
    cancelLoadingText: {
      //左侧按钮上loading名称
      typeof: String,
      default: "Loading"
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.$emit("update:show", false);
    },
    onConfirm() {
      this.isLoading = true;
      this.$emit("confirm", () => {
        this.isLoading = false;
      });
    },
    onCancel() {
      this.isLoading = true;
      this.$emit("cancel", () => {
        this.isLoading = false;
      });
    }
  },
  mounted() {}
};