var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tab.length > 0 ? _c('div', {
    staticClass: "mTabTwo"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mTabTwo-item",
      class: _vm.selectTab && _vm.selectTab.label === item.label && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };