import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const assetsVuex = createNamespacedHelpers("assets");
export default {
  name: "nCurrency",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    ...assetsVuex.mapState(["assetsList"])
  },
  props: {
    isShowKey: {
      default: true
    },
    isShowCoin: {
      default: true
    },
    isShowTap: {
      default: true
    },
    isShowUsdt: {
      default: true
    }
  },
  data() {
    return {
      tonCurrency: {
        tap: {
          balance: null,
          icon: null
        },
        usdt: {
          balance: null,
          icon: null
        }
      }
    };
  },
  methods: {
    ...assetsVuex.mapActions(["getAssetsList"]),
    async getCurrencyData() {
      await this.getAssetsList();
      console.log(333333333);
      this.assetsList.map(item => {
        if (item.chain === "ton") {
          for (const key in this.tonCurrency) {
            if (item.name == key) {
              this.tonCurrency[key].balance = item.balance;
              this.tonCurrency[key].icon = item.icon;
            }
          }
        }
      });
    }
  }
};