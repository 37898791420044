var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mLuckyItem",
    class: _vm.isSoon && 'soon'
  }, [_c('div', {
    staticClass: "mLuckyItem-main"
  }, [_vm.url ? _c('img', {
    attrs: {
      "src": _vm.url,
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mLuckyItem__label"
  }, [_vm._v(_vm._s(_vm.name))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };