import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const friendVuex = createNamespacedHelpers("friend");
export default {
  name: "friends",
  data() {
    const tabList = [{
      label: "Share & Reward",
      value: 0
    }, {
      label: "Leaderbroard",
      value: 1
    }];
    return {
      tabList,
      selectTab: null,
      invitationInfo: null,
      earnings: null,
      myFriends: null,
      topLeaderRank: null
    };
  },
  watch: {
    selectTab: {
      handler(newVal) {
        console.log("selectTab", newVal);
      }
    }
  },
  methods: {
    ...userVuex.mapActions(["getInviteInfo", "getInviteEarning", "getFriendRank", "getTopleadersRank"]),
    onTab(item) {
      console.log("onTab", item);
    },
    async initFn() {
      if (!this.selectTab) return;
      if (this.selectTab.value === 0) {
        this.$nextTick(() => {
          try {
            const mFirendShareOrRewardRef = this.$refs.mFirendShareOrRewardRef;
            if (!mFirendShareOrRewardRef) {
              console.log("mFirendShareOrRewardRef", mFirendShareOrRewardRef);
              mFirendShareOrRewardRef.init();
            }
          } catch (err) {}
        });
      } else if (this.selectTab.value === 1) {
        this.$nextTick(() => {
          try {
            const mFirendLeasderBroardRef = this.$refs.mFirendLeasderBroardRef;
            if (!mFirendLeasderBroardRef) {
              console.log("mFirendLeasderBroardRef", mFirendLeasderBroardRef);
              mFirendLeasderBroardRef.init();
            }
          } catch (err) {}
        });
      }
    }
  },
  async mounted() {
    this.setWowAnim();
    this.invitationInfo = await this.getInviteInfo();
    this.earnings = await this.getInviteEarning();
    this.myFriends = await this.getFriendRank();
    this.topLeaderRank = await this.getTopleadersRank();
    this.initFn();
  }
};