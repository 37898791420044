import "core-js/modules/es.array.push.js";
export default {
  name: "ris",
  data() {
    return {
      step: 0
    };
  },
  watch: {},
  methods: {
    onRisAConfirm() {
      this.step = 1;
    },
    onRisBConfirm() {
      this.step = 2;
    },
    onRisCConfirm() {
      this.$router.push("/");
    }
  }
};