import "core-js/modules/es.array.push.js";
export default {
  name: "lucky",
  data() {
    return {};
  },
  methods: {
    onSkip(path) {
      this.hapticFeedback();
      this.$router.push(path);
    }
  },
  mounted() {
    this.setWowAnim();
  }
};