var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mMenu"
  }, [_c('div', {
    staticClass: "mMenu-main"
  }, [_vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mMenu-item",
      class: [item.value === 'human' && 'human', _vm.selectMenu == item.value && 'active'],
      on: {
        "click": function ($event) {
          return _vm.onMenu(item);
        }
      }
    }, [_c('div', {
      staticClass: "mMenu-light"
    }), _c('div', {
      staticClass: "mMenu-item-main"
    }, [_c('div', {
      staticClass: "menu-item-icon"
    }, [_c('div', {
      staticClass: "menu-item-icon_s"
    }, [_c('svg-icon', {
      attrs: {
        "name": `icon-${item.value}`
      }
    })], 1)]), _c('div', {
      staticClass: "mMenu-item-label"
    }, [_vm._v(_vm._s(item.label))])])]);
  }), _c('div', {
    staticClass: "mMenu-line"
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };