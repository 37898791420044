var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ris"
  }, [_vm.step === 0 ? _c('ris-a', {
    on: {
      "confirm": _vm.onRisAConfirm
    }
  }) : _vm.step === 1 ? _c('ris-b', {
    on: {
      "confirm": _vm.onRisBConfirm
    }
  }) : _vm.step === 2 ? _c('ris-c', {
    on: {
      "confirm": _vm.onRisCConfirm
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };