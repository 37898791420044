import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
import { taskMixins } from "@/mixins/taskMixins";
export default {
  name: "daily",
  mixins: [taskMixins],
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo", "settingInfo"]),
    ...taskVuex.mapState(["taskInfo"])
  },
  data() {
    return {
      popDailyAdsShow: false,
      isTime: false,
      dailyList: [],
      distance: 0,
      lastTimer: null,
      todayItem: null,
      dailyTaskList: null
    };
  },
  watch: {
    isLogin: {
      async handler(bool) {
        if (bool) {
          this.initFn();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...taskVuex.mapActions(["getSignDailyTask", "signDailyIn", "getTaskList"]),
    async initFn() {
      let res = await this.getSignDailyTask({
        type: 1,
        refresh: 1
      });
      if (!res) {
        return;
      }
      res.steps.map(item => {
        if (item.today) {
          this.todayItem = item;
        }
      });
      this.dailyList = res.steps;
      if (!this.todayItem.completed) {
        res = await this.signDailyIn({
          type: 1,
          multiple: 0
        });
        if (!res) {
          return;
        }
        this.dailyList = res.steps;
        this.taskInfo.popReward.isAnim = true;
        this.taskInfo.popReward.reward = this.todayItem.coins;
        this.taskInfo.popReward.show = true;
      }
      this.distance = res.tomorrow_distance;
      this.countDown();
    },
    countDown() {
      clearTimeout(this.lastTimer);
      if (this.distance > 0) {
        this.distance--;
      }
      if (this.distance <= 0) {
        clearTimeout(this.lastTimer);
        this.initFn();
        return;
      }
      setTimeout(() => {
        this.countDown();
      }, 1000);
    },
    onShowPopDailyAds() {
      this.taskInfo.popReward.reward = this.todayItem.coins;
      this.taskInfo.popReward.isAnim = false;
      this.taskInfo.popReward.show = true;
    }
  }
};