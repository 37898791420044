var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mFirendLeasderBroard"
  }, [_c('div', {
    staticClass: "rank"
  }, [_vm.ranking ? [_c('transition-group', {
    staticClass: "rank-list",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.ranking.length > 0 ? _vm._l(_vm.ranking, function (item, index) {
    return _c('div', {
      key: `rank${index}`,
      staticClass: "rank-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      }
    }, [_c('div', {
      staticClass: "rank__img"
    }, [_c('m-head-img', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    })], 1), _c('div', {
      staticClass: "rank__name"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "rank__level"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/icon/icon-hic.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("fmt_pre")(item.ref_count, false)) + " ")])]);
  }) : _vm._e()], 2)] : _c('div', {
    staticClass: "loading"
  }, [_c('div', {
    staticClass: "circleLoading"
  })])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };