var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "popReward-preload",
    style: {
      background: `
      url(${require('@images/rewardAnim/anim-1.png')}),
      url(${require('@images/rewardAnim/anim-2.png')}),
      url(${require('@images/rewardAnim/anim-bg-COIN.png')}),
      url(${require('@images/COIN.png')}),
      url(${require('@images/SPINS.png')}),
      url(${require('@images/LGCOIN.png')}),
      url(${require('@images/LGSPINS.png')}),
      url(${require('@images/TON.png')}),
      url(${require('@images/TOKEN.png')}),
      `
    }
  }), _vm.taskInfo.popReward.icon === 'SPINS' && _vm.show ? [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRewardspinsAnim,
      expression: "isRewardspinsAnim"
    }]
  }, [_c('pop-prompt', {
    attrs: {
      "isClose": false,
      "title": " ",
      "confirmText": "Get",
      "content": "html",
      "show": _vm.show,
      "isBtnCancel": false
    },
    on: {
      "confirm": _vm.onSpinAccept,
      "update:show": function ($event) {
        _vm.show = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pop-rewardspins"
  }, [_c('div', {
    staticClass: "pop-rewardspins__title"
  }, [_vm._v("Congratulations on getting")]), _c('div', {
    staticClass: "pop-rewardspins__reward"
  }, [_vm._v("+" + _vm._s(_vm.fmt_pre(_vm.taskInfo.popReward.reward)))])])])], 1)] : _vm.taskInfo.popReward.icon === 'CRY' && _vm.show ? [_c('pop-prompt', {
    attrs: {
      "isClose": false,
      "title": " ",
      "confirmText": "Accept",
      "content": "html",
      "show": _vm.show,
      "isBtnCancel": false
    },
    on: {
      "confirm": _vm.onFailAccept,
      "update:show": function ($event) {
        _vm.show = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pop-fail"
  }, [_c('div', {
    staticClass: "pop-fail__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/CRY.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "pop-fail__title"
  }, [_vm._v("make persistent efforts")])])])] : _vm.taskInfo.popReward.icon !== 'SPINS' && _vm.taskInfo.popReward.icon !== 'CRY' && _vm.show ? _c('div', {
    staticClass: "popCoinReward",
    class: [_vm.anim.step7 && 'step7', _vm.anim.step6 && 'step6', _vm.anim.step5 && 'step5', _vm.anim.step4 && 'step4', _vm.anim.step1 && 'step1', _vm.anim.step2 && 'step2', _vm.anim.step3 && 'step3', !_vm.taskInfo.popReward.isAnim && 'isDisableAnim']
  }, [[_c('div', {
    staticClass: "shadow"
  }), _c('div', {
    ref: "popRewardBodyRef",
    staticClass: "popReward-body"
  }, [_c('div', {
    staticClass: "popReward-xc",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "popReward-box__container",
    attrs: {
      "id": "container"
    }
  }), _c('div', {
    staticClass: "popReward-box"
  }, [_vm.anim.step3 ? [_c('img', {
    attrs: {
      "src": require(`@images/rewardAnim/anim-2.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "popReward-box__coin",
    attrs: {
      "src": require(`@images/rewardAnim/anim-bg-COIN.png`),
      "alt": ""
    }
  })] : _vm._e(), !_vm.anim.step3 ? [_c('img', {
    attrs: {
      "src": require(`@images/rewardAnim/anim-1.png`),
      "alt": ""
    }
  })] : _vm._e(), _c('div', {
    staticClass: "popReward-box__coins"
  }, _vm._l(5, function (_) {
    return _c('span');
  }), 0), _vm.anim.stars.length > 0 ? _c('div', {
    staticClass: "popReward-box__explosion"
  }, _vm._l(_vm.anim.stars, function (star, index) {
    return _c('div', {
      key: index,
      staticClass: "star",
      style: star.style
    });
  }), 0) : _vm._e()], 2), _c('div', {
    staticClass: "popReward-main"
  }, [_c('div', {
    staticClass: "popReward-head"
  }), _c('div', {
    staticClass: "popReward-con"
  }, [_c('div', {
    staticClass: "popReward-info"
  }, [_c('div', {
    staticClass: "popReward-info__title"
  }, [_vm._v(_vm._s(_vm.$route.path == "/daily" ? "Login successful, I have received" : "Congratulations on getting"))]), _c('div', {
    staticClass: "popReward-info__reward"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm.fmt_pre(_vm.taskInfo.popReward.reward)))]), _vm.taskInfo.popReward.isAnim ? [_c('div', {
    staticClass: "popReward-btn",
    on: {
      "click": _vm.onAccept
    }
  }, [_vm._v("Get")]), _c('div', {
    staticClass: "popReward-info__balance"
  }, [_vm._v(" Balance: "), _c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _c('span', [_vm.anim.step6 ? [_vm._v(" " + _vm._s(_vm.fmt_th(_vm.userInfo.coin - _vm.taskInfo.popReward.reward)) + " ")] : _c('m-count-up', {
    attrs: {
      "endVal": Number(_vm.userInfo.coin)
    }
  })], 2)])] : [_c('div', {
    staticClass: "popReward-btn",
    on: {
      "click": _vm.onAccept
    }
  }, [_vm._v("Ok")]), _c('div', {
    staticClass: "popReward-info__balance"
  })]], 2)])])])])]], 2) : _vm._e(), _c('m-coin-anim')], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };