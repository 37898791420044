var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "lucky-page page"
  }, [_c('m-head'), _c('div', {
    staticClass: "lucky-main page-main"
  }, [_c('div', {
    staticClass: "lucky-list"
  }, [_c('div', {
    staticClass: "lucky-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/airdrop');
      }
    }
  }, [_c('div', {
    staticClass: "wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('m-lucky-item', {
    attrs: {
      "url": require('@images/lucky-icon-airdrop.jpg'),
      "name": "AIRDROP"
    }
  })], 1)]), _c('div', {
    staticClass: "lucky-item"
  }), _c('div', {
    staticClass: "lucky-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/slotMachine');
      }
    }
  }, [_c('div', {
    staticClass: "wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.8s"
    }
  }, [_c('m-lucky-item', {
    attrs: {
      "url": require('@images/lucky-icon-mathine.jpg'),
      "name": "SLOT"
    }
  })], 1)])])]), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };