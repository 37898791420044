import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
const taskVuex = createNamespacedHelpers("task");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popReward",
  props: {
    show: false
  },
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...gameVuex.mapState(["gameProgress"]),
    ...taskVuex.mapState(["taskInfo"]),
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    return {
      anim: {
        stars: [],
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
        step7: false
      },
      isRewardspinsAnim: true,
      mCoinAnimReward: 5
    };
  },
  watch: {
    show: {
      async handler(bool) {
        this.popFunc(bool, this);
        if (bool) {
          this.isRewardspinsAnim = true;
          if (this.taskInfo.popReward.icon != "SPINS") {
            this.playCoinAnim();
          }
        } else {
          this.clearAnim();
        }
      },
      deep: true
    }
  },
  methods: {
    clearAnim() {
      this.anim.stars = [];
      this.anim.step1 = false;
      this.anim.step2 = false;
      this.anim.step3 = false;
      this.anim.step4 = false;
      this.anim.step5 = false;
      this.anim.step6 = false;
      this.anim.step7 = false;
      this.taskInfo.popReward.isAnim = true;
      this.taskInfo.popReward.reward = 0;
      this.taskInfo.popReward.show = false;
      this.taskInfo.popReward.icon = "COIN";
    },
    playCoinAnim() {
      this.$nextTick(async () => {
        if (!this.taskInfo.popReward.isAnim) {
          this.anim.step1 = true;
          this.anim.step2 = true;
          this.anim.step3 = true;
          this.anim.step4 = true;
          this.anim.step5 = true;
          this.anim.step6 = true;
          this.anim.step7 = true;
          await this.setPopLoc();
          return;
        }
        await this.setPopLoc();
        this.anim.step1 = false;
        this.anim.step2 = false;
        this.anim.step3 = false;
        this.anim.step4 = false;
        this.anim.step5 = false;
        this.anim.step6 = false;
        this.anim.step7 = false;
        this.anim.stars = [];
        await new Promise(resove => setTimeout(resove, 100));
        this.anim.step1 = true;
        await new Promise(resove => setTimeout(resove, 300));
        this.anim.step2 = true;
        setTimeout(() => {
          this.createStars();
        }, 400);
        await new Promise(resove => setTimeout(resove, 500));
        this.anim.step3 = true;
        this.createFlowerEffect();
        this.anim.step4 = true;
        await new Promise(resove => setTimeout(resove, 600));
        this.anim.step5 = true;
        await new Promise(resove => setTimeout(resove, 100));
        this.anim.step6 = true;
        setTimeout(() => {
          this.anim.step6 = false;
          this.anim.step7 = true;
        }, 1000);
      });
    },
    setPopLoc() {
      return new Promise(resove => {
        const popRewardBodyRef = this.$refs.popRewardBodyRef;
        const children = popRewardBodyRef.children[0];
        this.$nextTick(() => {
          setTimeout(() => {
            const pwidth = popRewardBodyRef.offsetWidth;
            const pheight = popRewardBodyRef.offsetHeight;
            const pathValue = `M ${pwidth / 2 + 100} ${pheight / 2 + 50} Q 185 40, ${pwidth / 2} ${pheight / 2}`;
            children.style.offsetPath = `path("${pathValue}")`;
            resove();
          }, 100);
        });
      });
    },
    onAccept() {
      this.clearAnim();
      this.$emit("update:show", false);
    },
    async onSpinAccept() {
      this.$nextTick(async () => {
        this.isRewardspinsAnim = false;
        this.taskInfo.popCoinAnim.reward = this.taskInfo.popReward.reward;
        this.taskInfo.popCoinAnim.show = true;
        this.$emit("update:show", false);
        this.clearAnim();
      });
    },
    onFailAccept() {
      this.clearAnim();
      this.$emit("update:show", false);
    },
    createStars() {
      let starsArray = [];
      for (let i = 0; i < 30; i++) {
        const angle = Math.random() * 360; // 随机角度
        const distance = Math.random() * 100 + 50; // 随机距离（50px - 250px）
        const x = Math.cos(angle * Math.PI / 180) * distance;
        const y = Math.sin(angle * Math.PI / 180) * distance;
        const delay = Math.random() * 0.5; // 随机延迟播放动画
        // 随机生成颜色
        starsArray.push({
          style: {
            "--x": `${x}px`,
            // 确保变量以字符串的形式带有单位
            "--y": `${y}px`,
            animationDelay: `${delay}s` // 延迟时间
          }
        });
      }

      this.anim.stars = starsArray;
      console.log("this.anim.stars", this.anim.stars);
    },
    createFlowerEffect() {
      const container = document.getElementById("container");
      const totalFlowers = 15; // 一共30个粒子

      // 创建撒花的粒子
      function createFlower() {
        const flower = document.createElement("div");
        flower.classList.add("flower");
        // 随机生成颜色（RGB 格式）
        function getRandomColor() {
          const r = Math.floor(Math.random() * 256);
          const g = Math.floor(Math.random() * 256);
          const b = Math.floor(Math.random() * 256);
          return `rgb(${r}, ${g}, ${b})`;
        }
        // 随机设置每个粒子出现的位置、旋转角度和动画持续时间
        const startX = Math.random() * (container.offsetWidth - 30); // 粒子的横向随机位置
        const startAngle = Math.random() * 360; // 随机旋转角度，范围是 0 至 360 度
        const duration = Math.random() * 1 + 0.2; // 持续时间 2 - 5秒
        const delay = Math.random() * 1.2; // 随机延迟时间
        const color = getRandomColor(); // 随机颜色

        flower.style.left = `${startX}px`;
        flower.style.transform = `rotate(${startAngle}deg)`; // 设置初始旋转角度
        flower.style.animationDuration = `${duration}s`;
        flower.style.animationDelay = `${delay}s`;
        flower.style.backgroundColor = color; // 设置粒子的颜色

        container.appendChild(flower);

        // 粒子动画结束后移除
        flower.addEventListener("animationend", () => {
          container.removeChild(flower);
        });
      }

      // 初始化30个粒子，逐个添加到页面
      for (let i = 0; i < totalFlowers; i++) {
        createFlower();
      }
    }
  },
  mounted() {}
};