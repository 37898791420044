import moment from "moment";
import store from "@/store";
import BigNumber from "bignumber.js";
export default {
  //格式化地址，len表示除了中间省略的部份以外的长度
  fmt_addr: function (value, len) {
    if (!value) return;
    let sect_len = Math.floor(len / 2);
    let start = value.substr(0, sect_len);
    let end = value.substr(-sect_len + 1, sect_len);
    return start + "..." + end;
  },
  toThousands: function (num) {
    let numS = num;
    let numF = "";
    if(num.toString().indexOf(".") !==-1){
      numS = num.toString().split(".")[0];
      numF = num.toString().split(".")[1];
    }
    
    
    return (numS || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + (numF ? "." + numF : "");
  },
  /**
   * 大于1k会自动转换为1k digits是小数点保留位数
   * @param {*} num
   * @param {*} digits
   * @returns
   */
  nFormatter: function (num, digits) {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  },
  //科学计数转成字符串
  fmt_sc(num) {
    const str = num.toString();
    // 检查是否是科学计数法表示
    if (/e/i.test(str)) {
      return num.toFixed(18); // 保留18位精度
    }
    return str;
  },
  //千分位
  fmt_th(_num, decimalPlaces) {
    if (!_num) {
      return _num;
    }
    let str = _num.toString();
    if (/e/i.test(str)) {
      str = _num.toFixed(18);
    }
    let [integerPart, decimalPart = ""] = str.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPlaces != null) {
      decimalPart = decimalPart.slice(0, decimalPlaces);
    }
    return decimalPlaces != null ? `${formattedInteger}${decimalPart ? `.${decimalPart}` : ""}` : formattedInteger;
  },

  //保留小数后位数,后面0忽略
  fmt_toPre(num, decimalPlaces = 18) {
    if (!num) return 0;
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(num * factor) / factor;
  },

  fmt_pre(num, leng = 2, isFormatted = true, isThousand = false, maxZeroCount = 7) {
    if(num===null || num===undefined) {
      return num
    }
    // 处理 BigNumber.js 对象
    if (num && typeof num === "object" && (num.isBigNumber || (typeof BigNumber !== "undefined" && BigNumber.isBigNumber(num)))) {
      try {
        num = num.toNumber(); // 尝试转换为普通 number
      } catch (e) {
        num = num.toFixed(20); // 如果超限，转换为字符串
      }
    }
  
    // 安全检查：非法输入直接返回 "0"
    if (typeof num === "string" && !isNaN(num.trim())) {
      num = Number(num.trim()); // 处理字符串数字
    }
    if (typeof num !== "number" || isNaN(num) || num === null || num === undefined) {
      return "0"; // 过滤非法值
    }
  
    // 处理 Infinity
    if (!isFinite(num)) {
      return num > 0 ? "∞" : "-∞";
    }
  
    // 科学计数法转换为普通字符串
    const toPlainString = (num) => {
      if (Math.abs(num) < 1.0) {
        let e = num.toString().split("e-");
        if (e.length > 1) {
          let zeroCount = parseInt(e[1], 10);
          let fixedNum = num.toFixed(zeroCount + 5);
          return fixedNum.replace(/\.?0+$/, "");
        }
      }
      return num.toString();
    };
  
    // 千分位分隔符
    const thousandsSeparator = (n) => {
      if (typeof n !== "number" && typeof n !== "string") return "0";
      let str = n.toString();
      if (!str) return "0"; 
      
      const strSplit = str.split(".");
      const integer = strSplit[0].split("").reverse();
      const decimal = strSplit[1] || "";
      const newInteger = [];
  
      for (let i = 0; i < integer.length; i++) {
        if (i % 3 === 0 && i !== 0) newInteger.push(",");
        newInteger.push(integer[i]);
      }
      newInteger.reverse();
      return decimal ? `${newInteger.join("")}.${decimal}` : newInteger.join("");
    };
  
    // 处理浮点数精度
    const formatFloat = (num, len) => {
      if (typeof num !== "number") return "0";
      const reg = new RegExp(`^(.*\\..{${len}}).*$`);
      return Number(String(num).replace(reg, "$1"));
    };
  
    // 处理极小小数
    const handleSmallDecimal = (num, len) => {
      if (num === 0) return "0";
      
      let numStr = toPlainString(num);
      if (typeof numStr !== "string" || numStr.length === 0) return "0";
  
      if (numStr.startsWith("0.") && numStr.length > maxZeroCount) {
        let zeroCount = (numStr.slice(2)).match(/^0+/)?.[0]?.length || 0;
        if (zeroCount > maxZeroCount) {
          return "0";
        } else {
          if(zeroCount>3) { //小数精度大于3位则采用 0.0{x}yyyy格式
            let decimalPart = numStr.slice(2 + zeroCount, 2 + zeroCount + len) || "";
            decimalPart = decimalPart.replace(/0+$/, ""); // 去掉末尾 0
            return `0.0{${zeroCount}}${decimalPart}`;
          }else {
            //小数位没有连续0的时候 精度上限为maxZeroCount-2 
            let decimalPart = numStr.slice(2, maxZeroCount) || "";
            decimalPart = decimalPart.replace(/0+$/, ""); // 去掉末尾 0
            return `0.${decimalPart}`;
          }
        }
      } else {
        return formatFloat(num, maxZeroCount-2);
      }
    };
  
    // 处理大数缩写（T、B、M、K）
    const formatLargeNumbers = (num, precision) => {
      let map = [
        { suffix: "Sp", threshold: 1e24 }, 
        { suffix: "Sx", threshold: 1e21 }, 
        { suffix: "Qn", threshold: 1e18 },
        { suffix: "Q", threshold: 1e15 }, //确保位数过大时影响UI
        { suffix: "T", threshold: 1e12 }, 
        { suffix: "B", threshold: 1e9 },   
        { suffix: "M", threshold: 1e6 }, 
      ];
      if (isThousand) {
        map.push({ suffix: "K", threshold: 1e3 });
      } else {
        map.push({ suffix: "", threshold: 1 });
      }
  
      const found = map.find((x) => Math.abs(num) >= x.threshold);
      if (!found) return thousandsSeparator(handleSmallDecimal(num, precision));
  
      let formatted = formatFloat(num / found.threshold, precision) + found.suffix;
      return thousandsSeparator(formatted);
    };
  
    // 处理极小数
    if (Math.abs(num) < Math.pow(10, -maxZeroCount)) {
      return handleSmallDecimal(num, leng); // 确保返回的是实际数值
    }
  
    // 处理格式化
    if (isFormatted) {
      return formatLargeNumbers(num, leng);
    }
  
    return thousandsSeparator(handleSmallDecimal(num, leng));
  },

  fmt_cjs(stamp) {
    let _stamp = stamp;
    //天数
    var days = Math.floor(_stamp / (60 * 60 * 24));
    //取模（余数）
    var modulo = _stamp % (60 * 60 * 24);
    //小时数
    var hours = Math.floor(modulo / (60 * 60));
    hours = hours < 10 ? "0" + hours : hours;
    modulo = modulo % (60 * 60);
    //分钟
    var minutes = Math.floor(modulo / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    //秒
    var seconds = parseInt(modulo % 60);
    seconds = seconds < 10 ? "0" + seconds : seconds;
    if (!isNaN(days) || !isNaN(hours) || !isNaN(minutes) || !isNaN(seconds)) {
      if (days > 0) {
        return `${days} day`;
      } else if (hours > 0) {
        return `${hours}:${minutes}:${seconds}`;
      } else {
        return `${minutes}:${seconds}`;
      }
    } else {
      return "-- DAY --:--:--";
    }
  },
  fmt_float(num, len = 5) {
    const reg = new RegExp(`^(.*\\..{${len}}).*`);
    let _num = String(num).replace(reg, "$1");
    console.log("reg", _num);
    return Number(_num);
  },

  /**
   * 格式化时间
   */
  fmt_time(time = new Date().getTime()) {
    if(!time) {
      return moment()
    }
    return moment(time);
  },

  fmt_unique(arr, uniId) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
  },
  //首字母大写
  fmt_cf(str, type) {
    if (!type) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else if (type === "all") {
      return str.toLowerCase();
    }
  },
  //节流
  fmt_throttle(fn, delay) {
    let isThrottled = false;
    let lastArgs = null;

    return function (...args) {
      if (isThrottled) {
        lastArgs = args; // 记录最后一次传入的参数
        return;
      }

      fn(...args); // 执行函数

      isThrottled = true;
      setTimeout(() => {
        if (lastArgs) {
          fn(...lastArgs); // 执行上次记录的函数
          lastArgs = null; // 清空记录
        }
        isThrottled = false;
      }, delay);
    };
  },
  /**
   * 格式化时间部分，不含日期
   * @param {*} time 秒数
   * @returns
   */
  formatHour(time) {
    let hour = ("0" + Math.floor(time / 3600)).slice(-2);
    let minutes = ("0" + Math.floor((time % 3600) / 60)).slice(-2);
    let seconds = ("0" + ((time % 3600) % 60)).slice(-2);
    return hour + ":" + minutes + ":" + seconds;
  },
  getUserShortName(item) {
    let str = "";
    if (item.firstname) {
      str += item.firstname.substring(0, 1);
    }
    if (item.lastname) {
      str += item.lastname.substring(0, 1);
    }
    console.log(str);
    return str;
  },
};
