import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "mBtnMathine",
  props: {
    isNoStyle: {
      default: false
    },
    type: {
      default: ""
    }
  },
  computed: {},
  data() {
    return {
      popPromptInfo: null,
      popPromptShow: false
    };
  },
  watch: {
    watch: {
      isLogin: {
        async handler(bool) {
          if (bool) {}
        },
        deep: true,
        immediate: true
      }
    }
  },
  methods: {
    showPop() {
      this.hapticFeedback();
      this.$router.push("/slotMachine");
    }
  },
  mounted() {}
};