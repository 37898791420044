var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mFirendShareOrReward"
  }, [_c('div', {
    staticClass: "firends-intro wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('div', {
    staticClass: "firends-intro-main"
  }, [_c('div', {
    staticClass: "firends-intro__title"
  }, [_vm._v("When my friend levels up")]), _c('div', {
    staticClass: "firends-intro__label"
  }, [_c('p', [_vm._v("Get " + _vm._s(_vm.fmt_pre(_vm.info?.rule?.upgradeBonus?.[0]?.coin ?? 0)) + " HID fom my friends,")]), _c('p', [_vm._v("+ " + _vm._s(_vm.fmt_pre(_vm.info?.rule?.upgradeBonus?.[1]?.coin ?? 0)) + " HID from their referrals,")]), _c('p', [_vm._v("+ " + _vm._s(_vm.fmt_pre(_vm.info?.rule?.upgradeBonus?.[2]?.coin ?? 0)) + " HlD from their referrals")])]), _c('div', {
    staticClass: "firends-intro__price"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-hic.png'),
      "alt": ""
    }
  }), _c('m-count-up', {
    attrs: {
      "endVal": _vm.earnings?.upgradeBonus?.coin ?? 0
    }
  })], 1)])])]), _c('div', {
    staticClass: "firends-share wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "firends-share-main"
  }, [_c('div', {
    staticClass: "firends-share__title"
  }, [_vm._v("I get " + _vm._s(_vm.fmt_pre((_vm.info?.rule?.paymentBonusRate?.stars ?? 0) * 100) + "%") + " of the stars from friends.")]), _c('div', {
    staticClass: "firends-share__price"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-star.png'),
      "alt": ""
    }
  }), _c('m-count-up', {
    attrs: {
      "endVal": _vm.earnings?.paymentBonus?.stars ?? 0
    }
  })], 1)]), _vm.info?.level && _vm.info.level.nextLevel != -1 ? _c('div', {
    staticClass: "firends-share-level"
  }, [_c('div', {
    staticClass: "firends-level"
  }, [_c('div', {
    staticClass: "level firends-level__current"
  }, [_vm._v(_vm._s("Lv." + _vm.info.level.currentLevel))]), _c('div', {
    staticClass: "firends-level__arrow",
    domProps: {
      "innerHTML": _vm._s(_vm.svg_level_arrow)
    }
  }), _c('div', {
    staticClass: "level firends-level__next"
  }, [_vm._v(_vm._s("Lv." + _vm.info.level.nextLevel))])]), _c('div', {
    staticClass: "firends-info"
  }, [_vm._v("I get " + _vm._s(_vm.fmt_pre((_vm.info?.rule?.nextPaymentBonusRate?.stars ?? 0) * 100) + "%") + " of the stars from friends.")])]) : _vm._e()])]), _c('div', {
    staticClass: "firends-btns wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.7s"
    }
  }, [_c('div', {
    staticClass: "btn-share",
    on: {
      "click": _vm.onShare
    }
  }, [_vm._v("Share & Reward")]), _c('div', {
    staticClass: "btn-copy",
    on: {
      "click": _vm.onCopy
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1)]), _c('div', {
    staticClass: "firends-rank"
  }, [_c('div', {
    staticClass: "firends__title"
  }, [_vm._v(" My friends "), _c('span', [_vm._v("(" + _vm._s(_vm.fmt_pre(_vm.ranking?.total)) + ")")])]), _vm.ranking && _vm.ranking.total > 0 ? _c('div', {
    staticClass: "firends-list"
  }, _vm._l(_vm.ranking.list, function (item, index) {
    return _c('div', {
      staticClass: "firends-item"
    }, [_c('div', {
      staticClass: "firends-rank__img"
    }, [_c('m-head-img', {
      attrs: {
        "name": item,
        "bgColor": item.head_color
      }
    })], 1), _c('div', {
      staticClass: "firends-rank__name"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "firends-rank__level"
    }, [_vm._v(_vm._s("LV." + item.user_level))])]);
  }), 0) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };