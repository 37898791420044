import "core-js/modules/es.array.push.js";
export default {
  name: "mMenu",
  data() {
    const menuList = [{
      label: "Rewards",
      value: "rewards"
    }, {
      label: "Lucky",
      value: "lucky"
    }, {
      label: "Human",
      value: "human"
    }, {
      label: "Upgrade",
      value: "upgrade"
    }, {
      label: "Friends",
      value: "friends"
    }];
    return {
      menuList,
      selectMenu: null
    };
  },
  watch: {
    "$route.path": {
      handler(path) {
        if (path === "/") {
          return this.selectMenu = "human";
        }
        this.selectMenu = path.split("/")[1];
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onMenu(item) {
      this.hapticFeedback();
      if (item.value === "human") {
        this.selectMenu = "human";
        this.$router.push(`/`);
        return;
      }
      this.selectMenu = item.value;
      this.$router.push(`/${item.value}`);
    }
  }
};