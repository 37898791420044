import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import FireworkEffect from "@assets/js/fireworkEffect.js";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "slotMachine",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userInfo", "balanceInfo", "settingInfo"]),
    ...gameVuex.mapState(["gameProgress", "levelIntro"]),
    ...taskVuex.mapState(["taskInfo"]),
    ...taskVuex.mapGetters(["isAnyPaymentSpinsLoading"])
  },
  data() {
    return {
      slotMachineConfig: null,
      slotMachineResult: null,
      slotMachineAllInfo: null,
      slotMachineInfo: {
        stock: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        },
        tap: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        },
        spins: {
          price_spins: 0
        }
      },
      slotMachineNum: 3,
      fireworkEffect: null,
      isMachineArrowAnim: false,
      isSlotMachineSelect: false,
      isPopResultShow: false,
      coinResultRef: null,
      selectRow: null,
      //抽到的奖品
      isFlag: true,
      lotteryId: null,
      isInit: false,
      isstockLoading: false,
      istapLoading: false,
      myRecordIsLoading: false,
      myRecordIsFinished: false,
      popFailShow: false,
      isRefresh: false,
      popPromptInfo: null,
      popPromptShow: false,
      count_options: [],
      //倍数列表
      selectCountOptions: 1,
      //当前选择的倍数
      countStep: 0,
      //当前选择倍数在列表中的索引值
      deductionSpins: 1,
      //每次扣除老虎机条数(含倍数)
      isMultipleInfoShow: false,
      multipleInfoShowTimer: null
    };
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    this.isPopResultShow = false;
    this.popFailShow = false;
    setTimeout(() => {
      next();
    }, 100);
  },
  watch: {},
  methods: {
    ...userVuex.mapMutations(["setUserInfo"]),
    ...userVuex.mapActions(["getLuckyLotteryList", "getSpinsBalance"]),
    ...gameVuex.mapActions(["getSpinsPriceList"]),
    async selectMultiple() {
      if (!this.isFlag) return;
      const machineMultipleRef = this.$refs.machineMultipleRef;
      if (!machineMultipleRef) return;
      this.hapticFeedback();
      this.countStep++;
      clearTimeout(this.multipleInfoShowTimer);
      if (this.balanceInfo.spins.balance < this.count_options[this.countStep] * this.slotMachineInfo.spins.price_spins) {
        this.countStep = 0;
      }
      if (this.count_options.length <= this.countStep) {
        this.countStep = 0;
      }
      this.selectCountOptions = this.count_options[this.countStep];
      this.deductionSpins = this.slotMachineInfo.spins.price_spins * this.selectCountOptions;
      //按钮点击效果
      machineMultipleRef.classList.add("active");
      setTimeout(() => {
        machineMultipleRef.classList.remove("active");
      }, 150);
      //倍数提示窗口
      if (this.countStep == 0) {
        this.isMultipleInfoShow = false;
        return;
      } //1倍不弹出 奖励倍数弹窗
      this.isMultipleInfoShow = true;
      this.multipleInfoShowTimer = setTimeout(() => {
        this.isMultipleInfoShow = false;
      }, 3000);
      console.dir(machineMultipleRef);
    },
    //获取老虎机基础配置
    async postSlotMachineInfo() {
      const res = await this.getLuckyLotteryList({
        provider: "official",
        type: 1
      });
      if (!res) {
        this.lotteryId = -1;
        return;
      }
      if (res.length <= 0) {
        this.lotteryId = -1;
        return;
      }
      const data = res[0];
      if (data.hasOwnProperty("price_extras") && typeof data.price_extras === "string") {
        price_extras = JSON.parse(data.price_extras);
      }
      this.slotMachineInfo.spins.price_spins = data.price_spins;
      this.slotMachineInfo = {
        ...data,
        ...this.slotMachineInfo
      };
      this.lotteryId = data.id;
      this.count_options = data.count_options;
      if (this.count_options && this.count_options.length > 0) {
        this.countStep = 0;
        this.selectCountOptions = data.count_options[this.countStep];
      }
    },
    //设置老虎机
    async setSlotMachineConfig() {
      const element = ["SPINS", "COIN", "LGSPINS", "LGCOIN", "SPINS", "COIN", "LGSPINS", "LGCOIN", "COIN"];
      let slotMachineConfig = {};
      for (let i = 0; i < this.slotMachineNum; i++) {
        slotMachineConfig[i] = element;
        console.log("element", slotMachineConfig[i]);
      }
      this.$nextTick(() => {
        this.isFlag = false;
        //进入页面 老虎机滚动动画
        this.$refs.mSlotMachineRef.initSpeed = 300;
        this.$refs.mSlotMachineRef.incremNum = 500;
        this.slotMachineConfig = slotMachineConfig;
        this.$refs.mSlotMachineRef.setConfig("init");
        this.isInit = true;
        setTimeout(() => {
          this.$refs.mSlotMachineRef.turn({
            id: 10,
            title: "50 USDT",
            icon: "",
            disp_combo: ["COIN", "COIN", "COIN"],
            currency: "COIN",
            amount: 50
          });
        }, 100);
      });
    },
    //获取老虎机结果
    async postSlotMachineResult() {
      this.isLoading = true;
      const res = await this.$http.post("/lucky/lottery/draw", {
        lotteryId: this.lotteryId,
        count: this.selectCountOptions
      });
      console.log("ressssssssss", res);
      if (!res) {
        this.isLoading = false;
        return;
      }
      if (res.code > 0) {
        this.$toasted.clear();
        if (!res.message.includes("does not exist")) {
          this.$toasted.error(res.message);
        }
        this.initFnc();
        return;
      }
      const data = res.data;
      if (!data) {
        this.$toasted.clear();
        this.$toasted.error(res.message);
        this.isLoading = false;
        return;
      }
      const winning_item = data.winning_item;
      const mapping = {
        SMSPINS: "SPINS",
        SMCOIN: "COIN",
        LGCOIN: "LGCOIN",
        LGSPINS: "LGSPINS"
      };
      winning_item.disp_combo_new = winning_item.disp_combo.map(item => mapping[item] || item);
      this.slotMachineResult = data.winning_item;
      this.slotMachineAllInfo = {
        user: data.user,
        lottery: data.lottery,
        winning_item: data.winning_item
      };
      this.slotMachineInfo.spins.price_spins = data.lottery.price_spins / this.selectCountOptions;
      console.log("=======123", this.slotMachineInfo.spins.price_spins);
      this.selectRow = {
        ...this.slotMachineResult,
        value: this.slotMachineResult.amount,
        type: this.slotMachineResult.currency
      };
      this.isLoading = false;
    },
    //开始游戏
    async slotMachineTurn(callback) {
      console.log("this.lotteryId", this.lotteryId);
      if (this.lotteryId < 0) {
        this.isFlag = true;
        this.initFnc("refresh");
        return;
      }
      await this.postSlotMachineResult();
      if (!this.slotMachineResult) {
        this.isFlag = true;
        return;
      }
      if (this.fireworkEffect) {
        this.playFirworkEffect();
      }
      this.isInit = false;
      this.isMultipleInfoShow = false;
      this.deductionSpins = this.slotMachineInfo.spins.price_spins * this.selectCountOptions;
      this.balanceInfo.spins.balance -= this.deductionSpins;
      this.$refs.mSlotMachineRef.initSpeed = 1500;
      this.$refs.mSlotMachineRef.incremNum = 1000;
      this.$refs.mSlotMachineRef.setConfig();
      callback(this.slotMachineResult);
    },
    //转动时 两边箭头抖动并产生摩擦效果 设置阶段
    setFirworkEffect() {
      this.$nextTick(() => {
        const canvas = document.getElementById("fireworkCanvas");
        const leftDiv = document.getElementById("machineLeftArrowRef");
        const rightDiv = document.getElementById("machineRightArrowRef");
        this.fireworkEffect = new FireworkEffect({
          canvas: canvas,
          leftDiv: leftDiv,
          rightDiv: rightDiv,
          config: {
            numParticles: 1,
            minSpeed: 1,
            maxSpeed: 8,
            minLength: 2,
            maxLength: 8,
            flameColorMin: 20,
            flameColorMax: 40,
            spreadRange: Math.PI,
            maxParticles: 200
          },
          parentWidth: this.$refs.machineArrowsRef.offsetWidth,
          parentHeight: this.$refs.machineArrowsRef.offsetHeight
        });
      });
    },
    //转动时 两边箭头抖动并产生摩擦效果 开始阶段
    playFirworkEffect() {
      if (this.fireworkEffect && !this.fireworkEffect.isCreating) {
        this.isMachineArrowAnim = true;
        this.fireworkEffect.isCreating = true;
        this.fireworkEffect.startFirework();
      }
    },
    //转动时 两边箭头抖动并产生摩擦效果 暂停阶段
    stopFirworkEffect() {
      this.isMachineArrowAnim = false;
      if (!this.fireworkEffect) return;
      this.fireworkEffect.stopFirework();
    },
    //游戏结束
    async slotMachineFinish(val) {
      if (this.isInit) {
        this.isFlag = true;
        return;
      }
      if (val == 2) {
        this.stopFirworkEffect();
        return;
      }
      //转盘结束时
      this.isSlotMachineSelect = true;
      await new Promise(resolve => setTimeout(resolve, 800));
      this.isSlotMachineSelect = false;
      //检测是否至少有一个哭脸 则显示哭脸弹窗
      console.log("this.slotMachineResult", this.slotMachineResult);
      const bool = this.slotMachineResult.disp_combo_new.some(itm => {
        return itm === "CRY";
      });
      if (bool || this.slotMachineResult.amount <= 0) {
        this.taskInfo.popReward.icon = "CRY";
        return;
      }
      this.taskInfo.popReward.reward = this.slotMachineResult.amount;
      this.taskInfo.popReward.icon = this.slotMachineResult.currency;
      await new Promise(resolve => setTimeout(resolve, 500));
      if (this.slotMachineResult.currency !== "SPINS") {
        this.getSpinsBalance();
      }
      this.setUserInfo(this.slotMachineAllInfo.user);
      this.taskInfo.popReward.show = true;
      await new Promise(resolve => setTimeout(resolve, 500));
      console.log("========7777", this.balanceInfo.spins.balance, this.selectCountOptions, this.selectCountOptions * this.slotMachineInfo.spins.price_spins);
      if (this.balanceInfo.spins.balance < this.selectCountOptions * this.slotMachineInfo.spins.price_spins) {
        //如果余额小于当前所需倍速消耗的次数 已选择的倍数就恢复初始
        this.countStep = 0;
        this.selectCountOptions = 1;
      }
      this.isFlag = true;
    },
    onMachineBtnUp(type) {
      this.$nextTick(() => {
        if (this.balanceInfo.spins.balance < this.selectCountOptions * this.slotMachineInfo.spins.price_spins) {
          return;
        }
        if (!this.isFlag) return;
        this.isFlag = false;
        this.hapticFeedback();
        const mSlotMachineRef = this.$refs.mSlotMachineRef;
        mSlotMachineRef.turn();
      });
    },
    async initFnc(type) {
      this.slotMachineConfig = null;
      this.slotMachineResult = null;
      this.lotteryId = null;
      await this.postSlotMachineInfo();

      // if (this.$refs.mDanmakuRef) {
      //   this.$refs.mDanmakuRef.getDanmakuInfo(this.lotteryId);
      // }

      if (type == "refresh" && this.isRefresh) {
        return;
      }
      if (type == "refresh") {
        this.isRefresh = true;
      }
      this.slotMachineConfig = null;
      this.slotMachineResult = null;
      this.slotMachineInfo = {
        stock: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        },
        tap: {
          price_stock: 0,
          price_coin: 0,
          price_tap: 0
        },
        spins: {
          price_spins: 0
        }
      };
      this.lotteryId = null;
      await this.postSlotMachineInfo();
      if (type == "refresh") {
        setTimeout(() => {
          if (this.lotteryId < 0) {
            this.$toasted.clear();
            this.$toasted.info("Under Maintenance!");
          }
          this.isRefresh = false;
        }, 2000);
      }
      // if (this.$refs.mDanmakuRef) {
      //   this.$refs.mDanmakuRef.getDanmakuInfo(this.lotteryId);
      // }
    },

    showPop(type) {
      this.hapticFeedback();
      if (type == "maxspins") {
        this.popPromptInfo = {
          title: "I am not a robot",
          icon: require("@images/icon/icon-up.png"),
          confirmText: "Upgrade",
          reward: `Max Spins +${this.levelIntro[this.gameProgress.nextLevel][1] - this.levelIntro[this.gameProgress.currentLevel][1]}`,
          confirmFn: () => this.$router.push("/upgrade")
        };
      }
      this.popPromptShow = true;
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.setWowAnim();
      this.setFirworkEffect();
      this.setSlotMachineConfig();
      await this.initFnc();
    });
  }
};