var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mLoading"
  }, [_c('div', {
    staticClass: "mLoading-img"
  }), _c('div', {
    staticClass: "mLoading-load"
  }, [_c('div', {
    staticClass: "flippers"
  }, _vm._l(5, function (_) {
    return _c('div');
  }), 0)]), _c('div', {
    ref: "imgsRef",
    staticClass: "imgs",
    style: {
      backgroundImage: _vm.backgroundImage
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };