var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mPass"
  }, [_c('div', {
    staticClass: "mPass-main"
  }, [_c('div', {
    staticClass: "mPass__title"
  }, [_vm._v("MY HUMAN PASS")]), _c('div', {
    staticClass: "mPass__label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "mPass-title__ad"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };