import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
export default {
  name: "facescan",
  computed: {
    ...userVuex.mapState(["userInfo", "settingInfo"]),
    ...facescanVuex.mapState(["rId"])
  },
  data() {
    return {
      url: 'https://verify.biomatrix.ai/face-scan?eventCode=TAPCOINANTIBOT&channelCode=HVGZ2R&requestId=',
      uid: '',
      requestId: '',
      iframeUrl: '',
      loading: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.uid = '';
    this.iframeUrl = '';
    this.requestId = '';
    this.loading = false;
    window.removeEventListener('message', this.callback);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    this.WebApp.BackButton.hide();
    window.addEventListener('message', this.callback);
    this.uid = this.userInfo.tg_id;
    this.requestId = this.$route.query.id;
    this.loading = true;
    if (this.requestId && this.uid) {
      this.iframeUrl = this.url + this.decompressRequestId(this.requestId) + '&uid=' + this.uid;
    } else {
      let inviteCode = this.$route.query.invitecode;
      if (inviteCode) {
        this.getRid();
      }
    }
    setTimeout(() => {
      this.WebApp.BackButton.hide();
    }, 0);
  },
  methods: {
    ...facescanVuex.mapActions(['getRequestId']),
    callback(event) {
      if (event.data === 'callParentMethod') {
        this.WebApp.close();
      }
    },
    decompressRequestId(compressedRequestId) {
      if (compressedRequestId) {
        const appid = compressedRequestId.slice(0, -32);
        const parts = [compressedRequestId.slice(-32, -24), compressedRequestId.slice(-24, -20), compressedRequestId.slice(-20, -16), compressedRequestId.slice(-16, -12), compressedRequestId.slice(-12)];
        let str = appid ? appid + '_' : '';
        for (const i in parts) {
          if (parts[i]) {
            str += parts[i] + (i < 4 ? '-' : '');
          }
        }
        return str;
      }
    },
    async getRid() {
      await this.getRequestId('');
      if (this.rId) {
        this.iframeUrl = this.url + this.rId + '&uid=' + this.uid;
      }
    },
    onClose() {
      this.WebApp.close();
    },
    start() {
      this.loading = false;
    }
  }
};