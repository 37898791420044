import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import formatter from "@utils/formatter";
export default {
  name: "nCurrencyTwo",
  props: {
    itemBg: {
      type: String,
      default: "#0000004d"
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...userVuex.mapState(["userData"]),
    fmt_pre() {
      return formatter.fmt_pre;
    }
  }
};