import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "upgrade",
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...gameVuex.mapState(["propertyList", "gameProgress"]),
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    const upgradeList = [];
    return {
      selectTab: null,
      upgradeList,
      popUpgradeResultShow: false,
      selectUpgradeRow: null,
      upgradeResultInfo: {
        level: null,
        isAnim: false,
        anim: {
          step1: false,
          step2: false,
          step3: false
        }
      },
      isUpgradeBotAnim: false
    };
  },
  watch: {
    isLogin: {
      async handler(bool) {
        if (bool) {
          await this.getGamePropertyList();
        }
      },
      deep: true,
      immediate: true
    },
    selectTab: {
      handler(newVal) {
        this.propertyList.map(item => {
          item.properties.sort((a, b) => {
            if (a.step >= 10 && b.step >= 10) return 0; // 保持相对顺序
            if (a.step >= 10) return 1; // step >= 10 的排后面
            if (b.step >= 10) return -1;
            // return b.step - a.step; // step < 10 的按降序排列
          });
        });
      }
    },

    "propertyList.length": {
      handler(len) {
        if (len > 0) {
          this.selectUpgradeRow = this.propertyList[0].properties;
        }
      },
      deep: true,
      immediate: true
    },
    "gameProgress.currentLevel": {
      async handler(newLevel, oldLevel) {
        if (oldLevel !== undefined && oldLevel !== null && newLevel != oldLevel) {
          this.$httpClear("/game/property/list");
          this.getGamePropertyList();
          this.popUpgradeResultShow = true;
          await new Promise(resolve => setTimeout(resolve, 1000));
          this.upgradeResultInfo.anim.step1 = true;
          await new Promise(resolve => setTimeout(resolve, 300));
          this.upgradeResultInfo.level = this.gameProgress.currentLevel;
          this.upgradeResultInfo.anim.step2 = true;
          await new Promise(resolve => setTimeout(resolve, 300));
          this.setWowAnim();
          this.upgradeResultInfo.anim.step3 = true;
          this.setConfetti();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...gameVuex.mapActions(["upgradeAvatar", "getGamePropertyList"]),
    ...userVuex.mapActions(["getMyInfo"]),
    ...userVuex.mapMutations(["setUserInfo"]),
    setConfetti() {
      this.confetti({
        particleCount: 15,
        angle: 60,
        spread: 55,
        origin: {
          x: 0
        },
        zIndex: 2000,
        colors: ["9cfd09", "3edf46", "0ccf66", "39580e", "a0fe07"]
      });
      this.confetti({
        particleCount: 15,
        angle: 120,
        spread: 55,
        origin: {
          x: 1
        },
        zIndex: 2000,
        colors: ["9cfd09", "3edf46", "0ccf66", "39580e", "a0fe07"]
      });
    },
    onTab(item) {
      console.log("onTab", item);
      if (item.properties && item.properties.length > 0) {
        this.selectUpgradeRow = item.properties;
      }
    },
    onUpgradeResultConfirm() {
      this.popUpgradeResultShow = false;
    },
    async onUpgrade(item) {
      if (item.loading) return;
      item.loading = true;
      try {
        this.WebApp.HapticFeedback.notificationOccurred("success");
      } catch (err) {}
      this.upgradeResultInfo.level = this.gameProgress.currentLevel;
      console.log("this.userInfo.coin", this.userInfo.coin, item.upgrade_cost);
      const res = await this.upgradeAvatar({
        propertyId: item.id
      });
      if (!res) {
        item.loading = false;
        return;
      }
      // await new Promise((reso) => setTimeout(reso, 500));
      await this.upgradeAnim(item);
      this.isUpgradeBotAnim = true;
      setTimeout(() => {
        this.isUpgradeBotAnim = false;
      }, 500);
      this.upgradeInfo(res, item);
    },
    upgradeAnim(item) {
      return new Promise(resolve => {
        const mLevelProgressRef = this.$refs.mLevelProgressRef?.$refs.progressRef;
        const endButtonRect = mLevelProgressRef?.getBoundingClientRect();
        const mTabRef = this.$refs.mTabRef?.$refs[`mTab_${item.category_id}`][0];
        const itemRef = this.$refs[`upgradeItem_${item.id}`][0];
        const upgradeBtnRef = itemRef.$refs?.upgradeBtnRef?.getBoundingClientRect();
        const end1ButtonRect = mTabRef.getBoundingClientRect();
        const sxRef = this.$refs.sxRef;
        const sxRef1 = this.$refs.sxRef1;
        sxRef1.style.left = sxRef.style.left = upgradeBtnRef.left + "px";
        sxRef1.style.top = sxRef.style.top = upgradeBtnRef.top + upgradeBtnRef.height / 2 + "px";
        sxRef1.style.opacity = sxRef.style.opacity = 1;
        setTimeout(() => {
          sxRef1.style.transition = sxRef.style.transition = "all 0.3s linear";
          sxRef.style.left = endButtonRect.left + endButtonRect.width - 4 + "px";
          sxRef.style.top = endButtonRect.top + endButtonRect.height / 2 - 2 + "px";
          sxRef1.style.left = end1ButtonRect.left + end1ButtonRect.width / 2 + "px";
          sxRef1.style.top = end1ButtonRect.top + end1ButtonRect.height / 2 - 2 + "px";
          setTimeout(() => {
            sxRef1.style.opacity = sxRef.style.opacity = 0;
            sxRef1.style.transition = sxRef.style.transition = "";
            resolve();
          }, 300);
        }, 300);
      });
    },
    async upgradeInfo(data, item) {
      const categoryInfo = data.categoryInfo[0];
      this.propertyList.map(itm => {
        if (itm.id == categoryInfo.id) {
          for (const key in categoryInfo) {
            if (key !== "properties") {
              this.$set(itm, key, categoryInfo[key]);
            } else {
              itm.properties.map(_it => {
                categoryInfo[key].map(it => {
                  if (_it.id == it.id) {
                    for (const ke in _it) {
                      this.$set(_it, ke, it[ke]);
                      this.$set(_it, "disabled", false); //禁用状态
                      this.$set(_it, "loading", false); //禁用状态
                      if (_it.id === item.id) {
                        this.$set(_it, "loading", true); //禁用状态
                      }
                    }
                  }
                });
              });
            }
          }
        }
      });

      setTimeout(async () => {
        console.log("this.gameProgress.currentLevel333", this.gameProgress.currentLevel, data.levelInfo.currentLevel);
        if (this.gameProgress.currentLevel !== data.levelInfo.currentLevel) {
          await this.getMyInfo();
        }
        this.gameProgress.currentLevel = data.levelInfo.currentLevel;
        this.gameProgress.nextLevel = data.levelInfo.nextLevel === -1 ? -1 : data.levelInfo.nextLevel;
        this.gameProgress.progressInfo = data.progressInfo;
        this.setUserInfo(data.userInfo);
        item.loading = false;
      }, 500);
      this.propertyList.map(item => {
        item.properties.sort((a, b) => {
          if (a.newStep >= 10 && b.newStep >= 10) return 0; // 保持相对顺序
          if (a.newStep >= 10) return 1; // step >= 10 的排后面
          if (b.newStep >= 10) return -1;
          // return b.step - a.step; // step < 10 的按降序排列
        });
      });
    }
  },

  mounted() {
    this.setWowAnim();
  },
  beforeRouteLeave(to, from, next) {
    if (this.propertyList && this.propertyList.length > 0) {
      this.propertyList.map(item => {
        item.properties.map(itm => {
          _v.$set(itm, "disabled", false); //禁用状态
          _v.$set(itm, "loading", false); //禁用状态
          this.$set(itm, "completion_rate_old", itm.completion_rate);
        });
      });
      console.log("this.propertyList", this.propertyList);
    }
    next();
  }
};