var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "slotMachine"
  }, [_c('m-head'), _c('div', {
    staticClass: "slotMachine-body page-body"
  }, [_c('div', {
    staticClass: "slotMachine-main page-main",
    style: {
      background: `url(${require(`@images/machine/machine-page-bg.jpg`)}) center top/100% auto no-repeat`
    }
  }, [_c('div', {
    staticClass: "machine",
    style: {
      background: `url(${require(`@images/machine/machine-bg.png`)}) center/auto 100% no-repeat`
    }
  }, [_c('div', {
    staticClass: "machine-main"
  }, [_c('div', {
    ref: "machineArrowsRef",
    staticClass: "machine-container"
  }, [_c('canvas', {
    attrs: {
      "id": "fireworkCanvas"
    }
  }), _c('div', {
    staticClass: "machine-menu"
  }, [_c('div', {
    staticClass: "machine-menu-bg"
  }, _vm._l(3, function (_) {
    return _c('span');
  }), 0), _c('m-slot-machine', {
    ref: "mSlotMachineRef",
    attrs: {
      "configs": _vm.slotMachineConfig,
      "results": _vm.slotMachineResult,
      "rowNum": _vm.slotMachineNum,
      "isSelect": _vm.isSlotMachineSelect
    },
    on: {
      "turn": _vm.slotMachineTurn,
      "finish": _vm.slotMachineFinish
    }
  }), _c('div', {
    staticClass: "machine-menu-shadow"
  }, _vm._l(3, function (_) {
    return _c('span');
  }), 0)], 1), _c('div', {
    staticClass: "machine-arrows"
  }, [_c('div', {
    staticClass: "machine-arrow left",
    class: _vm.isMachineArrowAnim && 'active',
    attrs: {
      "id": "machineLeftArrowRef"
    }
  }, [_c('span', {
    style: {
      background: `url(${require(`@images/machine/machine-arrow.png`)}) center/100% auto no-repeat`
    }
  })]), _c('div', {
    staticClass: "machine-arrow right",
    class: _vm.isMachineArrowAnim && 'active',
    attrs: {
      "id": "machineRightArrowRef"
    }
  }, [_c('span', {
    style: {
      background: `url(${require(`@images/machine/machine-arrow.png`)}) center/100% auto no-repeat`
    }
  })]), _c('div', {
    staticClass: "shadow"
  })]), _c('div', {
    staticClass: "machine-btns"
  }, [_vm.lotteryId == -1 ? _c('div', {
    staticClass: "maintenance",
    on: {
      "click": function ($event) {
        return _vm.initFnc('refresh');
      }
    }
  }, [_vm._v(" Under maintenance "), _c('div', {
    staticClass: "btn-refresh"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh",
      "spin": _vm.isRefresh
    }
  })], 1)]) : _vm.lotteryId > 0 ? _c('div', {
    staticClass: "machine-btn",
    class: (!_vm.isFlag || _vm.balanceInfo.spins.balance < _vm.selectCountOptions * _vm.slotMachineInfo.spins.price_spins) && 'active',
    on: {
      "click": _vm.onMachineBtnUp
    }
  }, [_c('div', {
    ref: "machineMultipleRef",
    staticClass: "machine-multiple",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.selectMultiple.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("x " + _vm._s(_vm.fmt_pre(_vm.selectCountOptions)))])])]) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "multipleInfo"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMultipleInfoShow,
      expression: "isMultipleInfoShow"
    }],
    staticClass: "machine-multiple__info"
  }, [_vm._v("All rewards x " + _vm._s(this.selectCountOptions))])])], 1)])]), _c('div', {
    staticClass: "machine-spins"
  }, [_c('div', {
    staticClass: "machine-spins__title",
    class: _vm.balanceInfo.spins.balance < _vm.selectCountOptions * _vm.slotMachineInfo.spins.price_spins && _vm.isFlag && 'err'
  }, [_vm._v(" Spins: "), _c('i', {
    attrs: {
      "id": "spinsEndId"
    }
  }, [_vm.balanceInfo.spins.balance >= _vm.selectCountOptions * _vm.slotMachineInfo.spins.price_spins ? _c('span', [_vm._v(_vm._s(_vm.fmt_pre(_vm.balanceInfo.spins.balance)))]) : _c('span', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `At least ${_vm.selectCountOptions * _vm.slotMachineInfo.spins.price_spins} spins is required`,
      expression: "`At least ${selectCountOptions * slotMachineInfo.spins.price_spins} spins is required`",
      modifiers: {
        "top": true,
        "click": true
      }
    }]
  }, [_vm._v(_vm._s(_vm.fmt_pre(_vm.balanceInfo.spins.balance)))]), _c('em', [_vm._v(" /" + _vm._s(_vm.fmt_pre(_vm.balanceInfo.spins.max)))])]), _vm.isAnyPaymentSpinsLoading ? _c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `Some spins have not been credited, and are being credited`,
      expression: "`Some spins have not been credited, and are being credited`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "circleLoading"
  }) : _vm._e()]), _vm.balanceInfo.spins?.recovery ? _c('div', {
    staticClass: "macine-spins-info"
  }, [_vm.balanceInfo.spins.recovery.countdown > 0 && _vm.balanceInfo.spins.recovery.count > 0 ? _c('div', {
    staticClass: "machine-spins__time"
  }, [_vm._v(" +" + _vm._s(_vm.balanceInfo.spins.recovery.count) + " Spins   "), _vm.balanceInfo.spins.recovery.countdown > 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-time"
    }
  }), _vm._v(" " + _vm._s(_vm.fmt_cjs(_vm.balanceInfo.spins.recovery.countdown)) + " ")] : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "machine-spins__btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-add"
    }
  }), _vm._v(" "), _c('m-pop-spins-price-list')], 1)]) : _vm._e()]), _c('div', {
    staticClass: "machine-more"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.userInfo.joined_facescan,
      expression: "!userInfo.joined_facescan"
    }],
    staticClass: "machine-more-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.2s"
    }
  }, [!_vm.userInfo.joined_facescan ? _c('div', {
    staticClass: "machine-more-item-main"
  }, [_c('div', {
    staticClass: "machine-more-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-human.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "machine-more-item__label"
  }, [_vm._v("+" + _vm._s(_vm.fmt_pre(_vm.settingInfo?.reward?.joinedSpins?.facescan)) + " Spins")])]) : _vm._e(), _c('m-btn-face', {
    attrs: {
      "type": "SPINS",
      "isNoStyle": true
    }
  })], 1), _c('div', {
    staticClass: "machine-more-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.3s"
    },
    on: {
      "click": function ($event) {
        return _vm.showPop('maxspins');
      }
    }
  }, [_c('div', {
    staticClass: "machine-more-item-main"
  }, [_c('div', {
    staticClass: "machine-more-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-up.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "machine-more-item__label"
  }, [_vm._v("Max Spins +" + _vm._s(_vm.levelIntro[_vm.gameProgress.nextLevel][1] - _vm.levelIntro[_vm.gameProgress.currentLevel][1]))])])]), _c('div', {
    staticClass: "machine-more-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('div', {
    staticClass: "machine-more-item-main"
  }, [_c('div', {
    staticClass: "machine-more-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-star.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "machine-more-item__label"
  }, [_vm._v("More Spins")])]), _c('m-pop-spins-price-list')], 1)])])]), _c('m-menu'), _c('pop-prompt', {
    attrs: {
      "isShadowClick": true,
      "title": " ",
      "isSmallConfirmBtn": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isSmallConfirmBtn') && _vm.popPromptInfo.isSmallConfirmBtn,
      "isCancelLoading": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isCancelLoading') && _vm.popPromptInfo.isCancelLoading,
      "isSmallCancelBtn": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('smallCancelBtn') && _vm.popPromptInfo.smallCancelBtn,
      "show": _vm.popPromptShow,
      "isConfirmLoading": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isConfirmLoading') && _vm.popPromptInfo.isConfirmLoading,
      "isBtnCancel": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('cancelText') && _vm.popPromptInfo.cancelText != null,
      "content": "html",
      "cancelText": _vm.popPromptInfo ? _vm.popPromptInfo.cancelText : ' ',
      "confirmText": _vm.popPromptInfo ? _vm.popPromptInfo.confirmText : ' '
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptShow = $event;
      },
      "cancel": function ($event) {
        _vm.popPromptInfo && _vm.popPromptInfo.cancelText && _vm.popPromptInfo.hasOwnProperty('cancelFn') && _vm.popPromptInfo.cancelFn();
      },
      "confirm": function ($event) {
        _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('confirmFn') && _vm.popPromptInfo.confirmFn();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('error') && _vm.popPromptInfo.error ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popPromptInfo.error)
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "pop-maxspins"
  }, [_vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('icon') && _vm.popPromptInfo.icon ? _c('div', {
    staticClass: "pop-maxspins__icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.popPromptInfo.icon,
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "pop-maxspins-level"
  }, [_c('div', {
    staticClass: "level pop-maxspins-level__current"
  }, [_vm._v(_vm._s("Lv." + _vm.gameProgress.currentLevel))]), _c('div', {
    staticClass: "pop-maxspins-level__arrow",
    domProps: {
      "innerHTML": _vm._s(_vm.svg_level_arrow)
    }
  }), _c('div', {
    staticClass: "level pop-maxspins-level__next"
  }, [_vm._v(_vm._s("Lv." + _vm.gameProgress.nextLevel))])]), _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('reward') && _vm.popPromptInfo.reward ? _c('div', {
    staticClass: "pop-maxspins__reward"
  }, [_vm._v(_vm._s(_vm.popPromptInfo.reward))]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };