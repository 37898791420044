import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
const taskVuex = createNamespacedHelpers("task");
import svgIcon from './svgIcon.vue';
export default {
  components: {
    svgIcon
  },
  name: "popFaceTip",
  computed: {
    ...walletVuex.mapState(["isMobile", 'setting']),
    ...userVuex.mapState(["userInfo", 'balanceInfo']),
    ...taskVuex.mapState(["taskInfo"])
  },
  data() {
    return {
      isVerify: false,
      isLoading: false,
      checkCount: 1,
      popFaceTipShow: false
    };
  },
  props: {},
  watch: {
    "popFaceTipShow": {
      handler(newVal, oldVal) {
        if (!newVal && typeof oldVal !== 'undefined') {
          if (FS) {
            FS.init({
              keyid: 'hpe46b04131b28d681',
              tgId: this.userInfo.tg_id
            });
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...facescanVuex.mapActions(['doCheck']),
    ...userVuex.mapMutations(["setUserInfo"]),
    doVerify() {
      if (FS) {
        FS.scan();
      }
      //this.isVerify = true
      this.isLoading = true;
      setTimeout(() => {
        this.isVerify = true;
        this.isLoading = false;
      }, 5000);
    },
    doCheckFaceScan() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      setTimeout(async () => {
        const res = await this.doCheck({
          target: "facescan"
        });
        this.isLoading = false;
        if (!res) {
          return;
        }
        this.popFaceTipShow = false;
        this.taskInfo.popReward.reward = this.settingInfo?.reward?.joined?.facescan ?? 0;
        this.balanceInfo.spins.balance = res.userInfo.spins;
        this.taskInfo.popReward.icon = "COIN";
        this.taskInfo.popReward.isAnim = true;
        this.taskInfo.popReward.show = true;
        if (res.userInfo) {
          this.setUserInfo(res.userInfo);
        }
      }, 5000);
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.userInfo.joined_facescan == 0 && this.userInfo.parent_info) {
        this.popFaceTipShow = true;
      }
    }, 200);
  }
};