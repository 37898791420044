var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        _vm.isShadowClick && _vm.onClose();
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    ref: "popPromptRef",
    staticClass: "popPrompt-main center",
    class: _vm.className
  }, [_vm.isClose ? _c('div', {
    staticClass: "btn-close",
    domProps: {
      "innerHTML": _vm._s(_vm.svg_close)
    },
    on: {
      "click": _vm.onClose
    }
  }) : _vm._e(), _vm.title ? _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('div', {
    staticClass: "popPrompt-con"
  }, [_vm.content === 'html' ? _vm._t("default") : [_c('div', {
    staticClass: "popPrompt-tip"
  }, [_vm._v(_vm._s(_vm.content))])], _vm.isBtnCancel || _vm.isBtnConfirm ? _c('div', {
    staticClass: "popPrompt-btns"
  }, [_vm.isBtnCancel ? _c('div', {
    staticClass: "btn btn-cancel",
    class: _vm.isSmallCancelBtn && 'small',
    on: {
      "click": _vm.onCancel
    }
  }, [!_vm.isCancelLoading ? _c('em', {
    domProps: {
      "innerHTML": _vm._s(_vm.cancelText)
    }
  }) : _c('em', {
    staticClass: "circleLoading"
  })]) : _vm._e(), _vm.isBtnConfirm ? _c('div', {
    staticClass: "btn btn-confirm",
    class: _vm.isSmallConfirmBtn && 'small',
    on: {
      "click": _vm.onConfirm
    }
  }, [!_vm.isConfirmLoading ? _c('em', {
    domProps: {
      "innerHTML": _vm._s(_vm.confirmText)
    }
  }) : _c('em', {
    staticClass: "circleLoading"
  })]) : _vm._e()]) : _vm._e(), _vm.$slots.error || _vm.$slots.limit ? _c('div', {
    staticClass: "btn-limit-info"
  }, [_vm.$slots.limit ? _c('div', {
    staticClass: "limit"
  }, [_vm._t("limit")], 2) : _vm._e(), _vm.$slots.error ? _c('div', {
    staticClass: "error"
  }, [_vm._t("error")], 2) : _vm._e()]) : _vm._e()], 2)])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };