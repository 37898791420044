import * as tmaSdk from "@tma.js/sdk";
import loadConfig from "@tonWalletConfig/config";
import vailcode from "@utils/errcode";
import { Address, toNano } from "@ton/core";
import WebApp from "@twa-dev/sdk";
const initData = WebApp.initData;
import router from "@/router"; // 引入 router 实例
let spinsRecoveryTimer = null;
let calcBalanceTimer = 0;
export default {
  namespaced: true,
  state: {
    isLogin: false,
    token: null,
    userInfo: {},
    myInfo: {},
    taskCount: null,
    settingInfo: null,
    balanceInfo: {
      usdt: {
        balance: 0,
      },
      ton: {
        balance: 0,
      },
      stars: {
        balance: 0,
      },
      spins: {
        balance: 0,
      },
    },
    spinsPriceList: null,
  },
  mutations: {
    setIsLogin: (state, payload) => (state.isLogin = payload),
    setToken: (state, payload) => (state.token = payload),
    setTaskCount: (state, payload) => (state.taskCount = payload),
    setUserInfo: (state, payload) => {
      for (const key in payload) {
        _v.$set(state.userInfo, key, payload[key]);
      }
    },
    setMyInfo: (state, payload) => {
      for (const key in payload) {
        _v.$set(state.myInfo, key, payload[key]);
      }
    },
    setSettingInfo: (state, payload) => (state.settingInfo = payload),
    setSpinsPriceList: (state, payload) => {
      state.spinsPriceList = payload;
    },
  },
  actions: {
    //初始化
    initProcess: async ({ state, dispatch, commit }) => {
      await dispatch("getSystemInit");
      await dispatch("setInitStatistics");
      await dispatch("getLoginInfo");
      await dispatch("getBalanceInfo");
      await dispatch("getMyInfo");
      await dispatch("game/getGameprogress", null, { root: true });
      // dispatch("setInApp");

      await dispatch("onlineRefresh");
    },
    //获取登录信息
    getLoginInfo: ({ state, commit, dispatch, rootState }) => {
      return new Promise(async (resolve, reject) => {
        let inviteCode = "";
        let groupId = "";
        let start_param = _v.WebApp.initDataUnsafe.start_param;
        if (start_param && start_param.indexOf("ref_") !== -1) {
          let tstart_param = start_param.split("-");
          console.log("tstart_param", tstart_param);
          tstart_param.map((item) => {
            if (item.indexOf("ref_") !== -1) {
              inviteCode = item.replace("ref_", "");
            }
          });
        }
        const res = await _v.$http.post("auth/login", { initData, inviteCode, groupId });
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject();
              commit("setIsLogin", false);
              return;
            }
            commit("setToken", data.token);
            // data.userInfo.coin = 0
            commit("setUserInfo", data.userInfo);
            commit("setTaskCount", data.task_count || 0);
            resolve(data);
          },
          (err) => {
            console.log(`%c getLoginInfo err: ${err}`, "color: red; font-size: 14px;");
            commit("setIsLogin", false);
            reject(null);
          }
        );
      });
    },
    //获取mini-app初始化信息
    getSystemInit: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/system/init", {});
        console.log("324234234", res);
        vailcode(
          res,
          () => {
            commit("setSettingInfo", res.data);
            resolve(1);
          },
          () => {
            reject(null);
            commit("setIsLogin", false);
          }
        );
      });
    },
    //设置mini-app
    setInitStatistics: ({ state, dispatch, commit }) => {
      return new Promise(async (resolve, reject) => {
        if (!state.settingInfo) {
          commit("setIsLogin", false);
        }
        console.log("state.settingInfo", state.settingInfo);
        const setMiniAppHead = () => {
          document.title = state.settingInfo.coin;
          state.settingInfo.statistics.map((item) => {
            if (item.type == "plausible" && item.value) {
              const pScript = document.createElement("script");
              pScript.src = `https://plausible.io/js/script.js`;
              pScript.defer = true;
              pScript.setAttribute("data-domain", item.value);
              document.head.appendChild(pScript);
              resolve(1);
            }
            if (item.type == "google" && item.value) {
              const gaScript = document.createElement("script");
              gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${item.value}`;
              gaScript.async = true;
              gaScript.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                  resolve(1);
                }
                gtag("js", new Date());
                gtag("config", item.value);
              };

              document.head.appendChild(gaScript);
            }
          });
        };
        const setMiniAppLink = () => {
          if ((_v.WebApp.initDataUnsafe.start_param || "").indexOf("page_") !== -1) {
            let tstart_param = (_v.WebApp.initDataUnsafe.start_param || "").split("-");
            console.log(tstart_param);
            let page = "";
            let id = "";
            let inviteCode = "";
            tstart_param.map((item) => {
              if (item.indexOf("page_") !== -1) {
                page = item.replace("page_", "");
              }
              if (item.indexOf("id_") !== -1) {
                id = item.replace("id_", "");
              }
              if (item.indexOf("ref_") !== -1) {
                inviteCode = item.replace("ref_", "");
              }
            });
            if (page) {
              let url = `/${page}${id ? `?id=${id}` : ""}`;
              if (inviteCode) {
                if (url.indexOf("?") !== -1) {
                  url += "&invitecode=" + inviteCode;
                } else {
                  url += "?invitecode=" + inviteCode;
                }
              }
              router.push(url);
            }
          }
        };
        _v.WebApp.isClosingConfirmationEnabled = true;
        _v.WebApp.disableVerticalSwipes();
        _v.WebApp.BackButton.onClick(() => {
          router.go(-1);
        });
        setMiniAppHead();
        setMiniAppLink();
        resolve();
      });
    },
    //在线刷新
    onlineRefresh: (_) => {
      const time = 180 * 1000;
      const onlineRefreshFnc = async () => {
        try {
          await _v.$http.post("/user/online/refresh", {});
        } catch (err) {
          console.log("onlineRefresh err", err);
        }
        setTimeout(() => {
          onlineRefreshFnc();
        }, time);
      };
      setTimeout(() => {
        onlineRefreshFnc();
      }, time);
    },
    // getInAppInfo: async ({ state, dispatch }) => {
    //   return new Promise(async (resolve, reject) => {
    //     await this.$http.post("/user/inapp", {})
    //     vailcode(rs, async () => {
    //       this.setUserInfo(rs.data);
    //       this.$emit("confirm")
    //     });
    //   })
    // },
    // setInApp: async ({ state, dispatch }) => {
    //   if (!state.userInfo.hasOwnProperty("in_app")) {
    //     await dispatch("getLoginInfo");
    //   }
    //   const in_app = state.userInfo.in_app;
    //   !in_app && router.push("/ris");
    // },
    //获取余额
    getBalanceInfo: ({ state, commit, dispatch }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/user/assets/list", {});
        vailcode(
          res,
          async () => {
            const data = res.data;
            if (!data) {
              commit("setIsLogin", false);
              reject(null);
              return;
            }

            console.log("datadatadata", data);

            data.map((item) => {
              if (item.name == "Stars") {
                state.balanceInfo.stars = item;
              } else if (item.name == "usdt") {
                state.balanceInfo.usdt = item;
              } else if (item.name == "ton") {
                state.balanceInfo.ton = item;
              }
            });
            resolve(1);
            //获取spins余额
            await dispatch("getSpinsBalance");
          },
          () => {
            reject(null);
            commit("setIsLogin", false);
          }
        );
      });
    },
    //获取邀请基本信息
    getInviteInfo: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/invitation/info", {});
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //获取邀请收益信息
    getInviteEarning: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/invitation/earnings", {});
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    getMyInfo: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/my/info", {});
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }
            console.log("data======", data);
            commit("setMyInfo", data);
            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //获取当前用户下属好友排行
    getFriendRank: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/ranking/friend", { limit: 200 });
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //获取全网邀请排行
    getTopleadersRank: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/ranking/topleaders", { limit: 30 });
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //获取Spins余额
    getSpinsBalance: ({ state, commit, dispatch }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/lucky/spins/balance", {});
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }
            state.balanceInfo.spins = res.data;

            dispatch("calcSpinsRecoveryTime");
            console.log("state.balanceInfo.spins", state.balanceInfo.spins);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //计算spins恢复时间
    calcSpinsRecoveryTime: ({ state, dispatch }) => {
      const spins = state.balanceInfo.spins;
      const recovery = spins.recovery;
      clearTimeout(spinsRecoveryTimer);
      const calcFnc = async () => {
        recovery.countdown--;
        if (recovery.countdown <= 0 && spins.balance < spins.max && calcBalanceTimer <= 5) {
          calcBalanceTimer++;
          clearTimeout(spinsRecoveryTimer);
          await dispatch("getSpinsBalance");
          return;
        }
        if (recovery.countdown > 0) {
          calcBalanceTimer = 0;
          spinsRecoveryTimer = setTimeout(calcFnc, 2000);
        }
        if (spins.balance >= spins.max) {
          calcBalanceTimer = 0;
        }
      };
      calcFnc();
    },
    //获取spins购买价格列表
    getLuckyLotteryList: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/lucky/lottery/list", params);
        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //获取spins购买价格列表
    getSpinsPriceList: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/lucky/spins/prices", {});
        vailcode(
          res,
          () => {
            const data = res.data.map((item) => {
              return {
                ...item,
                isLoading: false,
              };
            });
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    //获取spins购买价格列表
    getPaymentSpins: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/lucky/spins/deposit ", params);

        vailcode(
          res,
          () => {
            const data = res.data;
            if (!data) {
              reject(null);
              return;
            }

            resolve(data);
          },
          () => {
            reject(null);
          }
        );
      });
    },
    checkPaymentSpins: ({ state, dispatch, rootState }, params) => {
      return new Promise(async (resolve, reject) => {
        let paymentInfo = rootState.task.taskInfo.paymentInfo;
        paymentInfo[`p_${params.index}`].paymentId = params.paymentId;
        paymentInfo[`p_${params.index}`].isLoading = false;
        paymentInfo[`p_${params.index}`].timer = null;
        console.log("paymentInfopaymentInfopaymentInfo", paymentInfo);
        const fnc = async (params, paymentInfo) => {
          const res = await _v.$http.post("/lucky/spins/deposit ", params);
          console.log("res.coderes.coderes.coderes.code", res.code, params, paymentInfo);
          if (res.code === 0) {
            clearTimeout(paymentInfo[`p_${params.index}`].timer);
            paymentInfo[`p_${params.index}`].timer = null;
            paymentInfo[`p_${params.index}`].isLoading = false;
            paymentInfo[`p_${params.index}`].paymentId = null;
            resolve(1);
          } else if (res.code === 302) {
            paymentInfo[`p_${params.index}`].isLoading = true;
            paymentInfo[`p_${params.index}`].timer = setTimeout(async () => {
              await fnc(params, paymentInfo);
            }, 2000);
            // 还在等待用户支付完成
          } else {
            clearTimeout(paymentInfo[`p_${params.index}`].timer);
            paymentInfo[`p_${params.index}`].timer = null;
            paymentInfo[`p_${params.index}`].isLoading = false;
            resolve(0);
            // 支付失败，提示错误信息
          }
        };
        fnc(params, paymentInfo);
      });
    },
  },
};
