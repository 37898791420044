import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mUpgradeItem",
  inject: ["setPopEnoughShow"],
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...gameVuex.mapState(["propertyList"])
  },
  props: {
    item: null,
    index: null
  },
  methods: {
    onUpgrade(_, item) {
      const canClick = this.canClick();
      if (canClick === "disable") {
        return;
      }
      if (canClick == "disable2") {
        this.setPopEnoughShow();
        return;
      }
      if (canClick === "disable3") {
        return;
      }
      this.$emit("upgrade", item);
    },
    canClick() {
      if (this.item.disabled || this.item.step === 10) return "disable";
      if (this.userInfo.coin < this.item.upgrade_cost) return "disable2";
      const bool = this.propertyList.every(item => {
        return item.properties.every(itm => {
          return !itm.loading;
        });
      });
      if (!bool) {
        return "disable3";
      }
      return 0;
      // return true;
    },

    setIntervalTime() {
      this.item.disabled = false;
      setTimeout(() => {
        this.item.disabled = this.item.step !== 10;
      }, 5000);
    }
  }
};