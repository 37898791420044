import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nCurrencyTwo"
  }, [_c('div', {
    staticClass: "nCurrencyTwo-btn",
    style: {
      background: _vm.itemBg
    }
  }, [_c('img', {
    ref: "TAPSTOCKRef",
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/key.png`),
      "alt": ""
    }
  }), _vm.userData && _vm.userData.hasOwnProperty('stock') ? _c('div', {
    staticClass: "nCurrencyTwo-btn__value"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.userData.stock, 2, true, true)))]) : _vm._e(), _c('div', {
    staticClass: "nCurrencyTwo-btn-icon",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/earn');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1)]), _c('div', {
    staticClass: "nCurrencyTwo-btn",
    style: {
      background: _vm.itemBg
    }
  }, [_c('img', {
    ref: "TAPCOINSRef",
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tapcoins.png`),
      "alt": ""
    }
  }), _vm.userData && _vm.userData.hasOwnProperty('coin') ? _c('div', {
    staticClass: "nCurrencyTwo-btn__value"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.userData.coin, 2, true, true)))]) : _vm._e(), _c('div', {
    staticClass: "nCurrencyTwo-btn-icon",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/mine');
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };