class RequestCache {
  constructor() {
    this.cache = new Map();
  }

  // 根据请求 URL 和参数生成唯一的缓存key
  generateCacheKey(config) {
    let data = null;
    if (typeof config.data === "string") {
      data = JSON.parse(config.data);
    } else {
      data = config.data;
    }

    let _data = JSON.stringify(data);
    return `${config.url}_${_data}`;
  }

  // 从缓存中获取数据
  getCache(cacheKey, duration) {
    const cached = this.cache.get(cacheKey);
    if (cached) {
      const isExpired = Date.now() - cached.timestamp > duration; // 检查缓存是否过期
      if (isExpired) {
        // 缓存已过期，删除缓存
        this.cache.delete(cacheKey);
        return null;
      }
      return cached.response; // 返回有效缓存
    }
    return null; // 如果缓存不存在或已过期
  }

  // 设置缓存
  setCache(cacheKey, response, duration) {
    this.cache.set(cacheKey, {
      response,
      timestamp: Date.now(), // 设置时间戳
      duration, // 缓存过期时间
    });

    // 定时清除缓存
    setTimeout(() => {
      this.cache.delete(cacheKey);
    }, duration);
  }

  // 清除特定缓存
  clearCache(cacheKey) {
    this.cache.delete(cacheKey);
  }

  // **新增：根据 URL 清除所有相关缓存**
  clearCacheByUrl(url) {
    for (const key of this.cache.keys()) {
      if (key.startsWith(url)) {
        this.cache.delete(key);
        console.log(`Cache cleared for: ${key}`);
      }
    }
  }
}

export default new RequestCache();
