import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "userCenter",
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...gameVuex.mapState(["gameProgress"])
  },
  mounted() {
    this.setWowAnim();
  }
};