export default {
  name: "mTab",
  props: {
    tab: {
      default: () => []
    },
    selectTab: {
      default: null
    }
  },
  watch: {
    tab: {
      handler(newList, oldList) {
        if (newList.length > 0 && newList != oldList) {
          console.log(333333, newList);
          this.$emit("update:selectTab", newList[0]);
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {};
  },
  methods: {
    onTab(item) {
      this.$emit("update:selectTab", item);
      this.$emit("tabFn", item);
    }
  }
};