var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "btn-morespins",
    on: {
      "click": _vm.showPopMoreSpins
    }
  }), _c('pop-prompt', {
    attrs: {
      "isShadowClick": true,
      "className": "popMoreSpinsClass",
      "show": _vm.popMoreSpinsShow,
      "isBtnConfirm": false,
      "isBtnCancel": false,
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popMoreSpinsShow = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pop-morespins"
  }, [_c('div', {
    staticClass: "pop-morespins__title"
  }, [_vm._v("Number of spins")]), _c('div', {
    staticClass: "pop-morespins-list"
  }, [_c('transition-group', {
    staticClass: "pop-morespins-scroll",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.spinsPriceList && !_vm.isLoading ? _vm._l(_vm.spinsPriceList, function (item, index) {
    return _c('div', {
      key: `mp${index}`,
      staticClass: "pop-morespins-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      },
      on: {
        "click": function ($event) {
          return _vm.onPaySpins(item, index);
        }
      }
    }, [_c('div', {
      staticClass: "pop-morespins-item__num"
    }, [_vm._v(_vm._s(_vm.fmt_pre(item.count)) + " Spins")]), _c('div', {
      staticClass: "pop-morespins-item__sub"
    }, [_vm._v(_vm._s(item.hasOwnProperty('added') && item.added ? `Added ${item.added}` : ''))]), _c('div', {
      staticClass: "pop-morespins-item-info"
    }, [_vm.taskInfo.paymentInfo[`p_${index}`].isLoading || item.isLoading ? _c('div', {
      staticClass: "loading"
    }, [_c('div', {
      staticClass: "circleLoading"
    })]) : [_c('div', {
      staticClass: "pop-morespins-item-info__price"
    }, [_c('img', {
      attrs: {
        "src": require('@images/icon/icon-star.png'),
        "alt": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.fmt_pre(item.stars)))])])]], 2)]);
  }) : _vm._e()], 2)], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };