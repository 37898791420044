var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mTierBenefits"
  }, [_c('div', {
    staticClass: "mTierBenefits-main"
  }, [_c('div', {
    staticClass: "mTierBenefits__title"
  }, [_vm._v(_vm._s(`Lv.${_vm.levelInfo} Perks:`))]), _vm.levelIntro[_vm.levelInfo] ? _vm._l(_vm.levelIntro[_vm.levelInfo], function (item, index) {
    return _c('div', {
      staticClass: "mTierBenefits__label"
    }, [index == 0 ? _c('span', [_c('svg-icon', {
      attrs: {
        "name": "icon-tier"
      }
    }), _vm._v(" " + _vm._s(item) + " Airdrop Pass")], 1) : index == 1 ? _c('span', [_c('svg-icon', {
      attrs: {
        "name": "icon-tier"
      }
    }), _vm._v(" Max Spins:" + _vm._s(item) + " ")], 1) : index == 2 ? _c('span', [_c('svg-icon', {
      attrs: {
        "name": "icon-tier"
      }
    }), _vm._v(" I get " + _vm._s(item) + " of the stars from friends.")], 1) : _vm._e()]);
  }) : _c('div', {
    staticClass: "mTierBenefits__label"
  }, [_c('span', [_c('svg-icon', {
    attrs: {
      "name": "icon-tier"
    }
  }), _vm._v(" Stay tuned...")], 1)])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };