var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "daily-page page"
  }, [_c('m-head'), _c('div', {
    staticClass: "daily-main page-main"
  }, [_c('div', {
    staticClass: "daily__title"
  }, [_vm._v("Daily Reward")]), _vm._m(0), _c('transition-group', {
    staticClass: "daily-list",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterShowDaily
    }
  }, [_vm.dailyList.length > 0 ? _vm._l(_vm.dailyList, function (item, index) {
    return _c('div', {
      key: `daily${index}`,
      staticClass: "daily-item",
      class: item.completed && 'completed',
      attrs: {
        "data-index": index <= 50 ? index + 0.2 : 0
      },
      on: {
        "click": _vm.onShowPopDailyAds
      }
    }, [_c('div', {
      staticClass: "daily-item-main"
    }, [_c('div', {
      staticClass: "daily-item__status"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-ok1"
      }
    })], 1), _c('div', {
      staticClass: "daily-item__tag"
    }, [_c('span', [_vm._v(_vm._s(index + 1))])]), _c('div', {
      staticClass: "daily-item__icon",
      class: _vm.dailyList[index - 1] && _vm.dailyList[index - 1].today && _vm.dailyList[index - 1].completed && 'time'
    }, [_c('img', {
      attrs: {
        "src": require(`@images/icon/icon-hic.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "daily-item__label"
    }, [_vm._v(_vm._s(_vm._f("fmt_pre")(item.coins)))]), _vm.dailyList[index - 1] && _vm.dailyList[index - 1].today && _vm.dailyList[index - 1].completed ? _c('div', {
      staticClass: "daily-item__time"
    }, [_vm._v(" " + _vm._s(_vm._f("formatHour")(_vm.distance)) + " ")]) : _vm._e()])]);
  }) : _vm._e()], 2), _vm.dailyList.length <= 0 ? _c('div', {
    staticClass: "daily-list"
  }, [_c('div', {
    staticClass: "circleLoading"
  })]) : _vm._e()], 1), _c('m-menu')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "daily__sub"
  }, [_vm._v(" Accuure coins for logging into"), _c('br'), _vm._v(" the gome daily without skipping ")]);
}];
export { render, staticRenderFns };