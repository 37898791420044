import store from "@/store";
import Vue from "vue";
// console.log(isMobile)
function setRem() {
  let _isMobile = false;
  let htmlWidth = null;
  htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  Vue.prototype.remUnit = 200;
  //得到html的Dom元素
  let htmlDom = document.getElementsByTagName("html")[0];
  let sUserAgent = navigator.userAgent.toLowerCase();
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
    //wap
    _isMobile = true;
    const val = htmlWidth / 7.5;
    htmlDom.style.fontSize = val + "px";
    Vue.prototype.remTran = (value) => Number(value) / 200;
  } else {
    const val = 51.2;
    _isMobile = false;

    htmlDom.style.fontSize = val + "px";
    Vue.prototype.remTran = (value) => Number(value) / 200;
    // if (htmlWidth <= 600) {
    //   _isMobile = true;
    //   const val = htmlWidth / 7.5;
    //   htmlDom.style.fontSize = val + "px";
    //   Vue.prototype.remTran = (value) => Number(value) / val;
    // }
  }
  // console.log("_isMobile", _isMobile, htmlWidth);
  document.body.setAttribute("data-mobile", _isMobile);
  store.commit("wallet/setIsMobile", _isMobile);
  store.commit("wallet/setHtmlSize", { w: htmlWidth });
}

function throttle(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

// 初始化
setRem(true);
// 改变窗口大小时重新设置 rem

window.addEventListener("resize", throttle(setRem, 0));
