import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mLoading",
  computed: {
    ...gameVuex.mapState(["gameProgress"])
  },
  data() {
    return {
      backgroundImage: "",
      imgUrlArr: [require("@images/index-bg.jpg"), require("@images/lucky-bg.jpg"), require("@images/machine/machine-page-bg.jpg"), require("@images/machine/machine-bg.png"), require("@images/machine/machine-main-bg.png"), require("@images/rewardAnim/anim-1.png"), require("@images/rewardAnim/anim-2.png"), require("@images/rewardAnim/anim-bg-COIN.png"), require("@images/machine/machine-btn1-bg.png"), require("@images/lucky-icon-mathine.jpg"), require("@images/lucky-icon-airdrop.jpg"), require("@images/COIN.png"), require("@images/LGCOIN.png"), require("@images/LGSPINS.png"), require("@images/SPINS.png"), require("@images/airdrop-box-img.png"), require("@images/firend-intro-bg.jpg"), require("@images/card-bg.png"), require("@images/reward-bg.jpg")]
    };
  },
  watch: {
    "gameProgress.currentLevel": {
      handler(val) {
        if (val > 0) {
          try {
            if (val >= 10) {
              this.imgUrlArr.push(require(`@images/avatar/body/9.png`), require(`@images/avatar/head/9.png`));
              return;
            }
            this.imgUrlArr.push(require(`@images/avatar/body/${val}.png`), require(`@images/avatar/head/${val}.png`));
            if (val < botImgLimit) {
              this.imgUrlArr.push(require(`@images/avatar/body/${val + 1}.png`), require(`@images/avatar/head/${val + 1}.png`));
            }
          } catch (err) {}
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    unorderedPreload() {
      this.$nextTick(() => {
        try {
          let backgroundImages = [];
          // 无序加载，并发下载图片
          console.log("this.imgUrlArr", this.imgUrlArr);
          this.imgUrlArr.forEach((imgUrl, i) => {
            var oImg = new Image();
            oImg.addEventListener("load", this.imgLoaded);
            oImg.addEventListener("error", this.imgLoaded);
            oImg.src = imgUrl;
            backgroundImages.push(`url(${imgUrl})`);
          });
          this.backgroundImage = backgroundImages.join(",");
        } catch (err) {}
      });
    }
  },
  mounted() {
    this.unorderedPreload();
  }
};