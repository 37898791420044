import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "mCoinAnim",
  computed: {
    ...taskVuex.mapState(["taskInfo"]),
    taskInfoPopCoinAnimShow() {
      return this.taskInfo.popCoinAnim.show;
    }
  },
  data() {
    return {
      count: 0
    };
  },
  watch: {
    taskInfoPopCoinAnimShow: {
      handler(newBool, oldBool) {
        this.popFunc(newBool, this);
        if (newBool) {
          this.count = 0;
          setTimeout(() => {
            this.play();
          }, 500);
        }
      }
    }
  },
  methods: {
    ...userVuex.mapActions(["getSpinsBalance"]),
    play() {
      return new Promise(resove => {
        this.$nextTick(() => {
          const spinsEndId = document.getElementById("spinsEndId");
          console.log("spinsEndIdspinsEndId", spinsEndId);
          if (this.count > 5) {
            this.taskInfo.popCoinAnim.reward = 0;
            this.taskInfo.popCoinAnim.show = false;
          }
          if (!spinsEndId && this.count <= 5) {
            this.count++;
            setTimeout(() => {
              this.play();
            }, 200);
            return;
          }
          const coinEndRef = spinsEndId;
          const containerRef = this.$refs.destoryCoinContainerRef;
          this.coinAnimFn({
            ref: coinEndRef,
            containerRef,
            img: `${this.taskInfo.popCoinAnim.icon}.png`,
            callback: async () => {
              console.log(66666);
              this.count = 0;
              this.taskInfo.popCoinAnim.reward = 0;
              this.taskInfo.popCoinAnim.show = false;
              if (this.taskInfo.popCoinAnim.icon === "SPINS") {
                await this.getSpinsBalance();
              }
              resove();
            }
          });
        });
      });
    },
    coinAnimFn(params) {
      const _params = {
        containerRef: null,
        //出现金币区域dom 必传
        radius: 100,
        //出现金币区域范围
        numCoins: this.taskInfo.popCoinAnim.reward <= 20 ? this.taskInfo.popCoinAnim.reward : 30,
        //出现金币个数
        img: "lightning.svg",
        //图片名 带后缀
        ref: null,
        //金币位移的终点dom 必传 String
        callback: null //金币位移到终点后回调 必穿
      };

      if (!params.hasOwnProperty("ref")) {
        console.error("error: coinAnim ref?");
        return;
      } else {
        _params.ref = params.ref;
      }
      if (!params.hasOwnProperty("containerRef")) {
        console.error("error: coinAnim containerRef?");
        return;
      } else {
        _params.containerRef = params.containerRef;
      }
      if (!params.hasOwnProperty("callback") && typeof params.callback != "function") {
        console.error("error: coinAnim callback?");
        return;
      } else {
        _params.callback = params.callback;
      }
      if (params?.radius) {
        _params.radius = params.radius;
      }
      if (params?.numCoins) {
        _params.numCoins = params.numCoins;
      }
      if (params?.img) {
        _params.img = params.img;
      }
      console.log("_params", _params);
      let count = 0;
      let src = require(`@images/${_params.img}`);
      console.log("src", src);
      this.$nextTick(() => {
        const coinContainer = _params.containerRef;
        coinContainer.innerHTML = "";
        let coins = [];
        for (let i = 0; i < _params.numCoins; i++) {
          let coin = document.createElement("img");
          coin.src = src;
          coin.classList.add("coinSm");
          let angle = Math.random() * 2 * Math.PI;
          let r = Math.sqrt(Math.random()) * _params.radius;
          let x = _params.radius + r * Math.cos(angle) - 25;
          let y = _params.radius + r * Math.sin(angle) - 25;
          coin.style.left = "50%";
          coin.style.top = "50%";
          coinContainer.appendChild(coin);
          coins.push({
            element: coin,
            finalX: x,
            finalY: y
          });
        }
        console.log("coins", coins);
        void coinContainer.offsetWidth;
        coins.forEach((coin, index) => {
          coin.element.style.left = `${coin.finalX}px`;
          coin.element.style.top = `${coin.finalY}px`;
        });
        setTimeout(() => {
          let endButton = _params.ref;
          let endButtonRect = endButton.getBoundingClientRect();
          let containerRect = coinContainer.getBoundingClientRect();
          let endX = endButtonRect.left - containerRect.left;
          let endY = endButtonRect.top - containerRect.top;
          coins.forEach((coin, index) => {
            setTimeout(() => {
              coin.element.style.left = `${endX}px`;
              coin.element.style.top = `${endY}px`;
              if (index === coins.length - 1) {
                coin.element.addEventListener("transitionend", () => {
                  count++;
                  coinContainer.innerHTML = "";
                  if (count == 1) _params.callback();
                });
              }
            }, index * 20);
          });
        }, 500);
      });
    }
  }
};