import Vue from "vue";
import { TonConnectUI } from "@tonconnect/ui";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
const route = window.location.pathname;
// import * as buffer from "buffer";

// if (typeof window.Buffer === "undefined") {
//   window.Buffer = buffer.Buffer;
// }

import tonConfig from "@tonWalletConfig/config.json";
import store from "@/store";

const tonWallet = {
  connected: false, //是否已经连接上钱包 Boolean
  ton: null, //Object TonConnect api
  uiAddress: null, //UI显示地址
  address: null, //0:xxxx格式地址
  modal: null,
  wallet: null,
  walletInfo: null,
  isModal: false, //TonConnect 引导UI状态 显示隐藏状态 Boolean true：显示 false：隐藏
  account: null,
  chain: "",
  walletStateInit: null,
  publicKey: null,
  unsubscribe: () => {}, //订阅连接状态变化
  initTon: async function (id, bool) {
    return new Promise(async (resolve, reject) => {
      try {
        _v.tonConnectUI = await tonWallet.createTonConnectUI(id);
        await tonWallet.openOrCloseTonModal(bool);
        tonWallet.getWalletInfo();
        resolve(tonWallet);
      } catch (err) {
        console.log("initTon: ", err);
      }
    });
  },
  createTonConnectUI: function (id) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!tonWallet.ton) {
          const tonConnectUI = new TonConnectUI({
            manifestUrl: tonConfig.ton_manifest_url,
            buttonRootId: id,
          });
          tonConnectUI.uiOptions = {
            actionsConfiguration: {
              returnStrategy: tonConfig.ton_uiOptions_strategy,
            },
          };
          _v.$set(tonWallet, "ton", tonConnectUI);
          resolve(tonConnectUI);
        } else {
          resolve();
        }
      } catch (err) {
        console.log("createTonConnectUI", err);
        reject("");
      }
    });
  },
  //转换ton address格式
  tranAddress: function (address) {
    return toUserFriendlyAddress(address);
  },

  //获取钱包列表
  getWalletList: function (ton) {
    return new Promise(async (resolve, reject) => {
      try {
        const ton = tonWallet.ton;
        const walletsList = await ton.getWallets();
        resolve(wallet);
      } catch (err) {
        console.log("getWalletList: ", err);
        reject(err);
      }
    });
  },
  //开启或关闭ton模态框
  openOrCloseTonModal: function (bool = false) {
    return new Promise((resolve, reject) => {
      try {
        //获取当前模态框状态
        const ton = tonWallet.ton;
        let timer = setTimeout(async () => {
          clearTimeout(timer);
          timer = null;
          Vue.prototype.$set(tonWallet, "connected", ton?.connected);
          tonWallet.connected = ton?.connected;
          const modal = !tonWallet.connected && bool ? await ton.openModal() : await ton.closeModal();
          resolve(modal);
        }, 200);
      } catch (err) {
        console.log("openOrCloseTonModal: ", err);
        reject(err);
      }
    });
  },
  //获取连接信息
  getWalletInfo: function () {
    const ton = tonWallet.ton;
    const arr = ["wallet", "walletInfo", "account", "connected"];
    if (ton.account) {
      Vue.prototype.$set(tonWallet, "uiAddress", tonWallet.tranAddress(ton.account.address));
      Vue.prototype.$set(tonWallet, "address", ton.account.address);
      Vue.prototype.$set(tonWallet, "walletStateInit", ton.account.walletStateInit);
      Vue.prototype.$set(tonWallet, "publicKey", ton.account.publicKey);

      tonWallet.tranAddress(ton.account.address);
      Vue.prototype.$set(tonWallet, "chain", ton.account.chain);
    }
    arr.map((it) => Vue.prototype.$set(tonWallet, it, ton[it]));
  },
  disconnect: async function (callback) {
    if (tonWallet.ton) {
      await tonWallet.ton.disconnect();
      console.log("tonWallettonWallet", tonWallet);
      tonWallet.uiAddress = null;
      tonWallet.address = null;
      typeof callback === "function" && callback(tonWallet);
      console.log("tonWallet disconnect:", tonWallet);
    }
  },
  //发送交易
  sendTransaction: function (transaction) {
    return new Promise(async (resolve, reject) => {
      try {
        const ton = tonWallet.ton;
        const validUntil = Math.floor(Date.now() / 1000) + 60; // 60 sec
        const transaction = {
          validUntil,
          messages,
          //transaction body
        };
        const result = await ton.sendTransaction(transaction);
        resolve(result.boc);
      } catch (err) {
        reject();
      }
    });
  },
  //连接钱包
  connectTon() {
    return new Promise(async (resolve, reject) => {
      await tonWallet.openOrCloseTonModal(true);
      resolve();
      // console.log(6666666, !store.user.userInfo.wallet);
    });
  },
};

export default tonWallet;
