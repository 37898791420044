export default {
  name: "mLuckyItem",
  props: {
    isSoon: {
      default: false
    },
    url: {
      default: ""
    },
    name: {
      default: ""
    }
  }
};