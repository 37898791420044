import vailcode from "@utils/errcode";
export default {
  namespaced: true,
  state: {
    propertyList: null,
    gameProgress: null,
    levelIntro: {
      0: ["1,000", "10", "20%"],
      1: ["2,000", "15", "22%"],
      2: ["3,000", "20", "24%"],
      3: ["4,000", "25", "26%"],
      4: ["5,000", "30", "28%"],
      5: ["6,000", "35", "30%"],
      6: ["7,000", "40", "32%"],
      7: ["8,000", "45", "34%"],
      8: ["9,000", "50", "36%"],
      9: ["10,000", "55", "38%"],
      10: ["11,000", "60", "40%"],
    },
  },
  mutations: {
    setPropertyList: (state, payload) => {
      state.propertyList = payload;
    },
    setGameProgress: (state, payload) => {
      state.gameProgress = payload;
    },
  },
  actions: {
    //获取属性列表
    getGamePropertyList: ({ state, dispatch, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/game/property/list", {});
        vailcode(
          res,
          async () => {
            let data = res.data;
            if (!data) {
              resolve();
              return;
            }
            data.map((item) => {
              item.properties.map((itm) => {
                _v.$set(itm, "disabled", false); //禁用状态
                _v.$set(itm, "newStep", itm.step); //禁用状态
                _v.$set(itm, "loading", false); //禁用状态
                _v.$set(itm, "completion_rate_old", itm.completion_rate); //用于比对进度是否更新 更新则播放数字滚动动画
              });
            });
            console.log("datadatadatadata", data);
            data.map((item) => {
              item.properties.sort((a, b) => {
                if (a.newStep >= 10 && b.newStep >= 10) return 0; // 保持相对顺序
                if (a.newStep >= 10) return 1; // step >= 10 的排后面
                if (b.newStep >= 10) return -1;
                // return b.step - a.step; // step < 10 的按降序排列
              });
            });
            commit("setPropertyList", data);
            resolve(data);
          },
          (err) => {}
        );
      });
    },
    //获取升到下级进度
    getGameprogress: ({ state, dispatch, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/game/progress", {});
        vailcode(
          res,
          async () => {
            let data = res.data;
            if (!data) {
              resolve();
              return;
            }
            const currentLevel = data.currentLevel;
            const nextLevel = data.nextLevel === -1 ? -1 : data.nextLevel;
            data = { ...data, currentLevel, nextLevel };
            commit("setGameProgress", data);
            resolve(data);
          },
          (err) => {}
        );
      });
    },
    //升到下级进度
    upgradeAvatar: ({ state, dispatch, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("/game/upgrade", { propertyId: params.propertyId });
        //   const res = {
        //     "code": 0,
        //     "message": "",
        //     "data": {
        //         "levelInfo": {
        //             "currentLevel": 3,
        //             "nextLevel": 4
        //         },
        //         "progressInfo": {
        //             "total": 50,
        //             "completed_count": 49,
        //             "completion_rate": 0.98
        //         },
        //         "propertyInfo": {
        //             "id": 510,
        //             "category_id": 5,
        //             "short_name": "Asset Growth",
        //             "name": "Asset Growth",
        //             "icon": "https://static.humanpass.net/game/property/5/AssetGrowth.png",
        //             "description": null,
        //             "step": 7,
        //             "completion_rate": 0.7,
        //             "upgrade_cost": 3600
        //         },
        //         "userInfo": {
        //             "id": 1625250,
        //             "tg_id": "6605438145",
        //             "username": "wjm12345",
        //             "firstname": "wjm123",
        //             "lastname": "",
        //             "head_color": "#68243d",
        //             "avatar_url": null,
        //             "invite_reward": 0,
        //             "ref_count": 0,
        //             "joined_wallet": 0,
        //             "coin": 7909200,
        //             "stock": 0,
        //             "total_stock": 0,
        //             "ref_code": "t72hfz",
        //             "user_level": 1,
        //             "user_group_id": null,
        //             "skin": "basic",
        //             "joined_twitter": 1,
        //             "joined_telegram": 0,
        //             "joined_channel": 0,
        //             "joined_discord": 0,
        //             "uid": "0x28 1350 8818 9425",
        //             "age": 1,
        //             "age_reward": 1800,
        //             "joined_facescan": 1,
        //             "premium_reward": 0,
        //             "ton_wallet": null,
        //             "ton_gas_paid": 0,
        //             "verified_premium": 0,
        //             "verified_face": 0,
        //             "verified_boost_channel": 0,
        //             "verified_with_appname": 0,
        //             "app_center_tickets": 0,
        //             "created_at": "2024-12-26 00:00:00"
        //         }
        //     }
        // }
        vailcode(
          res,
          async () => {
            const data = res.data;
            console.log("datadatadatadata", data);

            if (!data) {
              resolve(null);
              return;
            }
            // await new Promise((reso) => setTimeout(reso, 1500));
            commit("user/setUserInfo", data.userInfo, { root: true });
            resolve(data);
          },
          (err) => {
            console.log(99999);
            resolve(null);
          }
        );
      });
    },
  },
};
