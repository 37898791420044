var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.item ? _c('div', {
    staticClass: "mUpgradeItem",
    class: [_vm.canClick()]
  }, [_c('div', {
    staticClass: "mUpgradeItem__icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.item.icon,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "mUpgradeItem__label"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c('div', {
    staticClass: "upgrade-btns",
    on: {
      "click": function ($event) {
        return _vm.onUpgrade($event, _vm.item);
      }
    }
  }, [_vm.item.completion_rate < 1 ? _c('div', {
    staticClass: "upgrade-progress-text"
  }, [_vm.item.completion_rate === _vm.item.completion_rate_old ? [_vm._v(" " + _vm._s(_vm.item.completion_rate * 100) + "% ")] : [_c('m-count-up', {
    attrs: {
      "endVal": Number(_vm.item.completion_rate * 100)
    }
  }), _vm._v("% ")]], 2) : _vm._e(), _c('div', {
    ref: "upgradeBtnRef",
    staticClass: "upgrade-add"
  }, [_c('div', {
    staticClass: "upgrade-progress"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: '100%'
    }
  }), _vm.item.loading ? _c('div', {
    staticClass: "circleLoading"
  }) : _vm._e()]), _vm.item.completion_rate >= 1 ? _c('svg-icon', {
    staticClass: "icon-ok",
    attrs: {
      "name": "icon-ok"
    }
  }) : _vm.item.completion_rate < 1 && !_vm.item.loading ? _c('svg-icon', {
    attrs: {
      "name": "icon-add"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "upgrade-btn-tag"
  }, [_c('div', {
    staticClass: "upgrade-btn-tag-main",
    class: _vm.userInfo.coin < _vm.item.upgrade_cost && 'disable'
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon/icon-hic.png'),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.fmt_pre(_vm.item.upgrade_cost)))])])], 1)])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };