
export const svg_close = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 8 8" fill="currentColor">
<g>
  <rect class="cls-2" width="1" height="1" />
  <polygon class="cls-1" points="1 0 0 0 0 1 1 1 1 0 1 0" />
</g>
<g>
  <rect class="cls-2" x="1" y="1" width="1" height="1" />
  <polygon class="cls-1" points="2 1 1 1 1 2 2 2 2 1 2 1" />
</g>
<g>
  <rect class="cls-2" x="2" y="2" width="1" height="1" />
  <polygon class="cls-1" points="3 2 2 2 2 3 3 3 3 2 3 2" />
</g>
<g>
  <rect class="cls-2" x="3" y="3" width="1" height="1" />
  <polygon class="cls-1" points="4 3 3 3 3 4 4 4 4 3 4 3" />
</g>
<g>
  <rect class="cls-2" x="4" y="4" width="1" height="1" />
  <polygon class="cls-1" points="5 4 4 4 4 5 5 5 5 4 5 4" />
</g>
<g>
  <rect class="cls-2" x="5" y="5" width="1" height="1" />
  <polygon class="cls-1" points="6 5 5 5 5 6 6 6 6 5 6 5" />
</g>
<g>
  <rect class="cls-2" x="6" y="6" width="1" height="1" />
  <polygon class="cls-1" points="7 6 6 6 6 7 7 7 7 6 7 6" />
</g>
<g>
  <rect class="cls-2" x="7" y="7" width="1" height="1" />
  <polygon class="cls-1" points="8 7 7 7 7 8 8 8 8 7 8 7" />
</g>
<g>
  <rect class="cls-2" x="7" width="1" height="1" />
  <polygon class="cls-1" points="8 0 7 0 7 1 8 1 8 0 8 0" />
</g>
<g>
  <rect class="cls-2" x="6" y="1" width="1" height="1" />
  <polygon class="cls-1" points="7 1 6 1 6 2 7 2 7 1 7 1" />
</g>
<g>
  <rect class="cls-2" x="5" y="2" width="1" height="1" />
  <polygon class="cls-1" points="6 2 5 2 5 3 6 3 6 2 6 2" />
</g>
<g>
  <rect class="cls-2" x="4" y="3" width="1" height="1" />
  <polygon class="cls-1" points="5 3 4 3 4 4 5 4 5 3 5 3" />
</g>
<g>
  <rect class="cls-2" x="3" y="4" width="1" height="1" />
  <polygon class="cls-1" points="4 4 3 4 3 5 4 5 4 4 4 4" />
</g>
<g>
  <rect class="cls-2" x="2" y="5" width="1" height="1" />
  <polygon class="cls-1" points="3 5 2 5 2 6 3 6 3 5 3 5" />
</g>
<g>
  <rect class="cls-2" x="1" y="6" width="1" height="1" />
  <polygon class="cls-1" points="2 6 1 6 1 7 2 7 2 6 2 6" />
</g>
<g>
  <rect class="cls-2" y="7" width="1" height="1" />
  <polygon class="cls-1" points="1 7 0 7 0 8 1 8 1 7 1 7" />
</g>
</svg>`;


export const svg_level_arrow = `
<svg width="1em" height="1em" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
  <style>
    @keyframes shiftColors1 {
      0% { fill: #156133; }
      33.33% { fill: #15d160; }
      66.66% { fill: #158642; }
      100% { fill: #156133; }
    }

    @keyframes shiftColors2 {
      0% { fill: #158642; }
      33.33% { fill: #156133; }
      66.66% { fill: #15d160; }
      100% { fill: #158642; }
    }

    @keyframes shiftColors3 {
      0% { fill: #15d160; }
      33.33% { fill: #158642; }
      66.66% { fill: #156133; }
      100% { fill: #15d160; }
    }

    .arrow1 { animation: shiftColors1 2s infinite; }
    .arrow2 { animation: shiftColors2 2s infinite; }
    .arrow3 { animation: shiftColors3 2s infinite; }
  </style>

  <g transform="translate(-250, 0)">
    <path class="arrow1" d="M332.501333 183.168a42.666667 42.666667 0 0 1 57.621334-2.496l2.709333 2.496 298.666667 298.666667a42.666667 42.666667 0 0 1 2.496 57.621333l-2.496 2.709333-298.666667 298.666667a42.666667 42.666667 0 0 1-62.826667-57.621333l2.496-2.709334L600.96 512 332.501333 243.498667a42.666667 42.666667 0 0 1-2.496-57.621334l2.496-2.709333z"/>
  </g>

  <g transform="translate(0, 0)">
    <path class="arrow2" d="M332.501333 183.168a42.666667 42.666667 0 0 1 57.621334-2.496l2.709333 2.496 298.666667 298.666667a42.666667 42.666667 0 0 1 2.496 57.621333l-2.496 2.709333-298.666667 298.666667a42.666667 42.666667 0 0 1-62.826667-57.621333l2.496-2.709334L600.96 512 332.501333 243.498667a42.666667 42.666667 0 0 1-2.496-57.621334l2.496-2.709333z"/>
  </g>

  <g transform="translate(250, 0)">
    <path class="arrow3" d="M332.501333 183.168a42.666667 42.666667 0 0 1 57.621334-2.496l2.709333 2.496 298.666667 298.666667a42.666667 42.666667 0 0 1 2.496 57.621333l-2.496 2.709333-298.666667 298.666667a42.666667 42.666667 0 0 1-62.826667-57.621333l2.496-2.709334L600.96 512 332.501333 243.498667a42.666667 42.666667 0 0 1-2.496-57.621334l2.496-2.709333z"/>
  </g>
</svg>
`





