import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mSlotMachine",
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  props: {
    configs: {
      typeof: Array,
      default: () => []
    },
    results: {
      typeof: Array,
      default: () => []
    },
    rowNum: {
      typeof: Number,
      default: 3
    },
    isSelect: {
      typeof: Boolean,
      default: false
    }
  },
  data() {
    return {
      trigger: null,
      disabled: false,
      slotMachineConfig: [],
      initSpeed: 3000,
      incremNum: 1000
    };
  },
  watch: {
    configs: {
      handler(len) {
        if (!len) return;
        this.setConfig();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setConfig(type) {
      let slotMachineConfig = [];
      console.log("this.configs", this.configs);
      if (!this.configs) return;
      slotMachineConfig = Array.from(new Array(this.rowNum), (val, index) => {
        return {
          duration: this.initSpeed + index * this.incremNum,
          rollback: type === "init" ? 0 : index >= this.rowNum - 1 ? 0.1 : 0,
          gifts: this.configs[index].map(it => {
            return {
              type: "image",
              name: index,
              path: require(`@images/${it}.png`),
              currency: it
            };
          })
        };
      });
      console.log("slotMachineConfig111111111", slotMachineConfig);
      this.slotMachineConfig = slotMachineConfig;
    },
    turn(obj) {
      if (obj) {
        const combo = obj.disp_combo_new;
        console.log("combocombocombocombo", combo, this.slotMachineConfig);
        for (const key in combo) {
          this.slotMachineConfig[key].result = combo[key];
        }
        this.disabled = true;
        this.trigger = null;
        setTimeout(() => {
          this.trigger = new Date();
        }, 150);
        return;
      }
      this.$emit("turn", results => {
        const combo = results.disp_combo_new;
        console.log("combocombocombo", combo);
        for (const key in combo) {
          this.slotMachineConfig[key].result = combo[key];
        }
        this.disabled = true;
        this.trigger = new Date();
      });
    },
    isFinished(val) {
      const autoTurnList = this.$el.querySelectorAll(".autoTurn");
      console.log("autoTurnList", autoTurnList);
      if (autoTurnList.length === 2) {
        this.$emit("finish", 2);
      }
      if (autoTurnList.length === 1) {
        this.$emit("finish", null);
      }
    }
  }
};