import axios from "axios";
import Vue from "vue";
import loadConfig from "@tonWalletConfig/config";
import store from "@store/index";
const crypto = require("crypto");
import requestApiCache from "@assets/js/requestApiCache"; // 引入缓存模块
import qs from "qs"; // Assuming you need qs for query string parsing

const Axios = axios.create({
  timeout: 30000, // 请求超时时间
});

// 缓存配置：为每个URL 定义独立的缓存策略
const cacheConfig = {
  "/task/list": { duration: 15000, cacheEnabled: true },
  "/game/property/list": { duration: 30000, cacheEnabled: true },
  "/ranking/topleaders": { duration: 30000, cacheEnabled: true },
  "/ranking/friend": { duration: 30000, cacheEnabled: true },
  "/lucky/spins/prices": { duration: 60000, cacheEnabled: true },
};

Axios.defaults.baseURL = loadConfig.http_host;

// 配置忽略的路径
const ignorePath = [
  //'/mine/upgrade',
  //'/lucky/lottery/draw'
];

// 添加请求拦截器 缓存策略相关
Axios.interceptors.request.use(
  (config) => {
    let token = store.state["user"]["token"] || "";
    if (token) config.data["_token"] = token;
    config.maxBodyLength = Infinity;
    config.headers["Content-Type"] = "application/json";
    const url = config.url;
    const cacheOptions = cacheConfig[url] || { cacheEnabled: false }; // 获取缓存配置
    if (cacheOptions.cacheEnabled && config.method === "post") {
      const cacheKey = requestApiCache.generateCacheKey(config);
      const cachedData = requestApiCache.getCache(cacheKey, cacheOptions.duration);
      //响应时存在缓存数据
      if (cachedData) {
        const controller = new AbortController();
        config.signal = controller.signal;
        // 调用 abort() 方法取消请求
        controller.abort();
        //阻止axios发送http请求并抛出异常 在异常中下发缓存数据
        return Promise.reject(new axios.Cancel(`${url}-||-${cacheKey} -||- ${JSON.stringify(cachedData)}`));
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
Axios.interceptors.response.use(
  (res) => {
    const url = res.config.url;
    const cacheOptions = cacheConfig[url] || { cacheEnabled: false }; // 获取缓存配置
    if (cacheOptions.cacheEnabled && res.config.method === "post") {
      const cacheKey = requestApiCache.generateCacheKey(res.config);
      const cachedData = requestApiCache.getCache(cacheKey, cacheOptions.duration);
      requestApiCache.setCache(cacheKey, cachedData ? cachedData : res.data, cacheOptions.duration);
    }
    return res.data;
  },
  (err) => {
    console.log("errerrerr", err);

    // **如果请求被取消（因缓存命中），解析缓存数据并返回**
    if (axios.isCancel(err)) {
      console.log("Request was cancelled due to cache hit:", err.message);

      // **解析 axios.Cancel 的错误消息**
      const match = err.message.split("-||-");
      console.log("matchmatchmatchmatchmatch", match);
      if (match) {
        try {
          const cachedData = JSON.parse(match[2]); // **提取缓存数据**
          console.log("Returning cached response:", cachedData);
          return Promise.resolve(cachedData); // **返回缓存数据**
        } catch (parseError) {
          console.log("Failed to parse cached response:", parseError);
        }
      }

      return Promise.resolve(null); // **如果解析失败，返回 null 或其他默认值**
    }

    // 检查是否是网络问题
    console.log("window.navigator.onLine", window.navigator.onLine);
    if (!window.navigator.onLine) {
      // 处理重试逻辑
      let retryCount = err?.config?.retryCount || 0;
      const maxRetries = 2;

      function retryRequest() {
        console.log(88888888);
        if (loadConfig.ext_http_host.constructor === Array) {
          let idx = Math.floor(Math.random() * loadConfig.ext_http_host.length);
          err.config.baseURL = loadConfig.ext_http_host[idx];
        } else {
          err.config.baseURL = loadConfig.ext_http_host;
        }
        console.log(9999999);

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            console.log(`Retry count: ${retryCount + 1}`);
            if (retryCount >= maxRetries) {
              resolve(err);
            } else {
              retryCount++;
              let newConfig = { ...err.config, retryCount };
              newConfig.data = qs.parse(newConfig.data); // Ensure data is parsed
              Axios(newConfig).then(resolve).catch(reject);
            }
          }, 1000); // Wait 1 second before retry
        });
      }

      return retryRequest();
    }
  }
);

// 清除指定 URL 的缓存
function clearCacheForUrl(url) {
  requestApiCache.clearCacheByUrl(url);
}


// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: (Vue) => {
    Object.defineProperty(Vue.prototype, "$http", {
      value: Axios,
    });
    Object.defineProperty(Vue.prototype, "$httpClear", {
      value: clearCacheForUrl, // 挂载清除缓存的方法
    });
  },
  Axios,
  clearCacheForUrl, // 允许外部调用
};
