var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "risB"
  }, [_c('div', {
    staticClass: "risB-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Checking your"))), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("account")) + " ")]), _c('div', {
    staticClass: "progress"
  }, _vm._l(_vm.progressList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "progress-item",
      style: {
        opacity: item.show ? 1 : 0
      }
    }, [_c('div', {
      staticClass: "progress__title"
    }, [_vm._v(" " + _vm._s(_vm.$lang(item.text)) + " "), item.isOk ? _c('div', {
      staticClass: "progress_title_icon",
      style: {
        color: item.isOk ? '#a0fe07' : '#fff'
      }
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-ok1"
      }
    })], 1) : _vm._e()]), _c('div', {
      staticClass: "progress_main"
    }, [_c('div', {
      staticClass: "bar",
      style: {
        width: item.progress + 'px'
      }
    })])]);
  }), 0), _c('div', {
    staticClass: "btn",
    class: {
      active: _vm.isContinue
    },
    on: {
      "click": function ($event) {
        _vm.isContinue && _vm.onContinue();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Continue")) + " ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };