import { resolveObjectURL } from "buffer";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const miningVuex = createNamespacedHelpers("mining");
const taskVuex = createNamespacedHelpers("task");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload,
      setPopEnoughShow: this.setPopEnoughShow,
      isLog: false
    };
  },
  computed: {
    ...walletVuex.mapState(["tonConnect", "isMobile", "htmlSize"]),
    ...userVuex.mapState(["userInfo", "loginInfo", "isLogin"]),
    ...taskVuex.mapState(["taskInfo"]),
    route() {
      return this.$route.path;
    },
    isPhone() {
      const sUserAgent = navigator.userAgent.toLowerCase();
      return /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent);
    }
  },
  data() {
    return {
      isRouterAlive: true,
      isBindWallet: true,
      isPreLoad: false,
      popEnoughShow: false
    };
  },
  watch: {
    "$route.path": {
      async handler(newPath, oldPath) {
        // if (newPath === "/") {
        //   this.WebApp.BackButton.isVisible && this.WebApp.BackButton.hide();
        // } else {
        //   this.WebApp.BackButton.show();
        // }
        this.clearPopFunc();
      },
      deep: true
    },
    isMobile: {
      handler(bool) {
        console.log(666666, bool);
        if (bool === null) {
          document.body.style.setProperty("--maxWidth", "100%");
          return;
        }
        // document.body.style.setProperty("--maxWidth", bool ? this.htmlSize.w + "px" :   );
      },

      deep: true,
      immediate: true
    }
  },
  methods: {
    ...userVuex.mapActions(["initProcess", "bindWallet", "getuserInfo"]),
    ...userVuex.mapMutations(["setIsLogin"]),
    ...miningVuex.mapMutations(["setIsAutoSpeedMine"]),
    ...miningVuex.mapActions(["autoSpeedMine", "workerDoMining"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    setPopEnoughShow() {
      this.popEnoughShow = !this.popEnoughShow;
    },
    adapScreen() {
      this.WebApp.onEvent("viewportChanged", () => {
        document.body.style.setProperty("--maxWidth", this.WebApp.viewportHeight * (768 / 1180) + "px");
      });
    },
    mLoadingResult() {
      this.isPreLoad = true;
    }
  },
  async mounted() {
    this.adapScreen();
    this.setIsLogin(false);
    await this.initProcess();
    await this.setIsLogin(true);
  }
};