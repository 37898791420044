var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "risA"
  }, [_c('div', {
    staticClass: "risA-main"
  }, [_c('div', {
    staticClass: "risA-img"
  }), _c('div', {
    staticClass: "risA-intro"
  }, [_vm._v(" " + _vm._s(_vm.$lang("You've been in Telegram for a while,"))), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("it's time to get rewarded!")) + " ")])]), _c('div', {
    staticClass: "ris-btn",
    on: {
      "click": _vm.onRisAConfirm
    }
  }, [_vm._v("Wow, Let'go")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };