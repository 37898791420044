export default {
  name: "mTabThree",
  props: {
    tab: {
      default: () => []
    },
    selectTab: {
      default: null
    }
  },
  watch: {
    tab: {
      handler(list) {
        if (list.length > 0) {
          this.$emit("update:selectTab", list[0]);
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {};
  },
  methods: {
    onTab(item) {
      this.$emit("update:selectTab", item);
      this.$emit("tabFn", item);
    }
  }
};