export default {
  name: "mGift",
  props: {
    trigger: {
      type: Date,
      default: false
    },
    config: {
      type: Object,
      required: true
    },
    itemHeight: {
      typeof: [String, Number],
      default: 70
    },
    fontSize: {
      typeof: [String, Number],
      default: 86
    },
    isSelect: {
      typeof: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRunning: false,
      currentDeg: 0,
      targetDeg: 0,
      giftsDeg: []
    };
  },
  computed: {
    rotate() {
      return 360 / this.config.gifts.length;
    },
    translateZ() {
      return this.itemHeight / 2 / Math.tan(this.rotate / 2 / 180 * Math.PI);
    }
  },
  watch: {
    config: {
      handler() {
        this.setConfig();
      },
      deep: true
    },
    trigger: {
      handler(val) {
        this.autoTurn();
      },
      deep: true
    },
    isRunning() {
      if (this.isRunning) {
        setTimeout(() => {
          this.autoTurnStop();
        }, this.config.duration + 200);
      }
    }
  },
  mounted() {
    this.setConfig();
    this.logGiftsDeg();
  },
  destroyed() {},
  methods: {
    logGiftsDeg() {
      // 紀錄獎品角度
      this.config.gifts.forEach((gift, index) => {
        this.giftsDeg[index] = {
          from: index === 0 ? 0 : this.giftsDeg[index - 1].to,
          to: index === 0 ? this.rotate : this.giftsDeg[index - 1].to + this.rotate,
          name: gift.name
        };
      });
    },
    setConfig() {
      // 將config的變數們寫入CSS變數中
      this.$el.style.setProperty("--duration", `${this.config.duration}ms`);
      this.$el.style.setProperty("--currentDeg", `-${this.currentDeg}deg`);
    },
    autoTurn() {
      // 取得隨機角度(預設至少跑5圈)
      const idx = this.config.gifts.findIndex(itm => {
        return this.config.result === itm.currency;
      });
      const resultIndex = idx / this.config.gifts.length;
      // let randomDeg = Math.random() * 360 + 360 * 5;
      let randomDeg = resultIndex * 360 + 360 * 5;
      randomDeg -= randomDeg % this.rotate; // 減去餘數，避免有高低不一的狀況
      this.targetDeg = randomDeg;
      console.log("this.config.gifts2222", resultIndex, randomDeg, this.rotate, idx, this.config, this.config.result);
      // 取得隨機回彈角度
      const randomRollBackDeg = this.config.rollback ? Math.random() * this.config.rollback + 1 : 1;
      // 設定轉動角度
      this.$el.style.setProperty("--targetDeg", `-${this.targetDeg}deg`);
      this.$el.style.setProperty("--rollBackDeg", `${randomRollBackDeg}`);
      // 執行轉動
      this.isRunning = true;
    },
    autoTurnStop() {
      // 把結束時的角度設定為當前角度
      this.currentDeg = this.targetDeg % 360;
      this.$el.style.setProperty("--currentDeg", ` -${this.currentDeg}deg`);
      // 顯示獎品資料(結束角度 + 單片角度/2)
      let giftName = null;
      const endDeg = this.currentDeg + this.rotate / 2;
      this.giftsDeg.forEach(gift => {
        if (endDeg >= gift.from && endDeg <= gift.to) {
          giftName = gift.name;
        }
      });
      // 宣告轉動結束
      this.isRunning = false;
      this.$emit("finished", giftName); // 告訴上層已經轉完
    }
  }
};