var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "userCenter"
  }, [_c('m-head'), _c('div', {
    staticClass: "userCenter-main page-main"
  }, [_c('div', {
    staticClass: "upgrade-info"
  }, [_c('div', {
    staticClass: "upgrade-info-main"
  }, [_c('img', {
    staticClass: "wow bounce",
    attrs: {
      "data-wow-duration": "0.8s",
      "src": require(`@images/avatar/body/${_vm.gameProgress.currentLevel >= _vm.botImgLimit ? _vm.botImgLimit : _vm.gameProgress.currentLevel}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "tip"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__label"
  }, [_vm._v("ID")]), _c('div', {
    staticClass: "info__value"
  }, [_vm._v(_vm._s(_vm.userInfo.id))])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info__label"
  }, [_vm._v("Registration time")]), _c('div', {
    staticClass: "info__value"
  }, [_vm._v(_vm._s(_vm.fmt_time(_vm.userInfo.created_at).format("YYYY.MM.DD hh:mm")))])])]), _c('div', {
    staticClass: "level"
  }, [_c('div', {
    staticClass: "level-main"
  }, [_vm._v("Lv." + _vm._s(_vm.gameProgress.currentLevel))])]), _c('div', {
    staticClass: "intro"
  }, [_c('m-tier-benefits')], 1)])]), _c('div', {
    staticClass: "upgrade-hum"
  }, [_c('div', {
    staticClass: "hum__title"
  }, [_vm._v("Your Humanity")]), _c('div', {
    staticClass: "hum-list"
  }, [_c('div', {
    staticClass: "hum-item"
  }, [_c('div', {
    staticClass: "hum-item__icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-human.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "hum-item__label"
  }, [_vm._v("human Verification")]), _c('div', {
    staticClass: "hum-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm.fmt_pre(_vm.userInfo.facescan_reward)) + " ")])]), _c('div', {
    staticClass: "hum-item"
  }, [_c('div', {
    staticClass: "hum-item__icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-account.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "hum-item__label"
  }, [_vm._v("Account age")]), _c('div', {
    staticClass: "hum-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm.fmt_pre(_vm.userInfo.age_reward)) + " ")])]), _c('div', {
    staticClass: "hum-item"
  }, [_c('div', {
    staticClass: "hum-item__icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-telegram.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "hum-item__label"
  }, [_vm._v("Telegram Premium")]), _c('div', {
    staticClass: "hum-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm.fmt_pre(_vm.userInfo.premium_reward)) + " ")])]), _c('div', {
    staticClass: "hum-item"
  }, [_c('div', {
    staticClass: "hum-item__icon wow zoomIn",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-invite.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "hum-item__label"
  }, [_vm._v("Invited friends")]), _c('div', {
    staticClass: "hum-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm.fmt_pre(_vm.userInfo.invite_reward)) + " ")])])])])]), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };