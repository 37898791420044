var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.tab.length > 0 ? _c('div', {
    staticClass: "mTab"
  }, [_c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll"
    }],
    staticClass: "mTab-scroll"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mTab-item wow fadeInRight",
      class: _vm.selectTab && _vm.selectTab.id === item.id && 'active',
      attrs: {
        "data-wow-duration": `${0.2 + index / 10}s`
      },
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_c('div', {
      staticClass: "mTab-item-main"
    }, [_c('div', {
      staticClass: "mTab-item__text"
    }, [_vm._v(_vm._s(item.name))])]), item.hasOwnProperty('completion_rate') ? _c('div', {
      staticClass: "mTab-tag"
    }, [_c('div', {
      ref: `mTab_${item.id}`,
      refInFor: true,
      staticClass: "mTab-tag-main"
    }, [_vm._v(" " + _vm._s(parseInt(item.completion_rate * 100)) + "% ")])]) : _vm._e()]);
  }), 0)]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };