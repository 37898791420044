var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mLevelProgress",
    style: {
      '--height': _vm.remTran(_vm.height) + 'rem',
      '--width': _vm.remTran(_vm.width) + 'rem'
    }
  }, [_c('div', {
    staticClass: "level current-level"
  }, [_vm._v("Lv." + _vm._s(_vm.level ? _vm.level[0] : ""))]), _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    ref: "progressRef",
    staticClass: "progres-body",
    style: {
      width: _vm.progress + '%'
    }
  }, [_vm._m(0)]), _c('div', {
    staticClass: "progress__text"
  }, [_vm._v(_vm._s(_vm.fmt_pre(_vm.progress)) + "%")])]), _c('div', {
    staticClass: "level next-level"
  }, [_vm._v(_vm._s(_vm.level ? _vm.level[1] == -1 ? 'Max' : `Lv.${_vm.level[1]}` : ""))])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "bar"
  })]);
}];
export { render, staticRenderFns };