export default {
  name: "mFirendLeasderBroard",
  props: {
    ranking: {
      default: null
    }
  },
  data() {
    return {};
  },
  methods: {
    onTab(item) {
      this.selectTab = item;
    },
    initFn() {
      console.log("mFirendLeasderBroard");
    }
  }
};