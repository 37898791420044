import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const facescanVuex = createNamespacedHelpers("facescan");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "mBtnFace",
  props: {
    isNoStyle: {
      default: false
    },
    type: {
      default: "COIN"
    }
  },
  computed: {
    ...userVuex.mapState(["userInfo", "settingInfo", "balanceInfo", "isLogin"]),
    ...taskVuex.mapState(["taskInfo"])
  },
  data() {
    return {
      popPromptInfo: null,
      popPromptShow: false
    };
  },
  watch: {
    popPromptShow: {
      handler(bool) {
        if (!bool) {
          this.popPromptInfo.error = null;
          this.popPromptInfo.cancelText = null;
        }
      }
    },
    watch: {
      isLogin: {
        async handler(bool) {
          if (bool) {
            // this.popPromptInfo.reward = this.settingInfo?.reward?.joined?.facescan ?? 0 + this.settingInfo.coin;
          }
        },
        deep: true,
        immediate: true
      }
    }
  },
  methods: {
    ...facescanVuex.mapActions(["doCheck"]),
    ...userVuex.mapMutations(["setUserInfo"]),
    facescan() {
      if (FS) {
        FS.scan();
      }
    },
    showPop() {
      this.hapticFeedback();
      this.setPopPromptInfo();
      this.popPromptShow = true;
    },
    setPopPromptInfo() {
      this.popPromptInfo = {
        title: "I am not a robot",
        icon: require("@images/icon/icon-human.png"),
        reward: `<img src="${require("@images/icon/icon-hic.png")}" alt="" /> +${this.settingInfo?.reward?.joined?.facescan ?? 0}&nbsp;&nbsp;&nbsp;<img src="${require("@images/SPINS.png")}" alt="" /> <span style="color: #6b59d1">+${this.settingInfo?.reward?.joinedSpins?.facescan ?? 0}`,
        isConfirmLoading: false,
        error: null,
        isShowdefaultIcon: false,
        confirmText: "Verify",
        confirmFn: async () => {
          this.popPromptInfo.isConfirmLoading = true;
          if (FS) {
            FS.scan();
          }
          setTimeout(() => {
            this.popPromptInfo.isConfirmLoading = false;
            this.popPromptInfo.cancelText = "Check";
          }, 5000);
        },
        cancelText: null,
        cancelFn: async () => {
          this.popPromptInfo.isCancelLoading = true;
          this.popPromptInfo.error = null;
          try {
            const res = await this.doCheck({
              target: "facescan"
            });
            if (!res) {
              this.popPromptInfo.isCancelLoading = false;
              return;
            }
            this.popPromptInfo.isCancelLoading = false;
            this.popPromptShow = false;
            this.taskInfo.popReward.reward = this.settingInfo?.reward?.joined?.facescan ?? 0;
            this.balanceInfo.spins.balance = res.userInfo.spins;
            this.taskInfo.popReward.icon = "COIN";
            this.taskInfo.popReward.isAnim = true;
            this.taskInfo.popReward.show = true;
            if (res.userInfo) {
              this.setUserInfo(res.userInfo);
            }
          } catch (err) {
            console.log("errr====", err);
            if (err && err?.code != 0) {
              this.popPromptInfo.error = err.message;
            }
            this.popPromptInfo.isCancelLoading = false;
          }
        },
        isSmallConfirmBtn: false,
        //claim出现时 confirm 按钮比例小一点
        isCancelLoading: false //claim按钮 loading状态
      };
    }
  },

  mounted() {
    if (FS) {
      FS.init({
        keyid: "hpe46b04131b28d681",
        tgId: this.userInfo.tg_id
      });
    }
  }
};