export const taskMixins = {
  computed: {
    popLimitedTimeMiningTaskStatusNum() {
      return this.popLimitedTimeMiningData.tasksList.filter((it) => it.status).length;
    },
    popLimitedTimeMiningShowConfirm() {
      return this.popLimitedTimeMiningTaskStatusNum >= this.popLimitedTimeMiningData.tasksList.length;
    },
    nowTime() {
      return Math.floor(new Date().getTime() / 1000);
    },
  },
  data() {
    return {
      popLimitedTimeMiningData: {
        show: false,
        tasksList: [],
        selectTask: null,
        adsCountdown: 0,
        adsCountdownTimer: null,
        isAdcoloudCount: 0, //广告
        popLimitedTimeMiningShowConfirm: false,
        taskType: null
      },
    }
  },
  watch:{
    "popLimitedTimeMiningData.show": {
      handler(bool) {
        if(!bool) {
          this.popLimitedTimeMiningData.selectTask = null
          this.popLimitedTimeMiningData.tasksList.map(itm=> {
            this.$set(itm, 'isContinue', false)
            this.$set(itm, 'isShowErrorText', false)
          })
        }
      },
      deep: true
    }
  },
  methods: {
    popLimitedTimeMiningFncSet() {
      const that = this;
      return {
        setTasksEnergyAnim: ()=> {
          if(that.hasOwnProperty('setEnergyAnim') && that.hasOwnProperty('isPlayEnergyAnim')) {
            this.isPlayEnergyAnim = true
          }
        },
        getTasksList: async (task_type) => {
          this.popLimitedTimeMiningData.taskType = task_type
          const { official, extra } = await that.getTasksInfo([task_type]);
          const list = [...official, ...extra];
          that.popLimitedTimeMiningData.tasksList = list.map((item) => {
            return {
              ...item,
              isContinue: false,
              isShowErrorText: false,
            };
          });
          that.popLimitedTimeMiningData.tasksList = that.popLimitedTimeMiningData.tasksList.slice().sort((a, b) => a.status - b.status); //排序 status=0靠前
        },
        checkTask: async (item) => {
          return new Promise(async (resolve, reject) => {
            try {
              const res = await that.getTasksUrlCheck([item.id]);
              if (res.code !== 0) {
                item.loading = false;
                that.$toasted.clear();
                that.$toasted.error(res.message);
                item.isContinue = true
                reject();
                return;
              }

              // that.popPromptShow = false;
              await that.getAccountInfo();
              await that.popLimitedTimeMiningFncSet().getTasksList(that.popLimitedTimeMiningData.taskType);
              that.popLimitedTimeMiningData.isAdcoloudCount = 0;
              item.isContinue = false;
              if(item.reward>0) {
                that.$toasted.clear()
                that.$toasted.success(`Task completed! ${that.fmt_pre(item.reward)} energy added`);
                that.popLimitedTimeMiningFncSet().setTasksEnergyAnim()
              }
              that.popLimitedTimeMiningData.selectTask = null;

              item.loading = false;
              resolve();
            } catch (err) {
              item.loading = false;
              console.log("errrrr", err);
            }
          });
        },
        onStart: (item) => {
          this.popLimitedTimeMiningData.selectTask = item;
          if (item.type == "telegram" || item.type == "channel" || item.type === "twitter") {
            that.popLimitedTimeMiningFncSet().setTypeTelegramOrChannelOrTwitter(item);
            return;
          }
          if (item.type == "adsgram" || item.type == "adcloud" || item.type === "openad") {
            that.popLimitedTimeMiningFncSet().adsgramOrAdcloudOrOpenadOpenWay(item);
          }
        },
        onCheck: async (item) => {
          if (item.type == "telegram" || item.type == "channel" || item.type === "twitter") {
            await that.popLimitedTimeMiningFncSet().setTypeTelegramOrChannelOrTwitter(item);
          } else if (item.type == "adsgram" || item.type == "adcloud" || item.type === "openad") {
            await that.popLimitedTimeMiningFncSet().setAdsgramOrAdcloudOrOpenad(item);
          }
        },
        //类型为telegram或者channel或者twitter任务流程
        setTypeTelegramOrChannelOrTwitter: async (item) => {
          return new Promise(async (resolve, reject) => {
            try {
              item.loading = true;
              const res = await that.getTasksUrlInfo([item.id]);
              if (!res) {
                item.loading = false;
                return;
              }
              if (item.type == "telegram" || item.type == "channel") {
                _v.WebApp.openTelegramLink(item.url);
              } else if (item.type === "twitter") {
                _v.WebApp.openLink(item.url);
              }
              await new Promise((resolve) => setTimeout(resolve, item.check_countdown * 1000));
              await that.popLimitedTimeMiningFncSet().checkTask(item);
              item.loading = false;
              resolve();
            } catch (err) {}
          });
        },
        //adsgram || adcloud || openad广告打开方式
        adsgramOrAdcloudOrOpenadOpenWay: async (item) => {
          item.loading = true;
          const res = await that.getTasksUrlInfo([item.id]);
          if (!res) {
            that.popLimitedTimeMiningData.isAdcoloudCount = 0;
            item.isContinue = true;
            item.loading = false;
            return;
          }
          if (item.type === "openad") {
            //打开openad广告
            // const nowTime = new Date().getTime(); //标记 观看广告起始时间点
            const bool = await that.popLimitedTimeMiningFncSet().setOpenadFnc(item);

            if (bool) {
              //已满足观看时长
              await that.popLimitedTimeMiningFncSet().checkTask(item);
            } else {
              item.loading = false;
              item.isContinue = true;
            }
            return;
          }
          if (item.type === "adsgram") {
            const nowTime = new Date().getTime(); //标记 观看广告起始时间点
            const bool = await that.popLimitedTimeMiningFncSet().setAdsgramFnc(item, nowTime);

            if (bool) {
              //已满足观看时长
              that.popLimitedTimeMiningFncSet().checkTask(item);
            } else {
              item.loading = false;
              item.isContinue = true;
            }
            return;
          }
          if (item.type === "adcloud") {
            const adsCountdownFnc = () => {
              that.popLimitedTimeMiningData.adsCountdown--;
              if (that.popLimitedTimeMiningData.adsCountdown > 0) {
                that.popLimitedTimeMiningData.adsCountdownTimer = setTimeout(() => {
                  adsCountdownFnc();
                }, 1000);
              }
            };
            //打开adcloud
            that.popLimitedTimeMiningData.isAdcoloudCount++;
            that.popLimitedTimeMiningFncSet().setAdcloudFnc(item);
            item.loading = false;
            console.log("item.isContinue", item.isContinue);
            if (item.isContinue) {
              item.isContinue = false;
              that.popLimitedTimeMiningData.selectTask = null
              that.popLimitedTimeMiningData.adsCountdown = item.secondary_check_countdown || 10;
            } else {
              that.popLimitedTimeMiningData.adsCountdown = item.check_countdown;
              item.isContinue = true;
            }
            adsCountdownFnc();
          }
        },
        //类型为adsgram或者adcloud或者openad广告任务流程
        setAdsgramOrAdcloudOrOpenad: async (item) => {
          return new Promise(async (resolve, reject) => {
            item.loading = true;

            setTimeout(() => {
      
              console.log("that.popLimitedTimeMiningData.adsCountdown", that.popLimitedTimeMiningData.adsCountdown);
              if (that.popLimitedTimeMiningData.adsCountdown <= 0) {
                item.isShowErrorText = false;
                that.popLimitedTimeMiningFncSet().checkTask(item);
              } else {
                item.loading = false;
                item.isShowErrorText = true;
                item.isContinue = false;
                that.popLimitedTimeMiningData.selectTask = null
                // if (that.popLimitedTimeMiningData.isAdcoloudCount < 2) {
                // }
              }
              console.log("item.isContinueitem.isContinue", item.isContinue, this.popLimitedTimeMiningData, this.popLimitedTimeMiningData.selectTask);
              resolve();
            }, 500);
          });
        },
        //adsgram 广告接入
        setAdsgramFnc: (item) => {
          return new Promise(async (resolve, reject) => {
            if (window?.Adsgram){
              try{
                const AdController = await window.Adsgram.init({ blockId: item.url });
                await AdController.show()
                  .then(async (result) => {
                    if (result.done) {
                      resolve(1);
                    } else {
                      resolve(null);
                    }
                  })
                  .catch((result) => {
                    resolve(null);
                  });
              }catch(err) {
                that.$toasted.clear();
                that.$toasted.info("Adsgram is temporarily unavailable, please refresh the mini-app");
                that.popLimitedTimeMiningData.adsCountdown = 0
                that.popLimitedTimeMiningData.isAdcoloudCount = 0;
                item.loading = false;
                item.isContinue = false
                that.popLimitedTimeMiningData.selectTask = null
                reject()
              }
            }else {
              const script = document.createElement("script");
              script.src = "https://sad.adsgram.ai/js/sad.min.js";
              script.defer = true;
              script.onload = () => that.popLimitedTimeMiningFncSet().setAdsgramFnc(item);
              script.onerror = () => {
                that.$toasted.error("Adsgram initialization failed, please refresh the mini-app");
                that.popLimitedTimeMiningData.adsCountdown = 0
                that.popLimitedTimeMiningData.isAdcoloudCount = 0;
                item.loading = false;
                item.isContinue = false;
                that.popLimitedTimeMiningData.selectTask = null
                reject()
              };
              document.head.appendChild(script);
            }
          });
        },
        //openad 广告接入
        setOpenadFnc: async (item) => {
          return new Promise(async (resolve, reject) => {
            const adParams = {
              version: "", // your app version if there is no data, please leave it blank
              TG: true, // If it is required for Telegram TMA application, and the value is true.
            };
            let dataParams = item.url.split("|");
            const adInfo = {
              zoneId: Number(dataParams[0]), // example zoneId, please check your own code parameters
              publisherId: Number(dataParams[1]), // example publisherId, please check your own code parameters
              eventId: 0, // Reserved Parameter
            };
            const userInfo = {
              userId: "", // user Id, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
              firstName: "", // firstName or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
              lastName: "", // lastName or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
              userName: "", // username or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
              walletType: "", // user wallet type: TON / EVM / TRON / other blockchain project names,  if there is no data, please leave it blank
              walletAddress: "", // user wallet address,  if there is no data, please leave it blank
            };
            try{
              let res = await window.openADJsSDK.interactive.init({ adParams, adInfo, userInfo });
              let timer = null
              let startTime = null; //标记 观看广告起始时间点
              if (res.code === 0) {
                const callbackFunc = {
                  // Indicates load ad resource from OpenAD platform, false will be returned if there is no resource to be loaded for the publisher slot/zone
                  adResourceLoad: (e) => {
                    // 'step1', e = ture / false
                  },
                  // Indicates the interactive ad is opening
                  adOpening: (e) => {
                    startTime = new Date().getTime(); //标记 观看广告起始时间点
                    // 'step2', e = ture / false
                  },
                  // Indicates the interactive ad is opened,
                  adOpened: (e) => {
                    // 'step3',  e = ture / false
                  },
                  // indicates the interactive ad task is finished, the task is defined by publisher
                  adTaskFinished: (e) => {
                    const nowTime = new Date().getTime();
                    const watchedTime = (nowTime - startTime) / 1000; //标记退出广告所在的时间点
                    setTimeout(() => {
                      resolve(watchedTime >= item.check_countdown); //观看广告时长至少要 大于等于check_countdown 否者重新观看
                    }, 5000);
                    // 'step5',  e = viewAD / click  / close
                    // if (!item.completed) this.checkExtralTask(item, adv);
                  },
                  // Indicates the interactive ad is closing
                  adClosing: (e) => {
                    console.log("Openad adClosing");
                    // 'step6', e = viewAD / click  / close
                  },
                  // Indicates the interactive ad is closed
                  adClosed: (e) => {
                    console.log("Openad adClosed");
                    // 'step7', e = viewAD / click / close
                    // If you want to perform different steps based on different shutdown states, please write the code here.
                  },
                  // Indicates clicked and jumps
                  adClick: (e) => {
                    console.log("Openad adClick");
                    clearTimeout(timer)
                    timer = setTimeout(() => {
                      resolve(true)
                    },item.secondary_check_countdown * 1000);
                    // 'step4', e = ture / false
                  },
                };
                window.openADJsSDK.interactive.getRender({ adInfo, cb: callbackFunc });
              }
            }catch(err) {
              that.$toasted.clear()
              that.$toasted.error("openad error!")
              item.loading = false
            }
            // if(window?.openADJsSDK) {
            // }else {
            //   const script = document.createElement("script");
            //   script.src = "https://protocol.openad.network/sdkloader.js";
            //   script.type = "text/javascript"
            //   script.name = "openADJsSDK"
            //   script.version = "3.0"
            //   script.onload = async() =>{
            //      that.popLimitedTimeMiningFncSet().setOpenadFnc(item)
            //      const bool = await that.popLimitedTimeMiningFncSet().setOpenadFnc(item);
            //       if (bool) {
            //         //已满足观看时长
            //         await that.popLimitedTimeMiningFncSet().checkTask(item);
            //       } else {
            //         item.loading = false;
            //         item.isContinue = true;
            //       }
            //     };
            //   script.onerror = () => {
            //     this.$toasted.error("openAD initialization failed, please refresh the mini-app");
            //     that.popLimitedTimeMiningData.adsCountdown = 0
            //     that.popLimitedTimeMiningData.isAdcoloudCount = 0;
            //     item.loading = false;
            //     item.isContinue = false;
            //     that.popLimitedTimeMiningData.selectTask = null
            //     reject
            //   };
            //   document.head.appendChild(script);
            // }
          });
        },
        //adcloud 广告跳转
        setAdcloudFnc: (item) => {
          that.WebApp.openLink(item.url);
        },
      };
    },
  },
  mounted() {},
};
