import "core-js/modules/es.array.push.js";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
const walletVuex = createNamespacedHelpers("wallet");
import particles from "particles.js";
Vue.use(particles);
export default {
  name: "index",
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...gameVuex.mapState(["gameProgress"]),
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    return {
      isCoinAnim: false
    };
  },
  watch: {
    "userInfo.coin": {
      handler(newVal, oldVal) {
        console.log("newVal", newVal, oldVal);
        this.isCoinAnim = newVal != oldVal && oldVal != undefined && oldVal != null;
      },
      deep: true,
      immediate: true
    },
    isLogin: {
      async handler(bool) {
        if (bool) {
          // this.setWowAnim();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    upgradePageLink() {
      this.hapticFeedback();
      this.$router.push("/upgrade");
    }
  },
  mounted() {
    this.setWowAnim();
    this.$nextTick(particlesJS.load("particles-js", "/particlesjs-config.json"));
  }
};