import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rewards-page page"
  }, [_c('m-head'), _c('div', {
    staticClass: "rewards-main page-main"
  }, [_c('div', {
    staticClass: "rewards-list"
  }, [_c('div', {
    staticClass: "rewards-con"
  }, [_c('div', {
    staticClass: "rewards-select",
    on: {
      "click": _vm.onTab
    }
  }, [_vm.selectTab === 1 ? [_vm._v(" Actiive "), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : [_vm._v(" Inactive "), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })]], 2), _c('div', {
    staticClass: "rewards__title com"
  }, [_vm._v(_vm._s(_vm.selectTab == 1 ? "Inactive" : "Actiive"))]), _c('div', {
    staticClass: "rewards-item",
    on: {
      "click": () => _vm.$router.push('/daily')
    }
  }, [_c('div', {
    staticClass: "rewards-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-login.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "rewards-item-info"
  }, [_c('div', {
    staticClass: "rewards-item__label"
  }, [_vm._v("Login everyday")]), _vm.dailyInfo ? _c('div', {
    staticClass: "rewards-item__value"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(" +" + _vm._s(_vm.dailyInfo.total_coins) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "rewards-btns"
  }, [_c('div', {
    staticClass: "rewards-arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)])])]), _c('div', {
    staticClass: "rewards-con"
  }, [_vm.officialTaskList && _vm.officialTaskListFilter.length > 0 ? _c('div', {
    staticClass: "rewards__title"
  }, [_vm._v("BASE")]) : _vm._e(), _c('transition-group', {
    staticClass: "rewards-list",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.officialTaskList ? _vm._l(_vm.officialTaskListFilter.slice(0, 4), function (item, index) {
    return _c('div', {
      key: `official${index}`,
      staticClass: "rewards-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      },
      on: {
        "click": function ($event) {
          return _vm.onShowTaskPop(item);
        }
      }
    }, [_c('div', {
      staticClass: "rewards-item-icon"
    }, [item.icon ? [_c('img', {
      attrs: {
        "src": item.icon
      }
    })] : item.type == 'discord' || item.type == 'telegram' || item.type == 'channel' || item.type == 'twitter' ? [_c('img', {
      attrs: {
        "src": require(`@images/icon-${item.type == 'channel' ? 'telegram' : item.type}.png`),
        "alt": ""
      }
    })] : _c('img', {
      attrs: {
        "src": require(`@images/icon/icon-extend.png`),
        "alt": ""
      }
    })], 2), _c('div', {
      staticClass: "rewards-item-info"
    }, [_c('div', {
      staticClass: "rewards-item__label",
      class: _vm.isMobile && 'wap'
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "rewards-item__value"
    }, [item.reward > 0 ? [_c('img', {
      attrs: {
        "src": require(`@images/icon/icon-hic.png`),
        "alt": ""
      }
    }), _vm._v(" +" + _vm._s(_vm._f("fmt_pre")(item.reward)) + " ")] : _vm._e()], 2), _vm.selectTab !== 1 && item.cyclical == 1 && item.countdown > 0 && item.daily_completion_count > 0 && item.daily_limit_count > 0 ? _c('div', {
      staticClass: "rewards-item__value time"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/icon/icon-time.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("formatHour")(item.countdown)) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "rewards-btns"
    }, [_c('div', {
      staticClass: "rewards-arrow"
    }, [item.isLoading ? _c('div', {
      staticClass: "circleLoading"
    }) : item.completed === 1 && (item.cyclical === 0 || item.cyclical === 1 && item.daily_limit_count > 0 && item.daily_completion_count >= item.daily_limit_count) ? _c('svg-icon', {
      staticClass: "ok",
      attrs: {
        "name": "icon-ok"
      }
    }) : _c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1)])]);
  }) : _vm._e()], 2), !_vm.officialTaskList ? [_vm._m(0)] : _vm._e()], 2), _c('div', {
    staticClass: "rewards-con"
  }, [_vm.extraTaskList && _vm.extraTaskListFilter.length > 0 ? _c('div', {
    staticClass: "rewards__title"
  }, [_vm._v("EXTARS")]) : _vm._e(), _c('transition-group', {
    staticClass: "rewards-list",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.extraTaskList ? _vm._l(_vm.extraTaskListFilter, function (item, index) {
    return _c('div', {
      key: `extra${index}`,
      staticClass: "rewards-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      },
      on: {
        "click": function ($event) {
          return _vm.onShowTaskPop(item);
        }
      }
    }, [_c('div', {
      staticClass: "rewards-item-icon"
    }, [item.icon ? [_c('img', {
      attrs: {
        "src": item.icon
      }
    })] : item.type == 'discord' || item.type == 'telegram' || item.type == 'channel' || item.type == 'twitter' ? [_c('img', {
      attrs: {
        "src": require(`@images/icon-${item.type == 'channel' ? 'telegram' : item.type}.png`),
        "alt": ""
      }
    })] : _c('img', {
      attrs: {
        "src": require(`@images/icon/icon-extend.png`),
        "alt": ""
      }
    })], 2), _c('div', {
      staticClass: "rewards-item-info"
    }, [_c('div', {
      staticClass: "rewards-item__label",
      class: _vm.isMobile && 'wap'
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "rewards-item__value"
    }, [item.reward > 0 ? [_c('img', {
      attrs: {
        "src": require(`@images/icon/icon-hic.png`),
        "alt": ""
      }
    }), _vm._v(" +" + _vm._s(_vm._f("fmt_pre")(item.reward)) + " ")] : _vm._e()], 2), _vm.selectTab !== 1 && item.cyclical == 1 && item.countdown > 0 && item.daily_completion_count > 0 && item.daily_limit_count > 0 ? _c('div', {
      staticClass: "rewards-item__value"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/icon/icon-time.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("formatHour")(item.countdown)) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "rewards-btns"
    }, [_c('div', {
      staticClass: "rewards-arrow"
    }, [_c('div', {
      staticClass: "rewards-arrow"
    }, [item.isLoading ? _c('div', {
      staticClass: "circleLoading"
    }) : item.completed === 1 && (item.cyclical === 0 || item.cyclical === 1 && item.daily_limit_count > 0 && item.daily_completion_count >= item.daily_limit_count) ? _c('svg-icon', {
      staticClass: "ok",
      attrs: {
        "name": "icon-ok"
      }
    }) : _c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1)])])]);
  }) : _vm._e()], 2), !_vm.extraTaskList ? [_vm._m(1)] : _vm._e()], 2)])]), _c('m-menu'), _c('pop-prompt', {
    attrs: {
      "isShadowClick": true,
      "title": " ",
      "isSmallConfirmBtn": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isSmallConfirmBtn') && _vm.popPromptInfo.isSmallConfirmBtn,
      "isCancelLoading": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isCancelLoading') && _vm.popPromptInfo.isCancelLoading,
      "isSmallCancelBtn": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('smallCancelBtn') && _vm.popPromptInfo.smallCancelBtn,
      "show": _vm.popPromptShow,
      "isConfirmLoading": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isConfirmLoading') && _vm.popPromptInfo.isConfirmLoading,
      "isBtnCancel": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('cancelText') && _vm.popPromptInfo.cancelText != null,
      "content": "html",
      "cancelText": _vm.popPromptInfo ? _vm.popPromptInfo.cancelText : ' ',
      "confirmText": _vm.popPromptInfo ? _vm.popPromptInfo.confirmText : ' '
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptShow = $event;
      },
      "cancel": function ($event) {
        _vm.popPromptInfo && _vm.popPromptInfo.cancelText && _vm.popPromptInfo.hasOwnProperty('cancelFn') && _vm.popPromptInfo.cancelFn();
      },
      "confirm": function ($event) {
        _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('confirmFn') && _vm.popPromptInfo.confirmFn();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('error') && _vm.popPromptInfo.error ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popPromptInfo.error)
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "pop-common"
  }, [_vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('icon') && _vm.popPromptInfo.icon ? _c('div', {
    staticClass: "pop-common__icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.popPromptInfo.icon,
      "alt": ""
    }
  })]) : _vm._e(), _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('title') && _vm.popPromptInfo.title ? _c('div', {
    staticClass: "pop-common__title"
  }, [_vm._v(_vm._s(_vm.popPromptInfo.title))]) : _vm._e(), _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('reward') && _vm.popPromptInfo.reward ? _c('div', {
    staticClass: "pop-common__reward"
  }, [_vm._v("+" + _vm._s(_vm.fmt_pre(_vm.popPromptInfo.reward)))]) : _vm._e()])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rewards__title"
  }, [_vm._v(" BASE "), _c('div', {
    staticClass: "circleLoading"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rewards__title"
  }, [_vm._v(" EXTARS "), _c('div', {
    staticClass: "circleLoading"
  })]);
}];
export { render, staticRenderFns };