import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "mFirendShareOrReward",
  computed: {
    ...userVuex.mapState(["userInfo", "settingInfo"])
  },
  props: {
    info: {
      default: {}
    },
    earnings: {
      default: {}
    },
    ranking: {
      default: {}
    }
  },
  data() {
    return {};
  },
  methods: {
    initFn() {
      console.log("mFirendShareOrReward");
    },
    onShare() {
      this.hapticFeedback();
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=Who%20let%20the%20${this.settingInfo.coin}%20out%20&url=https://t.me/${this.settingInfo.botname}/app?startapp=ref_${this.userInfo.ref_code}`);
    },
    onCopy() {
      this.hapticFeedback();
      const that = this;
      this.$copyText(`https://t.me/${this.settingInfo.botname}/app?startapp=ref_${this.userInfo.ref_code}`).then(function (e) {
        that.$toasted.clear();
        that.$toasted.success(that.$lang("Copy Success!"));
      }, function (e) {});
    }
  }
};