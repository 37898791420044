import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import vailcode from "@utils/errcode";
export default {
  name: "risC",
  data() {
    return {
      isStep: 0
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile"]),
    ...userVuex.mapState(["userInfo", "settingInfo"])
  },
  methods: {
    ...userVuex.mapMutations(["setUserInfo"]),
    onContinueRis() {
      this.isStep = 1;
    },
    async onSkipGame() {
      let rs = await this.$http.post("/user/inapp", {});
      vailcode(rs, async () => {
        this.setUserInfo(rs.data);
        this.$emit("confirm");
      });
    }
  }
};