import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "mPopSpinsPriceList",
  computed: {
    ...userVuex.mapState(["userInfo", "balanceInfo"]),
    ...taskVuex.mapState(["taskInfo"])
  },
  data() {
    return {
      popMoreSpinsShow: false,
      spinsPriceList: null,
      isLoading: false,
      xIndex: 0
    };
  },
  methods: {
    ...userVuex.mapActions(["getSpinsPriceList", "getPaymentSpins", "checkPaymentSpins"]),
    ...taskVuex.mapActions(["openInvoiceStarPay"]),
    async showPopMoreSpins() {
      this.hapticFeedback();
      this.popMoreSpinsShow = true;
      await this.getSpinsPrice();
    },
    async getSpinsPrice() {
      if (this.isLoading) return;
      this.isLoading = true;
      const spinsPriceList = await this.getSpinsPriceList();
      if (!spinsPriceList) {
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.spinsPriceList = spinsPriceList;
    },
    async onPaySpins(item, index) {
      this.xIndex = index;
      if (this.taskInfo.paymentInfo[`p_${index}`].isLoading) {
        this.$toasted.clear();
        this.$toasted.info("This item is in transaction, please select another item");
        return;
      }
      console.log("itemitemitemitemitem", item, index);
      if (item.isLoading) return;
      item.isLoading = true;
      const res = await this.getPaymentSpins({
        index
      });
      if (!res) {
        item.isLoading = false;
        return;
      }
      const chainInfo = res.chainInfo;
      console.log("chainInfochainInfochainInfo", res, chainInfo);
      const paymentId = res.paymentId;
      const payRef = await this.openInvoiceStarPay({
        url: chainInfo.payment_url
      });
      console.log("payRef", payRef);
      if (!payRef) {
        item.isLoading = false;
        return;
      }
      console.log("index, paymentIdindex, paymentId", index, paymentId);
      const checkRes = await this.checkPaymentSpins({
        index,
        paymentId
      });
      console.log("checkRescheckRescheckRes", checkRes);
      if (!checkRes) {
        item.isLoading = false;
        return;
      }
      item.isLoading = false;
      this.popMoreSpinsShow = false;
      this.taskInfo.popCoinAnim.reward = item.count;
      this.taskInfo.popCoinAnim.show = true;
    }
  }
};