var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [!_vm.userInfo.joined_facescan ? _c('div', {
    staticClass: "mBtnFace",
    class: _vm.isNoStyle && 'none',
    on: {
      "click": _vm.showPop
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-face"
    }
  })], 1) : _vm._e(), _c('pop-prompt', {
    attrs: {
      "isShadowClick": true,
      "title": " ",
      "isSmallConfirmBtn": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isSmallConfirmBtn') && _vm.popPromptInfo.isSmallConfirmBtn,
      "isCancelLoading": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isCancelLoading') && _vm.popPromptInfo.isCancelLoading,
      "isSmallCancelBtn": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('smallCancelBtn') && _vm.popPromptInfo.smallCancelBtn,
      "show": _vm.popPromptShow,
      "isConfirmLoading": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isConfirmLoading') && _vm.popPromptInfo.isConfirmLoading,
      "isBtnCancel": _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('cancelText') && _vm.popPromptInfo.cancelText != null,
      "content": "html",
      "cancelText": _vm.popPromptInfo ? _vm.popPromptInfo.cancelText : ' ',
      "confirmText": _vm.popPromptInfo ? _vm.popPromptInfo.confirmText : ' '
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptShow = $event;
      },
      "cancel": function ($event) {
        _vm.popPromptInfo && _vm.popPromptInfo.cancelText && _vm.popPromptInfo.hasOwnProperty('cancelFn') && _vm.popPromptInfo.cancelFn();
      },
      "confirm": function ($event) {
        _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('confirmFn') && _vm.popPromptInfo.confirmFn();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function () {
        return [_vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('error') && _vm.popPromptInfo.error ? _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.popPromptInfo.error)
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "pop-common"
  }, [_vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('icon') && _vm.popPromptInfo.icon ? _c('div', {
    staticClass: "pop-common__icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.popPromptInfo.icon,
      "alt": ""
    }
  })]) : _vm._e(), _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('title') && _vm.popPromptInfo.title ? _c('div', {
    staticClass: "pop-common__title"
  }, [_vm._v(_vm._s(_vm.popPromptInfo.title))]) : _vm._e(), _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('reward') && _vm.popPromptInfo.reward ? _c('div', {
    staticClass: "pop-common__reward",
    class: _vm.popPromptInfo && _vm.popPromptInfo.hasOwnProperty('isShowdefaultIcon') && !_vm.popPromptInfo.isShowdefaultIcon && 'isShowdefaultIcon',
    domProps: {
      "innerHTML": _vm._s(_vm.popPromptInfo.reward)
    }
  }) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };