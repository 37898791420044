var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "upgrade-page page"
  }, [_c('m-head'), _c('div', {
    staticClass: "upgrade-main page-main"
  }, [_c('div', {
    staticClass: "upgrade-info"
  }, [_c('div', {
    staticClass: "upgrade-info-main"
  }, [_c('img', {
    staticClass: "wow bounce",
    class: _vm.isUpgradeBotAnim && 'anim',
    attrs: {
      "data-wow-duration": "0.8s",
      "src": require(`@images/avatar/body/${_vm.gameProgress.currentLevel >= _vm.botImgLimit ? _vm.botImgLimit : _vm.gameProgress.currentLevel}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "tip"
  }, [_c('div', {
    staticClass: "level"
  }, [_c('m-level-progress', {
    ref: "mLevelProgressRef",
    attrs: {
      "width": _vm.isMobile ? 760 : 800,
      "progress": _vm.gameProgress.progressInfo.completion_rate * 100,
      "level": [_vm.gameProgress.currentLevel, _vm.gameProgress.nextLevel]
    }
  })], 1), _c('div', {
    staticClass: "intro"
  }, [_c('m-tier-benefits')], 1)])]), _vm.propertyList ? _c('div', {
    staticClass: "upgrade-xs"
  }, [_c('div', {
    staticClass: "upgrade-tab"
  }, [_c('m-tab', {
    ref: "mTabRef",
    attrs: {
      "tab": _vm.propertyList,
      "selectTab": _vm.selectTab
    },
    on: {
      "update:selectTab": function ($event) {
        _vm.selectTab = $event;
      },
      "update:select-tab": function ($event) {
        _vm.selectTab = $event;
      },
      "tabFn": _vm.onTab
    }
  })], 1), _vm.selectUpgradeRow ? _c('div', {
    staticClass: "upgrade-list"
  }, _vm._l(_vm.selectUpgradeRow, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "upgrade-item",
      attrs: {
        "data-index": item.id
      }
    }, [_c('m-upgrade-item', {
      ref: `upgradeItem_${item.id}`,
      refInFor: true,
      attrs: {
        "index": index,
        "item": item
      },
      on: {
        "upgrade": _vm.onUpgrade
      }
    })], 1);
  }), 0) : _vm._e()]) : _vm._e()]), _c('m-menu'), _c('pop-prompt', {
    attrs: {
      "className": "pop-upgrade",
      "isClose": _vm.upgradeResultInfo.anim.step3,
      "show": _vm.popUpgradeResultShow,
      "content": "html",
      "title": "",
      "isBtnCancel": false,
      "isBtnConfirm": false
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpgradeResultShow = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pop-upgrade__title wow fadeInUp",
    style: {
      opacity: _vm.upgradeResultInfo.anim.step3 ? 1 : 0
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(" Congratulations,"), _c('br'), _vm._v(" the upgrade was successful ")]), _vm.popUpgradeResultShow ? _c('div', {
    staticClass: "pop-upgrade-main"
  }, [_c('div', {
    staticClass: "pop-upgrade-bot"
  }, [_c('div', {
    staticClass: "pop-upgrade-bot-main"
  }, [_c('div', {
    staticClass: "pop-upgrade__avatar"
  }, [_c('img', {
    class: [_vm.upgradeResultInfo.anim.step1 && 'step1', _vm.upgradeResultInfo.anim.step2 && 'step2'],
    attrs: {
      "src": require(`@images/avatar/body/${_vm.upgradeResultInfo.level >= _vm.botImgLimit ? _vm.botImgLimit : _vm.upgradeResultInfo.level}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "pop-upgrade__level",
    class: _vm.upgradeResultInfo.anim.step3 && 'step3'
  }, [_c('span', [_vm._v("Lv." + _vm._s(_vm.upgradeResultInfo.level))])])])]), _c('div', {
    staticClass: "btns fadeInUp",
    class: _vm.upgradeResultInfo.anim.step3 && 'anim',
    style: {
      opacity: _vm.upgradeResultInfo.anim.step3 ? 1 : 0
    }
  }, [_c('div', {
    staticClass: "intro"
  }, [_c('m-tier-benefits', {
    attrs: {
      "isCurrent": true
    }
  })], 1), _c('div', {
    staticClass: "btn-confirms",
    on: {
      "click": () => _vm.popUpgradeResultShow = false
    }
  }, [_vm._v("I am lucky")])])]) : _vm._e()]), _c('div', {
    staticClass: "sxx"
  }, [_c('div', {
    ref: "sxRef",
    staticClass: "sx"
  }), _c('div', {
    ref: "sxRef1",
    staticClass: "sx"
  })])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };