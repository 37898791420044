var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "friends-page page"
  }, [_c('m-head'), _c('div', {
    staticClass: "friends-main page-main"
  }, [_c('div', {
    staticClass: "firends-tab"
  }, [_c('m-tab-two', {
    attrs: {
      "tab": _vm.tabList,
      "selectTab": _vm.selectTab
    },
    on: {
      "update:selectTab": function ($event) {
        _vm.selectTab = $event;
      },
      "update:select-tab": function ($event) {
        _vm.selectTab = $event;
      },
      "tabFn": _vm.onTab
    }
  })], 1), _vm.selectTab && _vm.selectTab.value === 0 ? [_c('m-firend-share-or-reward', {
    ref: "mFirendShareOrRewardRef",
    attrs: {
      "ranking": _vm.myFriends,
      "earnings": _vm.earnings,
      "info": _vm.invitationInfo
    }
  })] : _vm._e(), _vm.selectTab && _vm.selectTab.value === 1 ? [_c('m-firend-leasder-broard', {
    ref: "mFirendLeasderBroardRef",
    attrs: {
      "ranking": _vm.topLeaderRank
    }
  })] : _vm._e()], 2), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };