class Particle {
  constructor(x, y, angle, speed, length, color) {
    this.x = x;
    this.y = y;
    this.angle = angle;
    this.speedX = Math.cos(angle) * speed;
    this.speedY = Math.sin(angle) * speed;
    this.length = length;
    this.color = color;
    this.life = 100;
    this.gravity = 0.3;
  }

  update() {
    this.x += this.speedX;
    this.y += this.speedY;
    this.speedY += this.gravity;
    this.length *= 0.98;
    this.life -= 20;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.moveTo(this.x, this.y);
    ctx.lineTo(this.x + Math.cos(this.angle) * this.length, this.y + Math.sin(this.angle) * this.length);
    ctx.lineWidth = 1;
    ctx.strokeStyle = this.color;
    ctx.stroke();
  }
}

class FireworkEffect {
  constructor({ canvas, leftDiv, rightDiv, config, parentWidth, parentHeight }) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");
    this.parentWidth = parentWidth || window.innerWidth;
    this.parentHeight = parentHeight || window.innerHeight;
    this.canvas.width = this.parentWidth;
    this.canvas.height = this.parentHeight;
    this.config = config;
    this.particles = [];
    this.isCreating = false;
    this.releaseInterval = null;

    // DOM Elements
    this.leftDiv = leftDiv;
    this.rightDiv = rightDiv;

    this.init();
  }

  init() {
    this.animate();
    this.addResizeListener(); // Register resize event listener
  }

  addResizeListener() {
    window.addEventListener("resize", this.onResize.bind(this));
  }

  removeResizeListener() {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  onResize() {
    const rect = this.canvas.parentElement.getBoundingClientRect();
    this.canvas.width = rect.width;
    this.canvas.height = rect.height;
    this.parentWidth = rect.width;
    this.parentHeight = rect.height;
  }

  startFirework() {
    this.createFirework(this.leftDiv);
    this.createFirework(this.rightDiv);

    this.releaseInterval = setInterval(() => {
      this.createFirework(this.leftDiv);
      this.createFirework(this.rightDiv);
    }, 50);
  }

  stopFirework() {
    clearInterval(this.releaseInterval);
    this.isCreating = false;
  }

  createFirework(divElement) {
    const divRect = divElement.getBoundingClientRect();

    // Add scroll correction by adding window.scrollX and window.scrollY
    const x = divElement.id === "machineLeftArrowRef" ? divRect.left + divRect.width - 10 : divRect.left + 10;
    const y = divRect.top + 123;

    // Correct the position by the scroll offset
    const scrollX = window.scrollX || 0;
    const scrollY = window.scrollY || 0;

    const numParticles = this.config.numParticles;
    const fireDirectionFactor = divElement.id === "leftDiv" ? -1 : 1;
    const spread = this.config.spreadRange;

    for (let i = 0; i < numParticles; i++) {
      let angle;
      if (divElement.id === "machineLeftArrowRef") {
        angle = Math.random() * spread - spread / 2 + Math.PI;
      } else {
        angle = Math.random() * spread - spread / 2;
      }

      const speed = Math.random() * (this.config.maxSpeed - this.config.minSpeed) + this.config.minSpeed;
      const length = Math.random() * (this.config.maxLength - this.config.minLength) + this.config.minLength;

      const color = "#b2c4ff";

      // Correct for scroll position
      this.particles.push(new Particle(x + scrollX, y + scrollY, angle, speed, length, color));
    }

    if (this.particles.length > this.config.maxParticles) {
      this.particles = this.particles.slice(this.particles.length - this.config.maxParticles);
    }
  }

  updateParticles() {
    for (let i = this.particles.length - 1; i >= 0; i--) {
      const p = this.particles[i];
      p.update();
      p.draw(this.ctx);

      if (p.life <= 0 || p.length <= 1) {
        this.particles.splice(i, 1);
      }
    }
  }

  animate() {
    this.ctx.clearRect(0, 0, this.parentWidth, this.parentHeight);
    this.updateParticles();
    requestAnimationFrame(() => this.animate());
  }

  // Call this method when you want to destroy the effect
  destroy() {
    this.removeResizeListener(); // Remove resize event listener
  }
}

export default FireworkEffect;
