import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nCurrency"
  }, [_c('div', {
    staticClass: "nCurrency-main"
  }, [_vm.isShowKey ? _c('div', {
    staticClass: "nCurrency-item wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    ref: "TAPSTOCKRef",
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/key.png`),
      "alt": ""
    }
  }), _vm.userData && _vm.userData.hasOwnProperty('stock') ? _c('div', {
    staticClass: "nCurrency-item__value"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.userData.stock, 2, true, true)))]) : _vm._e()]) : _vm._e(), _vm.isShowCoin ? _c('div', {
    staticClass: "nCurrency-item wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('img', {
    ref: "TAPCOINSRef",
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tapcoins.png`),
      "alt": ""
    }
  }), _vm.userData && _vm.userData.hasOwnProperty('coin') ? _c('div', {
    staticClass: "nCurrency-item__value"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.userData.coin, 2, true, true)))]) : _vm._e()]) : _vm._e(), _vm.isShowTap ? _c('div', {
    staticClass: "nCurrency-item wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/wallet');
      }
    }
  }, [_vm.tonCurrency.tap.icon ? _c('img', {
    ref: "TAPRef",
    attrs: {
      "src": _vm.tonCurrency.tap.icon,
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "nCurrency-item__value"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.tonCurrency.tap.balance, 2, true, true)))])]) : _vm._e(), _vm.isShowUsdt ? _c('div', {
    staticClass: "nCurrency-item wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/wallet');
      }
    }
  }, [_vm.tonCurrency.usdt.icon ? _c('img', {
    ref: "USDTRef",
    attrs: {
      "src": _vm.tonCurrency.usdt.icon,
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "nCurrency-item__value"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.tonCurrency.usdt.balance, 3, true, true)))])]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };