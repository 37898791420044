import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "airdrop",
  computed: {
    ...gameVuex.mapState(["gameProgress"])
  },
  methods: {
    onSkip(path) {
      this.hapticFeedback();
      this.$router.push(path);
    }
  },
  mounted() {
    this.setWowAnim();
  }
};