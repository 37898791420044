var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-prompt', {
    attrs: {
      "isShadowClick": true,
      "className": "popFaceTipClass",
      "show": _vm.popFaceTipShow,
      "isBtnConfirm": false,
      "isBtnCancel": false,
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popFaceTipShow = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pop-common"
  }, [_c('div', {
    staticClass: "tip-title"
  }, [_vm._v(_vm._s(_vm.$lang('Unlock Airdrop Access')))]), _c('div', {
    staticClass: "tip-top"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm.userInfo.parent_info?.icon ? _c('img', {
    attrs: {
      "src": _vm.userInfo.parent_info?.icon
    }
  }) : _vm._e()]), _vm.userInfo.parent_info?.icon ? _c('div', {
    staticClass: "s",
    domProps: {
      "innerHTML": _vm._s(_vm.svg_close)
    }
  }) : _vm._e(), _c('div', {
    staticClass: "s"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/logo.jpg`)
    }
  })])]), _c('div', {
    staticClass: "tip-s-title"
  }, [_vm._v(_vm._s(_vm.$lang('Collaborative Airdrop Program')))]), _c('div', {
    staticClass: "tip-content"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Before claiming rewards and airdrops, please be sure to complete the anti-bot verification first')) + " ")]), _c('div', {
    staticClass: "tip-sub-title"
  }, [_vm._v(_vm._s(_vm.$lang('I am not a robot')))]), !_vm.isVerify ? _c('div', {
    staticClass: "btn-confirm",
    class: _vm.isLoading && 'gray',
    on: {
      "click": _vm.doVerify
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang(' Verify to Unlock Airdrop Access')) + " "), _vm.isLoading ? _c('em', {
    staticClass: "circleLoading"
  }) : _vm._e()]) : _c('div', {
    staticClass: "btn-confirm",
    class: _vm.isLoading && 'gray',
    on: {
      "click": _vm.doCheckFaceScan
    }
  }, [_vm._v(" Check "), _vm.isLoading ? _c('em', {
    staticClass: "circleLoading"
  }) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };