var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: ['gift-container flat', {
      autoTurn: _vm.isRunning
    }],
    style: {
      '--height': _vm.remTran(_vm.itemHeight) + 'rem',
      '--fontSize': _vm.remTran(_vm.fontSize) + 'rem'
    }
  }, _vm._l(_vm.config.gifts, function (gift, index) {
    return _c('div', {
      key: index,
      class: ['gift', _vm.config.style],
      style: {
        transform: `rotateX(${_vm.rotate * index}deg) translateZ(${_vm.translateZ}px)`
      }
    }, [gift.type === 'text' ? [_c('span', {
      class: _vm.isSelect && 'select'
    }, [_vm._v(_vm._s(gift.name))])] : _vm._e(), gift.type === 'image' ? [_c('img', {
      class: _vm.isSelect && 'select',
      attrs: {
        "src": gift.path,
        "height": _vm.config.height
      }
    })] : _vm._e()], 2);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };