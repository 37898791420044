import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "rewards",
  computed: {
    ...userVuex.mapState(["isLogin", "userInfo"]),
    ...taskVuex.mapState(["taskInfo"]),
    ...walletVuex.mapState(["tonConnect", "isMobile"]),
    officialTaskListFilter() {
      return this.officialTaskList && this.officialTaskList.filter(it => it.cyclical === 1 && (it.hasOwnProperty("daily_completion_count") && it.daily_limit_count > 0 ? it.daily_completion_count < it.daily_limit_count : it.completed === 0) && this.selectTab === 0 || it.cyclical === 1 && (it.hasOwnProperty("daily_completion_count") && it.daily_limit_count > 0 ? it.daily_completion_count >= it.daily_limit_count : it.completed === 1) && this.selectTab === 1 || it.cyclical === 0 && it.completed === this.selectTab);
    },
    extraTaskListFilter() {
      return this.extraTaskList && this.extraTaskList.filter(it => it.cyclical === 1 && (it.hasOwnProperty("daily_completion_count") && it.daily_limit_count > 0 ? it.daily_completion_count < it.daily_limit_count : it.completed === 0) && this.selectTab === 0 || it.cyclical === 1 && (it.hasOwnProperty("daily_completion_count") && it.daily_limit_count > 0 ? it.daily_completion_count >= it.daily_limit_count : it.completed === 1) && this.selectTab === 1 || it.cyclical === 0 && it.completed === this.selectTab);
    }
  },
  data() {
    return {
      popPromptShow: false,
      //是否显示公共弹窗状态
      popPromptInfo: null,
      //存储公共弹窗的页面信息
      officialTaskList: null,
      //官方任务列表
      extraTaskList: null,
      //非官方任务列表
      selectTab: 0,
      adsCountdown: 0,
      adsCountdownTimer: null,
      isContinue: false,
      //弹窗是否显示继续按钮
      isAdcoloudCount: 0,
      ////针对类型为adsgram adcloud openad时 Continue和claim rewards两个按钮当前的切换次数 达到最大切换次数时 一直处于claim rewards按钮直至任务成功为止
      adcoloudCountLimit: 3,
      //针对类型为adsgram adcloud openad时 Continue和claim rewards两个按钮最大切换次数 达到最大切换次数时 一直处于claim rewards按钮直至任务成功为止
      isShowErrorText: false,
      //公共弹窗是否显示错误提示状态
      isShowBtnCancel: false,
      //公共弹窗中是否显示claim按钮状态
      taskPrepareInfo: null,
      //针对类型为stars支付或者ton支付 存储 paymentId
      dailyInfo: null,
      //每日签到在任务页的一些基础信息
      popRewardValue: 0
    };
  },
  watch: {
    isLogin: {
      async handler(bool) {
        if (bool) {
          await this.initFn();
          this.cyclicalCountdown();
        }
      },
      deep: true,
      immediate: true
    },
    popPromptShow: {
      handler(bool) {
        if (!bool) {
          this.isContinue = false;
          this.isShowErrorText = false;
          this.isShowBtnCancel = false;
          this.taskPrepareInfo = null;
          if (this.popPromptInfo) {
            this.popPromptInfo.isConfirmLoading = false;
          }
        }
      }
    }
  },
  methods: {
    ...taskVuex.mapActions(["getTaskList", "doTask", "getTaskPrepare", "openInvoiceStarPay", "getDailySummary"]),
    ...userVuex.mapMutations(["setUserInfo"]),
    async initFn() {
      this.extraTaskList = await this.getTaskList({
        adv: 0
      });
      this.officialTaskList = await this.getTaskList({
        adv: 1
      });
      console.log("officialTaskList", this.officialTaskList);
      this.dailyInfo = await this.getDailySummary();
    },
    onTab() {
      this.selectTab = Number(!this.selectTab);
    },
    //点击显示弹窗
    onShowTaskPop(item) {
      this.hapticFeedback();
      this.setTaskPopInfo(item);
      if (item.type == "telegram" || item.type == "channel" || item.type === "twitter" || item.type == "payment" && item.target.token === "Stars" || item.type == "payment" && item.target.token === "TON") {
        this.popPromptShow = true;
        return;
      }
      if (item.completed === 1) {
        //adsgram adcloud openad三种类型做完之后就不让打开弹窗
        return;
      }
      if (item.type == "adsgram" || item.type == "adcloud" || item.type === "openad") {
        //type 为adsgram adcloud openad这三个时,弹窗显示同时在执行一次相关业务
        this.isAdcoloudCount = 0;
        this.isContinue = false;
        this.popPromptInfo.isConfirmLoading = item.isLoading = true;
        this.adsgramOrAdcloudOrOpenadOpenWay(item);
      }
      this.popPromptShow = true;
    },
    //配置弹窗信息 更新页面直接调用这个函数
    setTaskPopInfo(item) {
      //通用信息配置 及所有弹窗都包含的元素  如果不包含 请在变量中做一些判断 或者剥离出去
      const basicInfo = {
        title: item.title,
        icon: item.icon,
        reward: item.reward,
        isConfirmLoading: item.isLoading,
        error: this.isShowErrorText ? item.check_countdown_prompt : null
      };
      //设置确认按钮文字
      console.log("item.target", item.target);
      const setConfirmText = item => {
        if (item.completed === 0) {
          if (this.isContinue) {
            return "Continue";
          } else if (item.type == "telegram" || item.type == "channel") {
            return "Join";
          } else if (item.type == "twitter") {
            return "Follow";
          } else if (item.type == "payment" && item.target.token === "Stars") {
            return "Payment";
          } else if (item.type == "payment" && item.target.token === "TON") {
            if (!this.tonConnect) {
              return "Connect";
            }
            return "Payment";
          } else if (item.type == "miniapp") {
            return "Open";
          } else if (item.type == "page") {
            return "Claim";
          } else if (item.type == "adsgram" || item.type == "adcloud" || item.type == "openad") {
            return "Claim Rewards";
          }
        } else {
          //完成后 type为telegram channel twitter 能继续打开弹窗 此时的按钮名称
          if (item.type == "telegram" || item.type == "channel" || item.type == "twitter") {
            return "Open";
          }
        }
      };
      //设置取消按钮文字, 目前只在非adsgram adcloud openad显示 其他则直接返回null 代表不显示
      const setCancelText = item => {
        if (item.type == "adsgram" || item.type == "adcloud" || item.type === "openad") {
          return;
        }
        if (this.isShowBtnCancel) {
          return "Claim";
        }
      };
      this.popPromptInfo = {
        ...basicInfo,
        confirmText: setConfirmText(item),
        confirmFn: async () => {
          //执行确认按钮业务
          this.hapticFeedback();
          try {
            if (item.type == "telegram" || item.type == "channel" || item.type === "twitter" || item.type === "miniapp") {
              await this.setTypeTelegramOrChannelOrTwitterOrMiniapp(item);
            } else if (item.type == "adsgram" || item.type == "adcloud" || item.type === "openad") {
              await this.setAdsgramOrAdcloudOrOpenad(item);
            } else if (item.type == "payment" && item.target.token === "Stars") {
              await this.setPayMentStars(item);
            } else if (item.type == "payment" && item.target.token === "TON") {
              await this.setPayMentTon(item);
            } else if (item.type == "page") {
              this.$router.push(`/${item.target}?id=${item.id}`);
            }
          } catch (err) {}
        },
        cancelText: setCancelText(item),
        //非adsgram adcloud openad时 显示claim 验证任务
        cancelFn: async () => {
          //执行取消按钮业务
          //非adsgram adcloud openad时 显示claim 验证任务
          this.hapticFeedback();
          this.popPromptInfo.isCancelLoading = item.isCancelLoading = true;
          let params = {};
          if (item.type == "payment" && (item.target.token === "Stars" || item.target.token === "TON")) {
            params["value"] = this.taskPrepareInfo.paymentId;
          }
          await this.checkTask(item, params, "cancel");
        },
        isSmallConfirmBtn: this.isShowBtnCancel,
        //claim出现时 confirm 按钮比例小一点
        isCancelLoading: item.isCancelLoading //claim按钮 loading状态
      };

      this.popPromptShow = true;
    },
    //验证任务
    async checkTask(item, params, btnType = "confirm") {
      return new Promise(async (resolve, reject) => {
        const _params = params ? {
          ...params
        } : {};
        try {
          const res = await this.doTask({
            taskId: item.id,
            kind: item.type,
            adv: item.adv,
            ..._params
          });
          this.isContinue = false;
          if (!res || res && res?.code > 0) {
            if (btnType === "confirm") {
              item.isLoading = false;
              this.popPromptInfo.isConfirmLoading = false;
            } else if (btnType == "cancel") {
              item.isCancelLoading = false;
              this.popPromptInfo.isCancelLoading = false;
            }
            this.isContinue = true;
            this.setTaskPopInfo(item);
            reject();
            return;
          }
          this.taskInfo.popReward.reward = item.reward;
          this.taskInfo.popReward.isAnim = true;
          this.taskInfo.popReward.show = true;
          this.setUserInfo(res.userInfo);
          this.$httpClear("/task/list");
          await this.initFn();
          this.isAdcoloudCount = 0;
          if (btnType === "confirm") {
            item.isLoading = false;
            this.popPromptInfo.isConfirmLoading = false;
          } else if (btnType == "cancel") {
            item.isCancelLoading = false;
            this.popPromptInfo.isCancelLoading = false;
          }
          this.setTaskPopInfo(item);
          this.popPromptShow = false;
          resolve();
        } catch (err) {
          console.log("errr====", err);
          item.isLoading = false;
          if (btnType === "confirm") {
            this.popPromptInfo.isConfirmLoading = false;
          } else if (btnType == "cancel") {
            this.popPromptInfo.isCancelLoading = false;
          }
          this.setTaskPopInfo(item);
        }
      });
    },
    //类型为adsgram或者adcloud或者openad 任务流程
    setAdsgramOrAdcloudOrOpenad(item) {
      return new Promise(async (resolve, reject) => {
        if (item.completed === 1) {
          reject();
          return;
        }
        if (item.isLoading) {
          reject();
          return;
        }
        this.popPromptInfo.isConfirmLoading = item.isLoading = true;
        if (this.isContinue) {
          await this.adsgramOrAdcloudOrOpenadOpenWay(item);
          this.popPromptInfo.isConfirmLoading = item.isLoading = false;
          reject();
          return;
        }
        setTimeout(() => {
          this.popPromptInfo.isConfirmLoading = item.isLoading = false;
          if (this.adsCountdown <= 0) {
            this.isShowErrorText = false;
            this.checkTask(item);
          } else {
            this.isShowErrorText = true;
            if (this.isAdcoloudCount < this.adcoloudCountLimit) {
              this.isContinue = true;
            }
            this.setTaskPopInfo(item);
          }
          resolve();
        }, 500);
      });
    },
    //类型为telegram或者channel或者twitter或者miniapp任务流程
    async setTypeTelegramOrChannelOrTwitterOrMiniapp(item) {
      return new Promise(async (resolve, reject) => {
        try {
          const linkFn = it => {
            if (it.type == "telegram" || it.type == "channel") {
              _v.WebApp.openTelegramLink(item.url);
            } else if (it.type === "twitter" || it.type === "miniapp") {
              _v.WebApp.openLink(item.url);
            }
          };
          if (item.completed === 1) {
            linkFn(item);
            reject();
            return;
          }
          if (item.isLoading) {
            reject();
            return;
          }
          this.popPromptInfo.isConfirmLoading = item.isLoading = true;
          linkFn(item);
          this.isShowBtnCancel = true;
          this.popPromptInfo.isConfirmLoading = item.isLoading = false;
          this.setTaskPopInfo(item);
          resolve();
        } catch (err) {}
      });
    },
    //adsgram || adcloud || openad广告打开方式
    async adsgramOrAdcloudOrOpenadOpenWay(item) {
      if (item.type === "openad") {
        //打开openad广告
        const bool = await this.setOpenadFnc(item);
        this.popPromptInfo.isConfirmLoading = item.isLoading = false;
        if (bool) {
          //已满足观看时长
          this.checkTask(item);
        } else {
          this.isContinue = true;
          this.setTaskPopInfo(item);
        }
        return;
      }
      if (item.type === "adsgram") {
        const bool = await this.setAdsgramFnc(item);
        this.popPromptInfo.isConfirmLoading = item.isLoading = false;
        if (bool) {
          //已满足观看时长
          this.checkTask(item);
        } else {
          this.isContinue = true;
          this.setTaskPopInfo(item);
        }
        return;
      }
      if (item.type === "adcloud") {
        const adsCountdownFnc = () => {
          this.adsCountdown--;
          if (this.adsCountdown > 0) {
            this.adsCountdownTimer = setTimeout(() => {
              adsCountdownFnc();
            }, 1000);
          }
        };
        //打开adcloud
        this.isAdcoloudCount++;
        this.setAdcloudFnc(item);
        this.popPromptInfo.isConfirmLoading = item.isLoading = false;
        if (this.isContinue) {
          this.isContinue = false;
          this.adsCountdown = item.secondary_check_countdown || 10;
        } else {
          this.adsCountdown = item.check_countdown;
        }
        adsCountdownFnc();
        this.setTaskPopInfo(item);
        this.popPromptShow = true;
      }
    },
    //adsgram 广告接入
    async setAdsgramFnc(item) {
      return new Promise(async (resolve, reject) => {
        try {
          const AdController = await window.Adsgram.init({
            blockId: item.url
          });
          await AdController.show().then(async result => {
            if (result.done) {
              resolve(1);
            } else {
              resolve(null);
            }
          }).catch(result => {
            resolve(null);
          });
        } catch (err) {
          this.$toasted.info("Adsgram is temporarily unavailable, please refresh the mini-app");
          this.popPromptInfo.isConfirmLoading = item.isLoading = false;
          this.popPromptShow = false;
          this.adcloundCountdown = 0;
          this.isAdcoloudCount = 0;
          this.isContinue = false;
          reject();
        }
      });
    },
    //openad 广告接入
    async setOpenadFnc(item) {
      return new Promise(async resolve => {
        const adParams = {
          version: "",
          // your app version if there is no data, please leave it blank
          TG: true // If it is required for Telegram TMA application, and the value is true.
        };

        let dataParams = item.url.split("|");
        const adInfo = {
          zoneId: Number(dataParams[0]),
          // example zoneId, please check your own code parameters
          publisherId: Number(dataParams[1]),
          // example publisherId, please check your own code parameters
          eventId: 0 // Reserved Parameter
        };

        const userInfo = {
          userId: "",
          // user Id, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          firstName: "",
          // firstName or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          lastName: "",
          // lastName or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          userName: "",
          // username or userId, Telegram TMA please leave it blank, webApp if there is no data, please leave it blank
          walletType: "",
          // user wallet type: TON / EVM / TRON / other blockchain project names,  if there is no data, please leave it blank
          walletAddress: "" // user wallet address,  if there is no data, please leave it blank
        };

        try {
          let res = await window.openADJsSDK.interactive.init({
            adParams,
            adInfo,
            userInfo
          });
          if (res.code !== 0) {
            this.$toasted.clear();
            this.$toasted.error("openad error! Try again");
            this.popPromptInfo.isConfirmLoading = item.isLoading = false;
            this.popPromptShow = false;
            return;
          }
          let timer = null;
          let startTime = null; //标记 观看广告起始时间点
          if (res.code === 0) {
            const callbackFunc = {
              // Indicates load ad resource from OpenAD platform, false will be returned if there is no resource to be loaded for the publisher slot/zone
              adResourceLoad: e => {
                // 'step1', e = ture / false
              },
              // Indicates the interactive ad is opening
              adOpening: e => {
                startTime = new Date().getTime(); //标记 观看广告起始时间点
                // 'step2', e = ture / false
              },

              // Indicates the interactive ad is opened,
              adOpened: e => {
                // 'step3',  e = ture / false
              },
              // indicates the interactive ad task is finished, the task is defined by publisher
              adTaskFinished: e => {
                const nowTime = new Date().getTime();
                const watchedTime = (nowTime - startTime) / 1000; //标记退出广告所在的时间点
                timer = setTimeout(() => {
                  resolve(watchedTime >= item.check_countdown); //观看广告时长至少要 大于等于check_countdown 否者重新观看
                }, 1000);
                // 'step5',  e = viewAD / click  / close
                // if (!item.completed) this.checkExtralTask(item, adv);
              },

              // Indicates the interactive ad is closing
              adClosing: e => {
                console.log("Openad adClosing");
                // 'step6', e = viewAD / click  / close
              },

              // Indicates the interactive ad is closed
              adClosed: e => {
                console.log("Openad adClosed");
                // 'step7', e = viewAD / click / close
                // If you want to perform different steps based on different shutdown states, please write the code here.
              },

              // Indicates clicked and jumps
              adClick: e => {
                console.log("Openad adClick");
                clearTimeout(timer);
                timer = setTimeout(() => {
                  resolve(true);
                }, item.secondary_check_countdown * 1000);
                // 'step4', e = ture / false
              }
            };

            window.openADJsSDK.interactive.getRender({
              adInfo,
              cb: callbackFunc
            });
          }
        } catch (err) {
          this.$toasted.clear();
          this.$toasted.error("openad error! Try again");
          this.popPromptInfo.isConfirmLoading = item.isLoading = false;
          this.popPromptShow = false;
        }
      });
    },
    //adcloud 广告跳转
    async setAdcloudFnc(item) {
      this.WebApp.openLink(item.url);
    },
    //星星支付任务
    async setPayMentStars(item) {
      this.popPromptInfo.isConfirmLoading = item.isLoading = true;
      const res = await this.getTaskPrepare({
        taskId: item.id,
        kind: item.type
      });
      if (!res) {
        this.popPromptInfo.isConfirmLoading = item.isLoading = false;
        return;
      }
      this.taskPrepareInfo = res;
      const starRes = await this.openInvoiceStarPay({
        url: res.chainInfo.payment_url
      });
      console.log("starResstarResstarRes", res);
      if (!starRes) {
        this.popPromptInfo.isConfirmLoading = item.isLoading = false;
        return;
      }
      this.popPromptInfo.isConfirmLoading = item.isLoading = false;
      this.isShowBtnCancel = true;
      this.setTaskPopInfo(item);
    },
    //TON支付任务
    async setPayMentTon(item) {
      this.popPromptInfo.isConfirmLoading = item.loading = true;
      console.log("this.tonConnect", this.tonConnect);
      if (!this.tonConnect) {
        this.tonWallet.connectTon();
      }
      const res = await this.getTaskPrepare({
        taskId: item.id,
        kind: item.type,
        address: this.tonWallet.uiAddress
      });
      if (!res) {
        this.popPromptInfo.isConfirmLoading = item.loading = false;
        return;
      }
      this.taskPrepareInfo = res;
      this.isShowBtnCancel = true;
      this.popPromptInfo.isConfirmLoading = item.loading = false;
    },
    //周期性任务 循环时间
    cyclicalCountdown() {
      clearTimeout(this.taskInfo.taskTimer);
      if (this.officialTaskList) {
        this.officialTaskList.map(item => {
          if (item.cyclical == 1 && item.countdown > 0) {
            item.countdown = item.countdown - 1;
            if (item.countdown <= 0) {
              item.completed = 0;
            }
          }
          return item;
        });
      }
      if (this.extraTaskList) {
        this.extraTaskList.map(item => {
          if (item.cyclical == 1 && item.countdown > 0) {
            item.countdown = item.countdown - 1;
            if (item.countdown <= 0) {
              item.completed = 0;
            }
          }
          return item;
        });
      }
      this.taskInfo.taskTimer = setTimeout(() => {
        this.cyclicalCountdown();
      }, 1000);
    }
  },
  mounted() {
    this.setWowAnim();
  }
};