import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.isRouterAlive ? [!_vm.isLogin ? _c('m-loading', {
    ref: "mLoadingRef",
    on: {
      "result": _vm.mLoadingResult
    }
  }) : [_c('layout')]] : _vm._e(), _c('pop-prompt', {
    attrs: {
      "isShadowClick": true,
      "show": _vm.popEnoughShow,
      "content": "html",
      "title": " ",
      "isBtnCancel": false,
      "confirmText": "Get More HID"
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnoughShow = $event;
      },
      "confirm": () => _vm.$router.push('/slotMachine')
    }
  }, [_c('div', {
    staticClass: "pop-enough"
  }, [_c('div', {
    staticClass: "pop-enough__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "pop-enough__label"
  }, [_vm._v("HID is not enough")]), _c('div', {
    staticClass: "pop-enough__balance"
  }, [_c('div', {
    staticClass: "pop-enough__balance_m"
  }, [_vm._v("Balance:  "), _c('img', {
    attrs: {
      "src": require(`@images/icon/icon-hic.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.fmt_pre(_vm.userInfo.coin)))])])])]), _c('pop-reward', {
    attrs: {
      "show": _vm.taskInfo.popReward.show
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.taskInfo.popReward, "show", $event);
      }
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };