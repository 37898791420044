var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop page"
  }, [_c('m-head'), _c('div', {
    staticClass: "airdrop-main page-main"
  }, [_c('div', {
    staticClass: "airdrop-intro wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.2s"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "airdrop-icon",
    style: {
      background: `url(${require('@images/airdrop-box-img.png')}) center/auto 100% no-repeat`
    }
  })]), _c('div', {
    staticClass: "airdrop-point wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.3s"
    }
  }, [_c('div', {
    staticClass: "airdrop-point-main"
  }, [_c('div', {
    staticClass: "airdrop-point__title"
  }, [_vm._v("Key Points")]), _c('div', {
    staticClass: "airdrop-point-list"
  }, [_c('div', {
    staticClass: "airdrop-point-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/center');
      }
    }
  }, [_c('div', {
    staticClass: "airdrop-point-item-main"
  }, [_c('div', {
    staticClass: "airdrop-point__icon",
    style: {
      background: `url(${require('@images/icon/icon-account.png')}) center/auto 100% no-repeat`
    }
  }), _vm._m(1)])]), _c('div', {
    staticClass: "airdrop-point-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('/upgrade');
      }
    }
  }, [_c('div', {
    staticClass: "airdrop-point-item-main"
  }, [_c('div', {
    staticClass: "airdrop-point__icon bot",
    style: {
      background: `url(${require(`@images/avatar/head/${_vm.gameProgress.currentLevel >= _vm.botImgLimit ? _vm.botImgLimit : _vm.gameProgress.currentLevel}.png`)}) center/auto 100% no-repeat`
    }
  }), _c('div', {
    staticClass: "airdrop-point-info"
  }, [_c('div', {
    staticClass: "airdrop-point__num"
  }, [_vm._v("Lv." + _vm._s(_vm.gameProgress.currentLevel))])])])])])])]), _c('div', {
    staticClass: "airdrop-reward"
  }, [_c('div', {
    staticClass: "airdrop-reward__title"
  }, [_vm._v("Airdrop")]), _c('div', {
    staticClass: "airdrop-reward-list"
  }, [_c('div', {
    staticClass: "airdrop-reward-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('div', {
    staticClass: "airdrop-reward__icon",
    style: {
      background: `url(${require('@images/icon/icon-usdt.png')}) center/auto 100% no-repeat`
    }
  }), _vm._m(2), _vm._m(3), _c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `Coming Soon`,
      expression: "`Coming Soon`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "airdrop-reward-btn"
  }, [_vm._v("Submit")])]), _c('div', {
    staticClass: "airdrop-reward-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "airdrop-reward__icon turmp",
    style: {
      background: `url(${require('@images/turmp.png')}) center/auto 100% no-repeat`
    }
  }), _vm._m(4), _vm._m(5), _c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `Coming Soon`,
      expression: "`Coming Soon`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "airdrop-reward-btn"
  }, [_vm._v("Submit")])]), _c('div', {
    staticClass: "airdrop-reward-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.6s"
    }
  }, [_c('div', {
    staticClass: "airdrop-reward__icon",
    style: {
      background: `url(${require('@images/icon/icon-dogs.png')}) center/auto 100% no-repeat`
    }
  }), _vm._m(6), _vm._m(7), _c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `Coming Soon`,
      expression: "`Coming Soon`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "airdrop-reward-btn"
  }, [_vm._v("Submit")])])])])]), _c('m-menu')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-intro-main"
  }, [_c('div', {
    staticClass: "airdrop__title"
  }, [_vm._v("AIRDROP")]), _c('div', {
    staticClass: "airdrop__label"
  }, [_c('span', [_vm._v("Upgrade & Enrich your profile to unlock airdropeligibility and tiered rewards.")]), _c('span', [_vm._v("Follow & Join the joint Airdrop events from HumanPass and top Web3 projects!")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-point-info"
  }, [_c('div', {
    staticClass: "airdrop-point__num"
  }, [_vm._v("??%")]), _c('div', {
    staticClass: "airdrop-point__label"
  }, [_vm._v("Humanity")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-reward-info"
  }, [_c('div', {
    staticClass: "airdrop-reward__label"
  }, [_vm._v("Airdrop 50,000 USDT")]), _c('div', {
    staticClass: "airdrop-reward-intro"
  }, [_c('div', {
    staticClass: "airdrop-reward-intro-item"
  }, [_vm._v(" Rewards "), _c('span', [_vm._v("Share 50,000 USDT")])]), _c('div', {
    staticClass: "airdrop-reward-intro-item comingsoon"
  }, [_vm._v(" Time "), _c('span', [_vm._v("Coming Soon")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-reward-condition"
  }, [_c('div', {
    staticClass: "airdrop-reward-condition__label"
  }, [_vm._v("Participation conditions")]), _c('div', {
    staticClass: "airdrop-reward-condition__value"
  }, [_c('span', [_vm._v("1. Personal profile completion is greater than 90%,")]), _c('span', [_vm._v("2. Level is greater than level 5,")]), _c('span', [_vm._v("3. Submit TON wallet address.")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-reward-info"
  }, [_c('div', {
    staticClass: "airdrop-reward__label"
  }, [_vm._v("Airdrop 5,000 Trump")]), _c('div', {
    staticClass: "airdrop-reward-intro"
  }, [_c('div', {
    staticClass: "airdrop-reward-intro-item"
  }, [_vm._v(" Rewards "), _c('span', [_vm._v("Share 50,00 Trump")])]), _c('div', {
    staticClass: "airdrop-reward-intro-item comingsoon"
  }, [_vm._v(" Time "), _c('span', [_vm._v("Coming Soon")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-reward-condition"
  }, [_c('div', {
    staticClass: "airdrop-reward-condition__label"
  }, [_vm._v("Participation conditions")]), _c('div', {
    staticClass: "airdrop-reward-condition__value"
  }, [_c('span', [_vm._v("1. Personal profile completion is greater than 98%,")]), _c('span', [_vm._v("2. Level is greater than level 7,")]), _c('span', [_vm._v("3. Submit SOLANA wallet address.")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-reward-info"
  }, [_c('div', {
    staticClass: "airdrop-reward__label"
  }, [_vm._v("Airdrop 600,000,000 DOGS")]), _c('div', {
    staticClass: "airdrop-reward-intro"
  }, [_c('div', {
    staticClass: "airdrop-reward-intro-item"
  }, [_vm._v(" Rewards "), _c('span', [_vm._v("Share 50,000 DOGS")])]), _c('div', {
    staticClass: "airdrop-reward-intro-item comingsoon"
  }, [_vm._v(" Time "), _c('span', [_vm._v("Coming Soon")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "airdrop-reward-condition"
  }, [_c('div', {
    staticClass: "airdrop-reward-condition__label"
  }, [_vm._v("Participation conditions")]), _c('div', {
    staticClass: "airdrop-reward-condition__value"
  }, [_c('span', [_vm._v("1. Personal profile completion is greater than 95%,")]), _c('span', [_vm._v("2. Level is greater than level 6,")]), _c('span', [_vm._v("3. Submit TON wallet address.")])])]);
}];
export { render, staticRenderFns };