export default {
  name: "risB",
  data() {
    return {
      progressList: [{
        text: "Account Age Verified",
        show: false,
        progress: 0,
        isOk: false
      }, {
        text: "Activity Level Analyzed",
        show: false,
        progress: 0,
        isOk: false
      }, {
        text: "Telegram Premium Checked",
        show: false,
        progress: 0,
        isOk: false
      }, {
        text: "OG Status Confirmed",
        show: false,
        progress: 0,
        isOk: false
      }],
      isContinue: false
    };
  },
  watch: {
    "$parent.step": {
      handler(step) {
        step == 1 && this.animationFn();
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.clearIntervalFn();
    next();
  },
  methods: {
    // 清理所有定时器，避免溢出
    clearIntervalFn() {
      console.log("清除所有定时器");
      for (let i = 1; i < 30000; i++) {
        clearInterval(i);
        clearTimeout(i);
      }
    },
    // 处理单个步骤的动画
    animateStep(index) {
      if (index >= this.progressList.length) {
        this.isContinue = true; // 所有步骤完成后，按钮可点击
        return;
      }

      // 使当前步骤可见
      this.progressList[index].show = true;
      const step = () => {
        if (this.progressList[index].progress < 400) {
          this.progressList[index].progress += 10;
          requestAnimationFrame(step);
        } else {
          this.progressList[index].isOk = true;
          this.animateStep(index + 1); // 递归调用下一步
        }
      };

      step();
    },
    // 触发动画
    animationFn() {
      this.isContinue = false;
      this.clearIntervalFn();
      setTimeout(() => this.animateStep(0), 10);
    },
    // 继续按钮跳转
    onContinue() {
      this.$emit("confirm");
    }
  },
  mounted() {}
};