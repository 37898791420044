var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "risC"
  }, [_c('div', {
    staticClass: "index-main"
  }, [_c('div', {
    staticClass: "tab"
  }, _vm._l(2, function (_, index) {
    return _c('div', {
      staticClass: "tab-item",
      class: _vm.isStep === index && 'active'
    });
  }), 0), _vm.isStep === 0 ? [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title_main"
  }, [_vm._v(_vm._s(_vm.$lang("Rising star!")))]), _c('div', {
    staticClass: "title_subtitle"
  }, [_vm._v(_vm._s(_vm.$lang("You’ve joined Telegram")))])]), _c('div', {
    staticClass: "ago"
  }, [_c('div', {
    staticClass: "ago-main",
    style: {
      backgroundImage: `url(${require(`@images/ris/ris-c-img-1.png`)})`
    }
  }, [_c('div', {
    staticClass: "ago-con"
  }, [_c('span', [_vm._v(_vm._s(_vm.userInfo.age || 0))]), _vm.userInfo.age <= 1 ? _c('i', [_vm._v(_vm._s(_vm.$lang("year ago")))]) : _c('i', [_vm._v(_vm._s(_vm.$lang("years ago")))])])])]), _c('div', {
    staticClass: "index-info"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Your account number is")) + " #" + _vm._s(_vm.userInfo.tg_id)), _c('br'), _vm._v(" " + _vm._s(_vm.$lang("You're in the Top")) + " " + _vm._s(_vm.userInfo.top_rinking || 0) + "% " + _vm._s(_vm.$lang("Telegram users")) + " ")]), _c('div', {
    staticClass: "index-btn",
    on: {
      "click": _vm.onContinueRis
    }
  }, [_vm._v(_vm._s(_vm.$lang("Continue")))])] : _vm.isStep === 1 ? [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "title_main"
  }, [_vm._v(_vm._s(_vm.$lang("You are amazing!")))]), _c('div', {
    staticClass: "title_subtitle"
  }, [_vm._v(_vm._s(_vm.$lang(`Here is your ${_vm.settingInfo.coin} reward`)))])]), _c('div', {
    staticClass: "index-car"
  }, [_c('div', {
    staticClass: "index-car-main",
    style: {
      backgroundImage: `url(${require(`@images/ris/ris-c-img-2.png`)})`
    }
  }), _c('div', {
    staticClass: "index-car-num"
  }, [_vm._v(_vm._s(_vm._f("fmt_pre")(_vm.userInfo.age_reward + _vm.userInfo.premium_reward)))])]), _c('div', {
    staticClass: "index-info"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Thanks for your time on Telegram")) + " ")]), _c('div', {
    staticClass: "index-btn",
    on: {
      "click": _vm.onSkipGame
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Continue")) + " ")])] : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };