import Vue from "vue";
// import store from "@store/index";

export default function vailCode(rs, callback, errcallback, isShowMessage = true) {
  if (!rs) {
    typeof errcallback == "function" && errcallback(rs);
    return;
  }
  if (rs.code == 0) {
    typeof callback == "function" && callback(rs);
  } else {
    if (rs.code === 401) {
      Vue.toasted.clear();
      _v.WebApp.showAlert(rs.message, function () {
        location.reload();
      });
    } else if (isShowMessage) {
      Vue.toasted.clear();
      Vue.toasted.error(rs.message);
    }

    typeof errcallback == "function" && errcallback(rs);
  }
}
