var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "human-page page"
  }, [_c('m-head'), _c('div', {
    staticClass: "human-main page-main"
  }, [_vm._v(" human ")]), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };