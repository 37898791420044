export default {
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    },
    // 是否旋转
    spin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.name}`;
    },
    svgClass() {
      if (this.className) {
        return "scoped-svg-icon " + this.className;
      } else {
        return "scoped-svg-icon";
      }
    }
  }
};