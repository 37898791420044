import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mHead",
  computed: {
    ...userVuex.mapState(["isLogin", "balanceInfo", "userInfo"]),
    ...gameVuex.mapState(["gameProgress"])
  },
  methods: {
    onSkip(path) {
      this.hapticFeedback();
      this.$router.push(path);
    }
  }
};