var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-page"
  }, [_c('m-head'), _c('div', {
    staticClass: "index-main"
  }, [_vm._m(0), _c('div', {
    staticClass: "index-pass"
  }, [_c('m-pass', {
    attrs: {
      "label": _vm.userInfo.uid
    }
  })], 1), _c('div', {
    staticClass: "index-hic"
  }, [_c('div', {
    staticClass: "index-hic-main"
  }, [!_vm.isCoinAnim ? [_vm._v(_vm._s(_vm.fmt_th(_vm.userInfo.coin)))] : _c('m-count-up', {
    attrs: {
      "endVal": Number(_vm.userInfo.coin)
    }
  })], 2)]), _c('div', {
    staticClass: "index-info"
  }, [_c('div', {
    staticClass: "index-level wow fadeInUpBig",
    attrs: {
      "data-wow-duration": "0.5s"
    },
    on: {
      "click": _vm.upgradePageLink
    }
  }, [_c('m-level-progress', {
    attrs: {
      "progress": _vm.gameProgress.progressInfo.completion_rate * 100,
      "level": [_vm.gameProgress.currentLevel, _vm.gameProgress.nextLevel]
    }
  })], 1), _c('div', {
    staticClass: "index-face",
    class: _vm.isMobile && 'wap'
  }, [_c('span', [_c('m-btn-face')], 1), _c('span', [_c('m-btn-mathine')], 1)]), _c('div', {
    staticClass: "index-bot wow bounce",
    class: _vm.isMobile && 'wap',
    attrs: {
      "data-wow-duration": "0.8s"
    },
    on: {
      "click": _vm.upgradePageLink
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/avatar/body/${_vm.gameProgress.currentLevel >= _vm.botImgLimit ? _vm.botImgLimit : _vm.gameProgress.currentLevel}.png`),
      "alt": ""
    }
  })])])]), _c('m-menu'), _c('pop-face-tip')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-particles"
  }, [_c('div', {
    attrs: {
      "id": "particles-js"
    }
  })]);
}];
export { render, staticRenderFns };