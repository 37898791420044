export default {
  name: "mLevelProgress",
  props: {
    level: {
      default: [] //两个值 当前等级和下一等级
    },

    height: {
      default: "72"
    },
    width: {
      default: "1100"
    },
    progress: {
      default: "100%"
    }
  }
};