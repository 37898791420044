import Vue from "vue";
import vailcode from "@utils/errcode";
import lang from "@i18n/i18nOption.json";
const { langDefault, langList } = lang;

export default {
  namespaced: true,
  state: {
    isMobile: true,
    tonConnect: false,
    tonAddress: null,
    htmlSize: {
      w: 0,
      h: 0,
    },
  },
  mutations: {
    setIsMobile: (state, payload) => {
      state.isMobile = payload;
    },
    setHtmlSize: (state, payload) => {
      for (const key in payload) {
        Vue.prototype.$set(state.htmlSize, key, payload[key]);
      }
    },
    setTonConnect: (state, payload) => {
      state.tonConnect = payload;
    },
    setTonAddress: (state, payload) => {
      state.tonAddress = payload;
    },
  },
  actions: {},
  getters: {},
};
