import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "layout",
  inject: ["reload"],
  data() {
    return {
      unsubscribe: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "tonConnect"]),
    ...walletVuex.mapGetters(["isWallet"]),
    ...userVuex.mapState(["userInfo"])
  },
  watch: {
    tonConnect(bool) {
      this.setTonAddress(bool ? this.tonWallet.address : null);
    }
  },
  methods: {
    ...walletVuex.mapMutations(["setTonConnect", "setTonAddress"]),
    ...userVuex.mapActions(["bindWallet", "getuserInfo"]),
    unsubscribeTonConnectStatus() {
      try {
        const tonWallet = this.tonWallet;
        const unsubscribe = tonWallet.ton.onStatusChange(async walletAndwalletInfo => {
          tonWallet.getWalletInfo();
          this.setTonConnect(tonWallet.ton.connected);
          this.setTonAddress(tonWallet.address);
          console.log(`%c this.userInfo ${this.userInfo.wallet}`, "color: red");
          // console.log(999991);
          // if (tonWallet.ton.connected && !this.userInfo.wallet) {
          //   await this.bindWallet(tonWallet.address);
          //   await this.getuserInfo()
          // }
        });

        this.unsubscribe = unsubscribe;
      } catch (err) {
        console.log("unsubscribeTonConnectStatus :", err);
      }
    },
    initTonWallet() {
      let timer = setTimeout(async () => {
        clearTimeout(timer);
        timer = null;
        const div = document.createElement("div");
        div.setAttribute("id", "ton-connect");
        div.style.display = "none";
        div.style.position = "absolute";
        div.style.zIndex = -99999;
        document.body.appendChild(div);
        const tonConnect = document.getElementById("ton-connect");
        if (tonConnect) {
          clearTimeout(timer);
          timer = null;
          try {
            typeof this.unsubscribe === "function" && this.unsubscribe();
            await this.tonWallet.initTon("ton-connect");
            await this.unsubscribeTonConnectStatus();
            this.setTonConnect(this.tonWallet.ton.connected);
          } catch (err) {
            console.log(err);
          }
        }
      }, 1000);
    }
  },
  mounted() {
    this.initTonWallet();
  }
};